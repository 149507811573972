import { TextField } from '@material-ui/core';
import * as React from 'react';

import { Editor } from 'react-ce';

import { JobDetails as JobDetailsType } from 'shared/models/job';
import { Job } from '../state';

interface CompanyInfoProps {
    jobId: string;
    details: JobDetailsType;
    isEditable: boolean;
    onChange: <K extends keyof Job>(field: K, value: Job[K]) => void;
}

export const CompanyInfoForm: React.FC<CompanyInfoProps> = ({ details, isEditable, onChange }) => {
    const handleDetailsFieldChange = <K extends keyof JobDetailsType>(key: K, value: JobDetailsType[K]) => {
        const updated = Object.assign({}, details, { [key]: value });
        onChange('details', updated);
    };

    const handleEditorTextChange = <K extends keyof JobDetailsType>(field: K) => (value: JobDetailsType[K]) => {
        handleDetailsFieldChange(field, value);
    };

    const handleTextfieldChange = <K extends keyof JobDetailsType>(field: K) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        handleDetailsFieldChange(field, event.target.value as JobDetailsType[K]);
    };

    return (
        <>
            <Editor
                value={details.companyDescription}
                onChange={handleEditorTextChange('companyDescription')}
                label="Company Info"
                className="job-details-text-editor"
                disabled={!isEditable}
            />

            <TextField
                label="Memorable Selling Point"
                value={details.salesPitch}
                onChange={handleTextfieldChange('salesPitch')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />

            <TextField
                label="Funding"
                value={details.funding}
                onChange={handleTextfieldChange('funding')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <TextField
                label="Founders"
                value={details.founders}
                onChange={handleTextfieldChange('founders')}
                fullWidth={true}
                disabled={!isEditable}
            />

            <TextField
                label="Founded Date"
                value={details.founded}
                onChange={handleTextfieldChange('founded')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <TextField
                label="Headquarters"
                value={details.hq}
                onChange={handleTextfieldChange('hq')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />

            <TextField
                label="Team Size"
                value={details.teamSize}
                onChange={handleTextfieldChange('teamSize')}
                fullWidth={true}
                disabled={!isEditable}
            />
            <TextField
                label="Website"
                value={details.website}
                onChange={handleTextfieldChange('website')}
                fullWidth={true}
                disabled={!isEditable}
            />
        </>
    );
};
