import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Header } from '../components/header';
import { JobViewSelector } from '../components/job-view-selector';
import { RecentJobsTable } from '../components/jobs-recent-table';

export const RecentJobsPage = () => {
    return (
        <DocumentTitle title="Recently created Jobs">
            <div id="container">
                <Header
                    title="Recently created Jobs"
                    actions={[<JobViewSelector key="recent-jobs" current="Recently created jobs" />]}
                />
                <div id="content" className="flex-fill">
                    <RecentJobsTable />
                </div>
            </div>
        </DocumentTitle>
    );
};
