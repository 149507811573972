import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import { Map } from 'immutable';
import * as React from 'react';

const styles = css`
    background: white;
    margin: 0 0 0 15px;

    .MuiOutlinedInput-input {
        padding-top: 14px;
        padding-bottom: 14px;
        background: white;
    }
`;

export type LowActivityJobFilter = 'noOutreach14d' | 'noCalls14d' | 'noSubmits14d' | 'noActivity14d' | 'allReasons';

export const lowActivityJobFilterLabels: Map<LowActivityJobFilter, string> = Map({
    allReasons: 'Any reason',
    noActivity14d: 'No activity OF ANY KIND in last 14 days',
    noCalls14d: 'No calls in last 14 days',
    noOutreach14d: 'No outreach in last 14 days',
    noSubmits14d: 'No submits in last 14 days'
}) as Map<LowActivityJobFilter, string>;

export const lowActivityJobOptions = ['noOutreach14d', 'noCalls14d', 'noSubmits14d', 'noActivity14d', 'allReasons'];

interface LowActivityJobsFiltersProps {
    selectedFilter: LowActivityJobFilter;
    onSelectFilter: (filter: LowActivityJobFilter) => void;
}

export const LowActivityJobsFilters: React.FC<LowActivityJobsFiltersProps> = (props) => {
    const { selectedFilter } = props;

    const handleChange = (event: React.ChangeEvent<{ value: LowActivityJobFilter }>) => {
        props.onSelectFilter(event.target.value);
    };

    const menuItems = (lowActivityJobOptions as LowActivityJobFilter[]).map((filter) => (
        <MenuItem key={filter} value={filter}>
            {lowActivityJobFilterLabels.get(filter)}
        </MenuItem>
    ));
    return (
        <Select css={styles} value={selectedFilter} onChange={handleChange} variant="outlined">
            {menuItems}
        </Select>
    );
};
