import { TableCell, TableRow, useTheme } from '@material-ui/core';
import { Whatshot } from '@material-ui/icons';
import { orderBy } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import {
    awaitingClientStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage,
    rocketScreenScheduledStage
} from 'shared/models/job-stages';

import { awaitingClientAgeThreshold } from '../../common/job-board-utils';
import { PageDialogLink } from '../../common/page-dialog-link';
import { ClientCandidates } from '../../containers/client-candidates';
import { Job } from '../../graphql/queries/home';
import { useSlides } from '../../hooks/use-candidate-slides';
import { HomePageTable } from './home-page-table';
import { InfoIcon } from './info-icon';
import { JobActions } from './job-actions';
import { JobSearchBox } from './job-search-box';
import { JobTitle } from './job-title';

const FireIcon: React.FC<{}> = () => {
    const theme = useTheme();
    return (
        <InfoIcon tooltip="Needs Attention" icon={<Whatshot />} color={theme.palette.error.main} className="left-pad" />
    );
};

const JobTableRow: React.FC<{ data: Job }> = ({ data }) => {
    const { setList } = useSlides();

    const rocketScreenScheduledCandidates = data.candidates.filter((c) => c.stage === rocketScreenScheduledStage);
    const awaitingClientCandidates = data.candidates.filter((c) => c.stage === awaitingClientStage);
    const clientRoundCandidates = data.candidates.filter(
        (c) =>
            c.stage === clientFirstRoundStage || c.stage === clientMiddleRoundStage || c.stage === clientFinalRoundStage
    );

    const handleShowCandidates = (
        stage: 'rocket_screen_scheduled' | 'awaiting_client_stage' | 'client_rounds'
    ) => () => {
        const candidates =
            stage === 'rocket_screen_scheduled'
                ? rocketScreenScheduledCandidates
                : stage === 'awaiting_client_stage'
                ? awaitingClientCandidates
                : clientRoundCandidates;
        if (candidates.length > 0) {
            setList(candidates, candidates[0]);
        }
    };

    const oldestAwaitingClientCandidate = awaitingClientCandidates.sort(
        (c1, c2) => c1.lastStageChangedAt - c2.lastStageChangedAt
    )[0];
    const clientFire =
        awaitingClientCandidates.length > 0 &&
        Date.now() - (oldestAwaitingClientCandidate?.lastStageChangedAt ?? Date.now()) > awaitingClientAgeThreshold ? (
            <FireIcon />
        ) : null;
    return (
        <TableRow key={`am-jobs-table-row-${data.id}`}>
            <TableCell>
                <PageDialogLink
                    Component={ClientCandidates}
                    componentProps={{ id: data.client.id }}
                    url={`/client/${data.client.id}/candidates`}
                >
                    <Link to={`/client/${data.client.id}/candidates`}>{data.client.name}</Link>
                </PageDialogLink>
            </TableCell>
            <TableCell>
                <JobTitle job={data} />
            </TableCell>
            <TableCell onClick={handleShowCandidates('rocket_screen_scheduled')}>
                <span style={{ cursor: 'pointer' }}>{rocketScreenScheduledCandidates.length}</span>
            </TableCell>
            <TableCell onClick={handleShowCandidates('awaiting_client_stage')}>
                <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {awaitingClientCandidates.length} {clientFire}
                </span>
            </TableCell>
            <TableCell onClick={handleShowCandidates('client_rounds')}>
                <span style={{ cursor: 'pointer' }}>{clientRoundCandidates.length}</span>
            </TableCell>
            <TableCell className="actions-cell">
                <JobActions
                    id={data.id}
                    client={data.client}
                    status={data.status}
                    dailyEmailLimit={data.dailyEmailLimit}
                    canEdit={true}
                />
            </TableCell>
        </TableRow>
    );
};

export const AMJobsTable: React.FC<{ jobs: Job[] }> = ({ jobs }) => {
    const columns = [
        { title: 'Client', sortFunc: (j: Job) => j.client.name },
        { title: 'Job', sortFunc: (j: Job) => j.title },
        {
            defaultDesc: true,
            sortFunc: (j: Job) => j.candidates.filter((c) => c.stage === rocketScreenScheduledStage).length,
            title: 'Scheduled'
        },
        {
            defaultDesc: true,
            sortFunc: (j: Job) => j.candidates.filter((c) => c.stage === awaitingClientStage).length,
            title: 'Awaiting Client'
        },
        {
            defaultDesc: true,
            sortFunc: (j: Job) =>
                j.candidates.filter(
                    (c) =>
                        c.stage === clientFirstRoundStage ||
                        c.stage === clientMiddleRoundStage ||
                        c.stage === clientFinalRoundStage
                ).length,
            title: 'Client Rounds'
        },
        { title: 'Actions' }
    ];

    const initialSortedJobs = orderBy(jobs, [(j) => j.client.name, (j) => j.title], ['asc', 'asc']);

    return (
        <HomePageTable
            columns={columns}
            records={initialSortedJobs}
            RowComponent={JobTableRow}
            title="Account Manager Jobs"
            sortKeyId="am-jobs"
            initialSort={{ column: 'Client', ascending: true }}
            actions={<JobSearchBox />}
        />
    );
};
