import { css } from '@emotion/core';
import { Divider, MenuItem, TextField } from '@material-ui/core';
import { Map, OrderedMap } from 'immutable';
import { difference, flatMap, intersection, uniq } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { UserData } from 'shared/models/user';

import { fetchClientsList, fetchJobsData, fetchPersonDetails } from '../actions';
import { Candidate, Client, Job, List, ListState, PersonDetails, State } from '../state';
import { ClientFormDialog } from './client-form-dialog';

const styles = css`
    .open-client-form-button {
        cursor: pointer;

        .MuiInputBase-root {
            cursor: pointer;

            .Mui-disabled {
                cursor: pointer;
            }
        }
    }
`;

const defaultSelectFieldProps = {
    fullWidth: true,
    label: 'Client'
};

interface OwnProps {
    onSelect: (val: string) => void;
    selected: string;
    disabled: boolean;
    errorText: string;
    selectFieldProps?: {
        label?: string;
        style?: any;
        fullWidth?: boolean;
    };
    personId?: string;
}

interface ConnectedProps {
    candidates: Map<string, OrderedMap<string, Candidate>>;
    jobs: OrderedMap<string, Job>;
    user: UserData;
    listsState: Map<string, ListState>;
    personDetails: List<PersonDetails>;
    clients: List<Client>;
}

interface ConnectedDispatch {
    fetchClientsList: () => void;
    fetchJobsData: () => void;
    fetchPersonDetails: (personId: string, jobId?: string) => void;
}

interface ClientSelectorState {
    adding: boolean;
}

type ClientSelectorProps = OwnProps & ConnectedProps & ConnectedDispatch;

class ClientSelectorComponent extends React.Component<ClientSelectorProps, ClientSelectorState> {
    constructor(props: ClientSelectorProps) {
        super(props);
        this.state = { adding: false };
        this.ensureDataExists(props);
    }

    componentDidUpdate(props: ClientSelectorProps) {
        this.ensureDataExists(props);
    }

    ensureDataExists = (props: ClientSelectorProps) => {
        if (props.personId) {
            if (!props.listsState.get('jobs')) {
                props.fetchJobsData();
            }
            if (!props.personDetails.list.get(props.personId)) {
                props.fetchPersonDetails(props.personId);
            }
        }
        if (!props.clients.initialized) {
            props.fetchClientsList();
        }
    };

    dataExists = () => {
        const { listsState, personDetails, personId, clients } = this.props;
        if (personId) {
            if (!listsState.get('jobs') || !personDetails.list.get(personId) || !clients.initialized) {
                return false;
            }
        }
        return true;
    };

    handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.props.onSelect(event.target.value !== 'Add Client' ? event.target.value : '');
    };

    handleAddClient = (e: React.SyntheticEvent<{}>) => {
        e.preventDefault();
        this.setState({ adding: true });
    };

    handleAddClientDialogClose = () => {
        this.setState({ adding: false });
    };

    sortedClients = () => {
        const { clients, personId, jobs, personDetails, user } = this.props;
        if (personId) {
            const jobsAssignedToUser = jobs
                .valueSeq()
                .toArray()
                .filter((j) => j.assignee === user.id);
            const jobsOfCandidates = personDetails.list
                .get(personId)
                .candidates.map((c) => jobs.find((j) => j.id === c.jobId));
            const clientIdsUser = uniq(jobsAssignedToUser.map((j) => j.clientId));
            const clientIdsCandidates = uniq(jobsOfCandidates.map((j) => j.clientId));
            const clientIdsUserAndCandidates = intersection(clientIdsUser, clientIdsCandidates);
            const clientIdsCandidatesOnly = difference(clientIdsCandidates, clientIdsUserAndCandidates);
            const sortedClients = flatMap([clientIdsUserAndCandidates, clientIdsCandidatesOnly], (cids) =>
                cids
                    .map((c) => clients.list.get(c))
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            );
            return sortedClients;
        } else {
            return clients.list
                .valueSeq()
                .toArray()
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        }
    };

    render() {
        const { selected, disabled, clients, errorText, selectFieldProps } = this.props;
        if (!this.dataExists()) {
            return null;
        }

        const clientsMenu = this.sortedClients().map((client) => {
            return (
                <MenuItem value={client.id} key={client.id} selected={client.id === selected}>
                    {client.name}
                </MenuItem>
            );
        });
        const styleProps = Object.assign({}, defaultSelectFieldProps, selectFieldProps);
        const selectField = disabled ? (
            <TextField
                className="open-client-form-button"
                value={clients.list.get(selected).name}
                error={Boolean(errorText)}
                helperText={errorText}
                {...styleProps}
                disabled={disabled}
            />
        ) : (
            <TextField
                value={selected}
                select={true}
                placeholder="Set Client"
                onChange={this.handleSelect}
                disabled={disabled}
                error={Boolean(errorText)}
                helperText={errorText}
                {...styleProps}
            >
                {clientsMenu}
                <Divider />
                <MenuItem value={'Add Client'} onClick={this.handleAddClient}>
                    Add Client
                </MenuItem>
            </TextField>
        );
        return (
            <div css={styles}>
                {selectField}
                <ClientFormDialog open={this.state.adding} onRequestClose={this.handleAddClientDialogClose} />
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    candidates: state.candidates,
    clients: state.clients,
    jobs: state.jobs,
    listsState: state.listsState,
    personDetails: state.personsDetails,
    user: state.session.user
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchClientsList,
    fetchJobsData,
    fetchPersonDetails
};

export const ClientSelector = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ClientSelectorComponent);
