import { Switch } from '@material-ui/core';
import * as React from 'react';

import { Editor } from 'react-ce';
import { JobSubmissionSetting } from 'shared/models/job';

interface IntroductionInfoCardProps {
    submissionSetting: JobSubmissionSetting;
    isEditable: boolean;
    onChange: (submissionSetting: JobSubmissionSetting) => void;
}

export const IntroductionInfoCard: React.FC<IntroductionInfoCardProps> = (props) => {
    const { submissionSetting, isEditable } = props;

    const handleIntroDescriptionChange = (value: string) => {
        const valueParsed = new DOMParser().parseFromString(value, 'text/html');
        let introductionDirections = null;
        if (valueParsed) {
            if (valueParsed.body.innerText.trim() !== '') {
                introductionDirections = value;
            }
        }
        const newSubmissionSetting: JobSubmissionSetting = { ...submissionSetting, introductionDirections };
        props.onChange(newSubmissionSetting);
    };

    const handleToggleIntroClientCandidateEmail = () => {
        const newSubmissionSetting = {
            ...submissionSetting,
            introClientEmailDisabled: !submissionSetting.introClientEmailDisabled
        };
        props.onChange(newSubmissionSetting);
    };

    return (
        <div className="job-submission-setting">
            <div style={{ margin: '0 0 10px' }}>
                <label className="form-settings-label">Intro Client &lt;&gt; Candidate Email Enabled</label>
                <Switch
                    className="job-submission-toggle"
                    color="primary"
                    checked={!submissionSetting.introClientEmailDisabled}
                    onChange={handleToggleIntroClientCandidateEmail}
                    disabled={!isEditable}
                />
            </div>
            <Editor
                value={submissionSetting.introductionDirections}
                onChange={handleIntroDescriptionChange}
                label="Introduction Directions"
                className="intro-directions"
                disabled={!isEditable}
            />
        </div>
    );
};
