import { JobStageData, totalFunnelCount } from 'shared/models/job-stages';

import { Job } from '../state';

export const awaitingClientAgeThreshold = 3 * 24 * 3600 * 1000; // tslint:disable-line:no-magic-numbers
export const responseReceivedAgeThreshold = 24 * 3600 * 1000; // tslint:disable-line:no-magic-numbers

export const stageCount = (job: Job, stageName: string) =>
    job.stageFunnelCounts[stageName] ? totalFunnelCount(job.stageFunnelCounts[stageName].qualified) : 0;

export const betweenStagesCount = (job: Job, pre: string, post: string, jobStages: JobStageData[]) => {
    const preIndex = jobStages.find((s) => s.name === pre).id;
    const postIndex = jobStages.find((s) => s.name === post).id;
    const betweenStages = jobStages.filter((s) => s.id > preIndex && s.id < postIndex);

    let count = 0;
    for (const stage of betweenStages) {
        count += stageCount(job, stage.name);
    }
    return count;
};
