import { useLazyQuery, useQuery } from '@apollo/client';
import { Tooltip } from '@material-ui/core';
import * as _ from 'lodash';
import { IconButton, Menu, MenuItem, Popover } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import { CommunicationEmail } from 'material-ui/svg-icons';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import {
    CandidateType,
    interpolateClientReminderEmailTemplate
} from 'shared/common/interpolate-client-reminder-template';
import { formatUrl } from 'shared/common/string-format-utils';
import {
    awaitingClientStage,
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage
} from 'shared/models/job-stages';
import { EmailAddress } from 'shared/types/email-compose';

import { clientCommsEmailAddress } from '../common/email/emails';
import { EmailKind } from '../common/email/header-validator';
import { composeEmail } from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { CLIENT_CANDIDATES, ClientCandidates } from '../graphql/queries/clients-candidates';
import {
    CLIENT_CANDIDATES_REMINDER_TEMPLATES,
    ClientCandidatesReminderTemplates
} from '../graphql/queries/email-templates';
import { useSession } from '../hooks/use-session';

const compareBySubmitToClientDate = (x: { submittedDate: number }, y: { submittedDate: number }) => {
    const a = x.submittedDate;
    const b = y.submittedDate;
    if (a < b) return 1;
    else if (a > b) return -1;
    else return 0;
};

interface OwnProps {
    clientId: string;
}

interface ConnectedDispatch {
    composeEmail: (payload: ComposeEmailWindowData, emailKind: EmailKind) => void;
}

type ClientCandidatesReminderEmailsProps = OwnProps & ConnectedDispatch;

const ClientCandidatesReminderEmailsComponent: React.FunctionComponent<ClientCandidatesReminderEmailsProps> = (
    props
) => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const { user } = useSession();

    const { data } = useQuery<{ clients: ClientCandidates[] }, { id: string; userId: string }>(CLIENT_CANDIDATES, {
        variables: { id: props.clientId, userId: user.id }
    });

    const [fetchTemplates, { data: templates }] = useLazyQuery<
        { email_templates: ClientCandidatesReminderTemplates[] },
        { group: string; kind: string }
    >(CLIENT_CANDIDATES_REMINDER_TEMPLATES);

    if (!data) {
        return null;
    }

    const { clients } = data;
    const client = clients[0];

    const handleMenuButtonTap = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        const anchorTarget = event.target as React.ReactInstance;
        setAnchor(anchorTarget);
        setMenuOpen(true);
    };

    const handleMenuRequestClose = () => {
        setMenuOpen(false);
    };

    const handleClick = (candidatesAtClientRound: boolean, sortedByJob: boolean) => () => {
        setMenuOpen(false);

        const templateKind = candidatesAtClientRound
            ? sortedByJob
                ? 'Sorted by job - All Candidates Status Update'
                : 'All together - All Candidates Status Update'
            : sortedByJob
            ? 'Sorted by job - Awaiting Feedback Reminder'
            : 'All together - Awaiting Feedback Reminder';

        fetchTemplates({
            variables: { group: 'client-reminders', kind: templateKind }
        });
    };

    if (templates && templates.email_templates && !menuOpen) {
        const template = templates.email_templates[0]?.body;
        const personIds = new Set<string>();
        const jobIds = new Set<string>();

        const to: EmailAddress[] = [];
        const cc: Array<{ address: string; name: string }> = [];

        const dataSortedByJob: Array<{
            jobTitle: string;
            awaitingClientFeedbackCandidates: CandidateType[];
            clientFinalRoundCandidates: CandidateType[];
            clientFirstRoundCandidates: CandidateType[];
        }> = [];

        let awaitingClientFeedbackCandidatesAllJobs: CandidateType[] = [];
        let clientFinalRoundCandidatesAllJobs: CandidateType[] = [];
        let clientFirstRoundCandidatesAllJobs: CandidateType[] = [];

        const hiringManagerFirstNames: string[] = [];

        client.jobs.map((job) => {
            jobIds.add(job.id);
            cc.push({
                address: job.accountManager.email,
                name: job.accountManager.name
            });

            job.candidates.map((c) => {
                personIds.add(c.person.id);
                if (!cc.find((i) => i.address === c.accountManager.email)) {
                    cc.push({
                        address: c.accountManager.email,
                        name: c.accountManager.name
                    });
                }
            });

            job.hiringManagerEmails.concat(job.hiringManagerCc).map((address) => {
                const hm = client.hiringManagers.find((hiringManager) => hiringManager.email === address);
                to.push({
                    address,
                    name: hm.name.full
                });

                if (!hiringManagerFirstNames.includes(hm.name.first)) hiringManagerFirstNames.push(hm.name.first);
            });

            cc.push(clientCommsEmailAddress);

            const awaitingClientFeedbackCandidates = job.candidates
                .filter((c) => c.stage === awaitingClientStage)
                .map((c) => {
                    return {
                        jobTitle: job.title,
                        link: formatUrl(c.person.profile_urls[0].url),
                        name: c.person.name,
                        submittedDate: c?.candidateClientSubmit[0]?.submittedToClientAt
                    };
                });

            const clientFinalRoundCandidates = job.candidates
                .filter((c) => c.stage === clientFinalRoundStage)
                .map((c) => {
                    return {
                        jobTitle: job.title,
                        link: formatUrl(c.person.profile_urls[0].url),
                        name: c.person.name,
                        submittedDate: c?.candidateClientSubmit[0]?.submittedToClientAt
                    };
                });

            const clientFirstRoundStageCandidates = job.candidates
                .filter((c) => c.stage === clientFirstRoundStage)
                .map((c) => {
                    return {
                        jobTitle: job.title,
                        link: formatUrl(c.person.profile_urls[0].url),
                        name: c.person.name,
                        submittedDate: c?.candidateClientSubmit[0]?.submittedToClientAt
                    };
                });

            const clientMiddleRoundStageCandidates = job.candidates
                .filter((c) => c.stage === clientMiddleRoundStage)
                .map((c) => {
                    return {
                        jobTitle: job.title,
                        link: formatUrl(c.person.profile_urls[0].url),
                        name: c.person.name,
                        submittedDate: c?.candidateClientSubmit[0]?.submittedToClientAt
                    };
                });

            const clientFirstRoundCandidates =
                clientFirstRoundStageCandidates && clientMiddleRoundStageCandidates
                    ? clientFirstRoundStageCandidates.concat(clientMiddleRoundStageCandidates)
                    : clientFirstRoundStageCandidates || clientMiddleRoundStageCandidates;
            if (
                awaitingClientFeedbackCandidates.length > 0 ||
                clientFinalRoundCandidates.length > 0 ||
                clientFirstRoundCandidates.length > 0
            ) {
                dataSortedByJob.push({
                    awaitingClientFeedbackCandidates: awaitingClientFeedbackCandidates.sort(
                        compareBySubmitToClientDate
                    ),
                    clientFinalRoundCandidates: clientFinalRoundCandidates.sort(compareBySubmitToClientDate),
                    clientFirstRoundCandidates: clientFirstRoundCandidates.sort(compareBySubmitToClientDate),
                    jobTitle: job.title
                });
            }

            awaitingClientFeedbackCandidatesAllJobs = [].concat(
                awaitingClientFeedbackCandidatesAllJobs,
                awaitingClientFeedbackCandidates
            );

            clientFinalRoundCandidatesAllJobs = [].concat(
                clientFinalRoundCandidatesAllJobs,
                clientFinalRoundCandidates
            );

            clientFirstRoundCandidatesAllJobs = [].concat(
                clientFirstRoundCandidatesAllJobs,
                clientFirstRoundCandidates
            );
        });

        awaitingClientFeedbackCandidatesAllJobs = awaitingClientFeedbackCandidatesAllJobs
            .sort(compareBySubmitToClientDate)
            .map((item, i) => {
                const jobTitle =
                    i < awaitingClientFeedbackCandidatesAllJobs.length - 1 &&
                    item.jobTitle !== awaitingClientFeedbackCandidatesAllJobs[i + 1].jobTitle
                        ? item.jobTitle + '</br>'
                        : item.jobTitle;

                return {
                    ...item,
                    jobTitle
                };
            });

        clientFinalRoundCandidatesAllJobs = clientFinalRoundCandidatesAllJobs
            .sort(compareBySubmitToClientDate)
            .map((item, i) => {
                const jobTitle =
                    i < clientFinalRoundCandidatesAllJobs.length - 1 &&
                    item.jobTitle !== clientFinalRoundCandidatesAllJobs[i + 1].jobTitle
                        ? item.jobTitle + '</br>'
                        : item.jobTitle;

                return {
                    ...item,
                    jobTitle
                };
            });

        clientFirstRoundCandidatesAllJobs = clientFirstRoundCandidatesAllJobs
            .sort(compareBySubmitToClientDate)
            .map((item, i) => {
                const jobTitle =
                    i < clientFirstRoundCandidatesAllJobs.length - 1 &&
                    item.jobTitle !== clientFirstRoundCandidatesAllJobs[i + 1].jobTitle
                        ? item.jobTitle + '</br>'
                        : item.jobTitle;

                return {
                    ...item,
                    jobTitle
                };
            });

        const account = {
            email: 'submissions@getrocket.com',
            name: 'Rocket Submissions'
        };

        const signature = '</br>Thanks so much!</br>Rocket </br></br>';

        const body = interpolateClientReminderEmailTemplate(template, {
            awaitingClientFeedbackCandidatesAllJobs,
            clientFinalRoundCandidatesAllJobs,
            clientFirstRoundCandidatesAllJobs,
            dataSortedByJob,
            hiringManagerFirstNames
        });

        props.composeEmail(
            {
                account: { name: account.name, address: account.email },
                accountOptions: [{ name: account.name, address: account.email }],
                attachments: [],
                body: body + signature,
                canEditToRecipients: true,
                draftSavedAt: null,
                headers: {
                    bcc: [],
                    cc,
                    subject: 'Rocket candidates followup - ' + moment().format('M/D/YYYY'),
                    to
                },
                isClientComm: true,
                jobIds: Array.from(jobIds),
                personIds: Array.from(personIds),
                threadId: null,
                windowId: `new-email-${client.id}`
            },
            'client'
        );
    }

    return (
        <React.Fragment>
            <Tooltip title="Send reminder email to client about submitted candidates">
                <IconButton onClick={handleMenuButtonTap}>
                    <CommunicationEmail color={white} />
                </IconButton>
            </Tooltip>
            <Popover open={menuOpen} anchorEl={anchor} onRequestClose={handleMenuRequestClose}>
                <Menu desktop={true}>
                    <MenuItem
                        primaryText="Sorted by job - Awaiting Feedback Reminder"
                        onClick={handleClick(false, true)}
                    />
                    <MenuItem
                        primaryText="Sorted by job - All Candidates Status Update"
                        onClick={handleClick(true, true)}
                    />
                    <MenuItem
                        primaryText="All together - Awaiting Feedback Reminder"
                        onClick={handleClick(false, false)}
                    />
                    <MenuItem
                        primaryText="All together - All Candidates Status Update"
                        onClick={handleClick(true, false)}
                    />
                </Menu>
            </Popover>
        </React.Fragment>
    );
};

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    composeEmail
};

export const ClientCandidatesReminderEmails = connect<undefined, ConnectedDispatch, OwnProps>(
    undefined,
    mapDispatchToProps
)(ClientCandidatesReminderEmailsComponent);
