import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IconButton, Tooltip } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { startCase } from 'lodash';
import React, { useState } from 'react';

import { hasJobPermission, JobDetails, jobPrepayRequired, JobRecruiterPermissions } from 'shared/models/job';
import { ScheduledOutreachData } from 'shared/models/scheduled-outreach';

import {
    JOB_OUTREACH_CHECKS,
    OutreachCandidates,
    READY_CANDIDATES,
    REVIVAL_CANDIDATES,
    SCHEDULE_OUTREACH
} from '../graphql/queries/outreach';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobStatus, JobType } from '../state';
import { OutreachSendDialog } from './outreach-send-dialog';

export const CandidatesOutreach: React.FC<{
    client: { name: string };
    jobId: string;
    dailyEmailLimit: number;
    status: JobStatus;
    iconColor?: string;
    iconSize?: 'small';
}> = ({ client, jobId, dailyEmailLimit, status, iconColor, iconSize }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { user } = useSession();
    const { setAlert } = useModal();
    const [revival, setRevival] = useState(false);
    const { data: jobData } = useQuery<
        {
            jobs: Array<{
                id: string;
                jobType: JobType;
                details: JobDetails;
                dailyPerUserEmailLimit: number;
                onePager: { key: string };
                recruiterPermissions: JobRecruiterPermissions;
                billingInfo: { prepayment: { invoiceSentAt: number; invoiceAmount: number } };
            }>;
        },
        { jobId: string }
    >(JOB_OUTREACH_CHECKS, { variables: { jobId } });
    const [fetchReadyCandidates, { data: readyCandidates }] = useLazyQuery<
        OutreachCandidates,
        { jobId: string; assignee: string }
    >(READY_CANDIDATES, { fetchPolicy: 'network-only' });
    const [fetchRevivalCandidates, { data: revivalCandidates }] = useLazyQuery<
        OutreachCandidates,
        { jobId: string; reason: string }
    >(REVIVAL_CANDIDATES, { fetchPolicy: 'network-only' });
    const [scheduleOutreach] = useMutation<{}, { outreach: Partial<ScheduledOutreachData> }>(SCHEDULE_OUTREACH);
    const { setSnackbar } = useSnackbar();

    const handleCloseOutreach = () => {
        setDialogOpen(false);
    };

    if (!jobData?.jobs[0]) return null;

    const hasSendOutreachPermission = hasJobPermission(jobData?.jobs[0]?.recruiterPermissions?.sendOutreach, user.id);

    const checkJobDetailsExistence = () => {
        if (!jobData.jobs[0].details.idealGroup || jobData.jobs[0].details.idealGroup.length === 0) {
            setAlert(
                'Job Group Missing',
                'At least one job group must be specified, please contact the Job AM to update settings'
            );
            return false;
        }

        if (jobData.jobs[0].jobType === 'S') return true;

        if (
            jobPrepayRequired(jobData.jobs[0].jobType) &&
            (!jobData.jobs[0].billingInfo || !jobData.jobs[0].billingInfo.prepayment.invoiceSentAt)
        ) {
            setAlert(
                'Pre-payment invoice date missing',
                'Billing admin needs to update the pre-payment invoice sent date'
            );
            return false;
        }

        if (!jobData.jobs[0].onePager) {
            setAlert('One pager missing', 'Account manager needs to upload the one pager before outreach can be sent');
            return false;
        }

        const jobDetails = jobData.jobs[0].details;
        const requiredFields: Array<keyof JobDetails> = [
            'placementFees',
            'recruiterPercentage',
            'accountManagerPercentage',
            'companyDescription',
            'compensation',
            'description',
            'experience',
            'founded',
            'founders',
            'funding',
            'hiringManagers',
            'hq',
            'salesPitch',
            'skills',
            'teamSize',
            'visa'
        ];
        const missingFields = requiredFields.filter(
            (field) => jobDetails[field] === undefined || jobDetails[field] === '' || jobDetails[field] === null
        );
        if (missingFields.length > 0) {
            const list = missingFields.map((field) => <li key={field}>{startCase(field)}</li>);
            const description = (
                <div>
                    <ul>{list}</ul>
                    <div style={{ marginTop: '25px' }}>
                        <a href={`/job/${jobId}/edit`} target="_blank">
                            Edit Job Settings
                        </a>
                    </div>
                </div>
            );
            setAlert('Job details missing fields', description);
            return false;
        }
        return true;
    };

    const handleOpenOutreach = () => {
        if (checkJobDetailsExistence()) {
            fetchReadyCandidates({ variables: { jobId, assignee: user.id } });
            setDialogOpen(true);
        }
    };

    const handleRevivalSelect = (revivalActive: boolean, reason: string) => {
        setRevival(revivalActive);
        if (revivalActive) {
            fetchRevivalCandidates({ variables: { jobId, reason } });
        }
    };

    const handleSend = async (data: {
        limit: number;
        revivalReason?: string;
        scheduledAt: number;
        sequenceId: string;
    }) => {
        setDialogOpen(false);
        setSnackbar('Scheduling outreach');
        const { limit, revivalReason, scheduledAt, sequenceId } = data;
        await scheduleOutreach({
            variables: { outreach: { limit, scheduledAt, revivalReason, sequenceId, jobId, userId: user.id } }
        });
        setSnackbar('Outreach scheduled');
    };

    const counts = revival ? revivalCandidates : readyCandidates;
    const availableCount = counts?.counts.aggregate.count;
    const person = counts?.candidates[0]?.person;
    const phoneCallSchedulingLink = counts?.candidates[0]?.assignee.calendarLink;
    const dialog = dialogOpen ? (
        <OutreachSendDialog
            client={client}
            jobId={jobId}
            sampleRecipient={person}
            availableCount={availableCount}
            dailyEmailLimit={dailyEmailLimit}
            dailyPerUserEmailLimit={jobData.jobs[0].dailyPerUserEmailLimit}
            onClose={handleCloseOutreach}
            onSend={handleSend}
            onRevivalSelect={handleRevivalSelect}
            phoneCallSchedulingLink={phoneCallSchedulingLink}
        />
    ) : null;

    const disallowed = !hasSendOutreachPermission;
    const disabled = status !== JobStatus.Active || dailyEmailLimit === 0 || disallowed;
    const tooltip = disallowed
        ? 'Job outreach disallowed'
        : dailyEmailLimit <= 0
        ? 'Outreach set to zero'
        : status === JobStatus.Active
        ? 'Send Outreach'
        : 'Job Not Active';
    return (
        <div>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton onClick={handleOpenOutreach} disabled={disabled} size={iconSize}>
                        <Send htmlColor={disabled ? undefined : iconColor} fontSize={iconSize} />
                    </IconButton>
                </span>
            </Tooltip>
            {dialog}
        </div>
    );
};
