import { gql } from '@apollo/client';

import { ScheduledOutreachStatus } from 'shared/models/scheduled-outreach';
import { SequenceStage, SequenceType } from 'shared/models/sequence';
import { EmailAddress } from 'shared/types/email-compose';

import { Name } from '../../types/common';

export interface Sequence {
    id: string;
    title: string;
    type: SequenceType;
    stages: SequenceStage[];
    cc: EmailAddress[];
    bcc: EmailAddress[];
    weekDaysOnly: boolean;
}

export interface OutreachEmailAccount {
    email: string;
    name: Name;
    queuingOutreachCount: number;
    maxDailyOutreach: number;
}

export interface OutreachCounts {
    emailAccounts: OutreachEmailAccount[];
    outreaches: Array<{
        jobId: string;
        userId: string;
        limit: number;
        scheduledAt: number;
        status: ScheduledOutreachStatus;
        addedCount: number;
    }>;
}

export interface OutreachCandidates {
    counts: { aggregate: { count: number } };
    candidates: Array<{
        person: {
            id: string;
            name: Name;
        };
        assignee: {
            calendarLink: string;
        };
    }>;
}

export const JOB_SEQUENCES = gql`
    query JobSequences($jobId: String!) {
        sequences(where: { jobId: { _eq: $jobId }, archived: { _eq: false } }) {
            id
            title
            type
            stages
            cc
            bcc
            weekDaysOnly
        }
    }
`;

export const OUTREACH_COUNTS = gql`
    query OutreachCounts($jobId: String!, $userId: String!, $sendingDayStart: bigint!) {
        emailAccounts: user_outreach_accounts(where: { userId: { _eq: $userId } }) {
            name
            email
            queuingOutreachCount
            maxDailyOutreach
        }
        outreaches: scheduled_outreaches(
            where: {
                _and: [
                    {
                        _or: [
                            { status: { _in: [scheduled, inProgress] } }
                            { status: { _eq: complete }, scheduledAt: { _gte: $sendingDayStart } }
                        ]
                    }
                    { _or: [{ jobId: { _eq: $jobId } }, { userId: { _eq: $userId } }] }
                ]
            }
        ) {
            jobId
            userId
            limit
            scheduledAt
            status
            addedCount
        }
    }
`;

export const JOB = gql`
    query Job($jobId: String!) {
        jobs(where: { id: { _eq: $jobId } }) {
            id
            title
            status
            dailyEmailLimit
            client {
                name
            }
            details
        }
    }
`;

export const READY_CANDIDATES = gql`
    query ReadyCandidates($jobId: String!, $assignee: String!) {
        counts: get_job_ready_candidates_aggregate(args: { job_id: $jobId, user_id: $assignee }) {
            aggregate {
                count
            }
        }
        candidates: get_job_ready_candidates(args: { job_id: $jobId, user_id: $assignee }, limit: 1) {
            person {
                id
                name
            }
            assignee: userByAssignee {
                calendarLink
            }
        }
    }
`;

export const REVIVAL_CANDIDATES = gql`
    query RevivalCandidates($jobId: String!, $reason: String!) {
        counts: get_revival_outreach_candidates_aggregate(args: { job_id: $jobId, revival_reason: $reason }) {
            aggregate {
                count
            }
        }
        candidates(where: { jobId: { _eq: $jobId } }, limit: 1, order_by: { createdAt: desc }) {
            person {
                id
                name
            }
            assignee: userByAssignee {
                calendarLink
            }
        }
    }
`;

export const JOB_OUTREACH_CHECKS = gql`
    query JobOutreachChecks($jobId: String!) {
        jobs(where: { id: { _eq: $jobId } }) {
            id
            details
            jobType
            dailyPerUserEmailLimit
            onePager
            billingInfo
            recruiterPermissions
        }
    }
`;

export const SCHEDULE_OUTREACH = gql`
    mutation ScheduleOutreach($outreach: scheduled_outreaches_insert_input!) {
        insert_scheduled_outreaches(objects: [$outreach]) {
            affected_rows
        }
    }
`;

export interface ScheduledOutreach {
    id: string;
    job: {
        id: string;
        title: string;
        client: {
            name: string;
        };
    };
    user: {
        id: string;
        name: string;
    };
    scheduledAt: number;
    requestedAt: number;
    status: string;
    limit: string;
    addedCount: number;
}

export const SCHEDULED_OUTREACHES = gql`
    query ScheduledOutreaches($username: String!, $startDate: bigint!, $endDate: bigint!) {
        scheduled_outreaches(
            where: {
                user: { username: { _eq: $username } }
                _or: [
                    { requestedAt: { _gte: $startDate, _lte: $endDate } }
                    { scheduledAt: { _gte: $startDate, _lte: $endDate } }
                ]
            }
            order_by: [{ status: desc }, { requestedAt: asc }, { job: { client: { name: asc } } }]
        ) {
            id
            job {
                id
                title
                client {
                    name
                }
            }
            user {
                id
            }
            scheduledAt
            requestedAt
            status
            limit
            addedCount
        }
    }
`;

export const UPDATE_SCHEDULED_OUTREACHES = gql`
    mutation UpdateScheduledOutreaches($id: String!, $updates: scheduled_outreaches_set_input!) {
        update_scheduled_outreaches(where: { id: { _eq: $id } }, _set: $updates) {
            affected_rows
        }
    }
`;
export interface ScheduledOutreachByJob {
    id: string;
    user: {
        id: string;
        name: string;
    };
    scheduledAt: number;
    requestedAt: number;
    status: string;
    limit: string;
    addedCount: number;
}

export const SCHEDULED_OUTREACHES_BY_JOB = gql`
    query ScheduledOutreachesByJob($jobId: String!, $startDate: bigint!, $endDate: bigint!) {
        scheduled_outreaches(
            where: {
                job: { id: { _eq: $jobId } }
                _or: [
                    { requestedAt: { _gte: $startDate, _lte: $endDate } }
                    { scheduledAt: { _gte: $startDate, _lte: $endDate } }
                ]
            }
            order_by: [{ status: desc }, { requestedAt: asc }, { job: { client: { name: asc } } }]
        ) {
            id
            user {
                id
                name(path: "full")
            }
            scheduledAt
            requestedAt
            status
            limit
            addedCount
        }
    }
`;

export const SCHEDULED_OUTREACHES_ALL = gql`
    query ScheduledOutreachesAll($startDate: bigint!, $endDate: bigint!) {
        scheduled_outreaches(
            where: {
                _or: [
                    { requestedAt: { _gte: $startDate, _lte: $endDate } }
                    { scheduledAt: { _gte: $startDate, _lte: $endDate } }
                ]
            }
            order_by: [{ status: desc }, { requestedAt: asc }, { job: { client: { name: asc } } }]
        ) {
            id
            job {
                id
                title
                client {
                    name
                }
            }
            user {
                id
                name(path: "full")
            }
            scheduledAt
            requestedAt
            status
            limit
            addedCount
        }
    }
`;
