import { Map, OrderedMap, OrderedSet } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { EmailTemplateView } from 'shared/models/email-templates';
import { SubmissionAccount } from 'shared/models/job';
import { NoteAttachment, NoteView } from 'shared/models/note';
import { UserData } from 'shared/models/user';
import { CrunchbaseData } from 'shared/types/crunchbase';

import { deleteDraft, getDraft } from '../common/draft-storage';
import { getSubmissionProfileData } from '../common/profile';
import { Client, EmailAccount, Job, List, NoteDraftState, PersonDetails, State } from '../state';
import { NoteDialog } from './note-dialog';

interface ConnectedProps {
    crunchbase: Map<string, CrunchbaseData>;
}

interface OwnProps {
    personId: string;
    jobId: string;
    generatedSubmission: string;
    onSave: () => void;
    onCancel: () => void;
    clients: List<Client>;
    jobs: OrderedMap<string, Job>;
    user: UserData;
    emailAccounts: Map<string, EmailAccount>;
    emailTemplates: EmailTemplateView[];
    personsDetails: List<PersonDetails>;
    notes: Map<string, OrderedSet<NoteView>>;
}

type SubmitToAMComponentProps = OwnProps & ConnectedProps;

const SubmitToAccountManagerComponent: React.FC<SubmitToAMComponentProps> = (props) => {
    const {
        crunchbase,
        personId,
        jobId,
        generatedSubmission,
        clients,
        jobs,
        emailAccounts,
        emailTemplates,
        personsDetails,
        notes,
        onSave,
        onCancel
    } = props;
    const [submissionNoteId, setSubmissionNoteId] = React.useState<string>(null);
    const [active, setActive] = React.useState(false);
    const [initialDraft, setInitialDraft] = React.useState<NoteDraftState>(null);

    const noteDraftKey = `submission-note-submit-${personId}-${jobId}`;

    const getSubmissionContent = () => {
        const submitToJob = jobs.get(jobId);
        const submitToClient = clients.list.get(submitToJob.clientId);
        const submissionAccount = emailAccounts.get(SubmissionAccount);
        const account = {
            address: submissionAccount.email,
            name: submissionAccount.name.full
        };
        const personDetails = personsDetails.list.get(personId);
        const templateKind = job.jobType === 'S' ? 'Client Submission - Sourcer' : 'Client Submission - Default';
        const defaultTemplate = emailTemplates.find(
            (template) => template.group === 'submission' && template.kind === templateKind
        );
        const submissionTemplate = job.templates.submission ?? defaultTemplate;
        const hiringManagers = submitToJob.hiringManagerEmails.map((hiringManagerEmail) =>
            submitToClient.hiringManagers.find((h) => h.email === hiringManagerEmail)
        );
        const hiringManagerAddresses = hiringManagers.map((hiringManager) => ({
            address: hiringManager.email,
            name: hiringManager.name.full
        }));
        const templateData = {
            client: submitToClient,
            job: submitToJob,
            person: personDetails.person,
            profile: getSubmissionProfileData(personDetails.profile, crunchbase),
            profileUrls: personDetails.profileUrls,
            senderAccount: submissionAccount
        };
        const bodyTemplate = generatedSubmission
            ? submissionTemplate.body.replace(
                  '{generated submission content | submission details}',
                  generatedSubmission.replace(
                      new RegExp(personDetails.person.name.full, 'i'),
                      '{{candidateNameWithLink}}'
                  )
              )
            : submissionTemplate.body;
        const subject = interpolateEmailTemplate(submissionTemplate.subject, templateData);
        const body = interpolateEmailTemplate(bodyTemplate, templateData);
        return {
            account,
            bcc: submissionTemplate.bcc,
            body,
            cc: submissionTemplate.cc,
            subject,
            to: hiringManagerAddresses
        };
    };

    React.useEffect(() => {
        const existingNote = notes
            .get(`persons-${personId}`)
            .find(
                (n) =>
                    n.context.isSubmissionNote === true &&
                    n.context.jobId === jobId &&
                    !n.context.isSubmissionNoteChangeRequest &&
                    !n.context.isSubmissionNoteReply &&
                    !n.context.isATSSubmissionNote
            );
        const noteId = existingNote ? existingNote.id : undefined;
        setSubmissionNoteId(noteId);
        getDraft<NoteDraftState>(noteDraftKey).then((existingDraft) => {
            let noteInitialContent;
            const noteInitialAttachments: NoteAttachment[] = existingNote ? existingNote.attachments : [];
            if (existingNote && existingDraft) {
                noteInitialContent =
                    existingNote.modifiedAt > existingDraft.modifiedAt ? existingNote.content : existingDraft.content;
            } else {
                noteInitialContent = existingDraft
                    ? existingDraft.content
                    : existingNote
                    ? existingNote.content
                    : getSubmissionContent().body;
            }
            setInitialDraft({
                addingAttachment: false,
                content: noteInitialContent,
                context: { jobId, isSubmissionNote: true },
                initialAttachments: noteInitialAttachments,
                modifiedAt: Date.now(),
                saving: false
            });
            setActive(true);
        });
    }, []);

    const handleCancel = () => {
        deleteDraft(noteDraftKey);
        onCancel();
    };

    const handleSave = () => {
        deleteDraft(noteDraftKey);
        onSave();
    };

    const job = jobs.get(jobId);
    const client = clients.list.get(job.clientId);

    return active ? (
        <NoteDialog
            initialDraft={initialDraft}
            personId={personId}
            jobId={jobId}
            noteId={submissionNoteId}
            onCancel={handleCancel}
            onSave={handleSave}
            noteDraftKey={noteDraftKey}
            title={`Submit to Account Manager for ${client.name} - ${job.title}`}
        />
    ) : null;
};

const mapStateToProps = (state: State): ConnectedProps => ({
    crunchbase: state.crunchbase
});

export const SubmitToAccountManager = connect<ConnectedProps, {}, OwnProps>(mapStateToProps)(
    SubmitToAccountManagerComponent
);
