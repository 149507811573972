import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Map } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { JobSubmissionSetting } from 'shared/models/job';

import { Client, Job, List, State } from '../state';
import { ClientContactSelector } from './client-contact-selector';
import { IntroductionInfoCard } from './introduction-info-card';
import { JobSubmissionSettingCard } from './job-submission-setting-card';

interface OwnProps {
    address: string;
    clientId: string;
    isEditable: boolean;
    errors: Map<string, string>;
    hiringManagerEmails: string[];
    hiringManagerEmailsCC: string[];
    introEmailContact: string;
    introEmailCc: string[];
    submissionSetting: JobSubmissionSetting;
    onJobFieldChange: (field: keyof Job) => (value: any) => void;
    updateClient: (clientId: string, updates: Partial<Client>) => void;
}

interface ConnectedProps {
    clients: List<Client>;
}

type JobClientInfoCardProps = OwnProps & ConnectedProps;

class JobClientInfoCardComponent extends React.Component<JobClientInfoCardProps> {
    handleHiringManagerEmailClick = (hiringManagerEmail: string) => {
        if (_.includes(this.props.hiringManagerEmails, hiringManagerEmail)) {
            this.props.onJobFieldChange('hiringManagerEmails')(
                this.props.hiringManagerEmails.filter((h) => h !== hiringManagerEmail)
            );
        } else {
            this.props.onJobFieldChange('hiringManagerEmails')(
                this.props.hiringManagerEmails.concat([hiringManagerEmail])
            );
        }
    };

    handleHiringManagerCCEmailClick = (hiringManagerEmail: string) => {
        if (_.includes(this.props.hiringManagerEmailsCC, hiringManagerEmail)) {
            this.props.onJobFieldChange('hiringManagerCc')(
                this.props.hiringManagerEmailsCC.filter((h) => h !== hiringManagerEmail)
            );
        } else {
            this.props.onJobFieldChange('hiringManagerCc')(
                this.props.hiringManagerEmailsCC.concat([hiringManagerEmail])
            );
        }
    };

    handleIntroEmailCcClick = (email: string) => {
        if (_.includes(this.props.introEmailCc, email)) {
            this.props.onJobFieldChange('introEmailCc')(this.props.introEmailCc.filter((h) => h !== email));
        } else {
            this.props.onJobFieldChange('introEmailCc')(this.props.introEmailCc.concat([email]));
        }
    };

    handleIntroContactClick = (email: string) => {
        this.props.onJobFieldChange('introEmailContact')(email);
    };

    handleTextFieldChange = (field: keyof Job) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.props.onJobFieldChange(field)(event.target.value);
    };

    handleSubmissionSettingChange = (submissionSetting: JobSubmissionSetting) => {
        this.props.onJobFieldChange('submissionSetting')(submissionSetting);
    };

    render() {
        const {
            address,
            clientId,
            clients,
            errors,
            hiringManagerEmailsCC,
            hiringManagerEmails,
            isEditable,
            introEmailContact,
            introEmailCc,
            submissionSetting
        } = this.props;
        const client = clients.list.get(clientId);

        const hiringManagerSection = (
            <div>
                <div className="form-settings-label">Submission Email Contact</div>
                <ClientContactSelector
                    isEditable={isEditable}
                    client={client}
                    updateClient={this.props.updateClient}
                    selected={hiringManagerEmails}
                    onSelect={this.handleHiringManagerEmailClick}
                />
            </div>
        );

        const hiringManagerCCSection = (
            <div>
                <div className="form-settings-label">Submission Email CC</div>
                <ClientContactSelector
                    isEditable={isEditable}
                    client={client}
                    updateClient={this.props.updateClient}
                    selected={hiringManagerEmailsCC}
                    onSelect={this.handleHiringManagerCCEmailClick}
                />
            </div>
        );

        const introContactSection = (
            <div>
                <div className="form-settings-label">Introduction Email Contact</div>
                <ClientContactSelector
                    isEditable={isEditable}
                    client={client}
                    updateClient={this.props.updateClient}
                    selected={introEmailContact}
                    onSelect={this.handleIntroContactClick}
                />
            </div>
        );

        const introCcSection = (
            <div>
                <div className="form-settings-label">Introduction Email CC</div>
                <ClientContactSelector
                    isEditable={isEditable}
                    client={client}
                    updateClient={this.props.updateClient}
                    selected={introEmailCc}
                    onSelect={this.handleIntroEmailCcClick}
                />
            </div>
        );

        const addressField = (
            <div style={{ marginTop: '-20px' }}>
                <TextField
                    label="Address"
                    disabled={!isEditable}
                    value={address}
                    multiline={true}
                    onChange={this.handleTextFieldChange('address')}
                    fullWidth={true}
                    error={Boolean(errors.get('address', null))}
                    helperText={errors.get('address', null)}
                />
            </div>
        );

        return (
            <div className="job-settings-card">
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ lineHeight: '24px' }}>
                        <div>
                            <Typography variant="h5" component="div">
                                Submission/Introduction Info
                            </Typography>
                            <Typography variant="h6" color="textSecondary" component="div">
                                View/Edit client communication settings
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {addressField}
                        {hiringManagerSection}
                        {hiringManagerCCSection}
                        <JobSubmissionSettingCard
                            isEditable={isEditable}
                            submissionSetting={submissionSetting}
                            onChange={this.handleSubmissionSettingChange}
                            errors={errors}
                        />
                        {introContactSection}
                        {introCcSection}
                        <IntroductionInfoCard
                            isEditable={isEditable}
                            submissionSetting={submissionSetting}
                            onChange={this.handleSubmissionSettingChange}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    clients: state.clients
});

export const JobClientInfoCard = connect<ConnectedProps, {}, OwnProps>(
    mapStateToProps,
    null
)(JobClientInfoCardComponent);
