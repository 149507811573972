import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { createContext, FC, useContext } from 'react';

import { Candidate, PENDING_EMAILS, PENDING_EMAILS_ARCHIVED } from '../graphql/queries/home';
import { USER_REMINDERS, UserReminder } from '../graphql/queries/reminders';
import { USER_MENTIONS, UserMention } from '../graphql/queries/user-mentions';
import { useSession } from './use-session';
import { useUserDataChanges } from './use-user-data-changes';

const NotificationsDataContext = createContext<{
    mentions: UserMention[];
    reminders: UserReminder[];
    pendingEmails: Candidate[];
    pendingEmailsArchived: Candidate[];
}>({
    mentions: [],
    pendingEmails: [],
    pendingEmailsArchived: [],
    reminders: []
});

const NotificationsDataProvider: FC<{ userId?: string }> = (props) => {
    const { user } = useSession();
    const userId = props.userId ?? user?.id;
    const { dataModificationTs } = useUserDataChanges();
    const maxUpdateDelay = 900000; // 15 minutes
    const pollInterval = 900000; // 15 minutes
    const [maxRemindAt, setMaxRemindAt] = React.useState(moment().endOf('day').valueOf());
    const [reminders, setReminders] = React.useState<UserReminder[]>([]);
    const [lastUpdateAt, setLastUpdateAt] = React.useState<number>(Date.now());

    const pendingEmailsQuery = useQuery<{ candidates: Candidate[] }, { userId: string }>(PENDING_EMAILS, {
        pollInterval,
        variables: { userId }
    });
    const archivedPendingEmailsQuery = useQuery<{ candidates: Candidate[] }, { userId: string }>(
        PENDING_EMAILS_ARCHIVED,
        {
            pollInterval,
            variables: { userId }
        }
    );
    const mentionsQuery = useQuery<{ mentions: UserMention[] }, { userId: string }>(USER_MENTIONS, {
        pollInterval,
        variables: { userId }
    });
    const remindersQuery = useQuery<{ reminders: UserReminder[] }, { userId: string; maxRemindAt: number }>(
        USER_REMINDERS,
        {
            pollInterval,
            variables: { userId, maxRemindAt }
        }
    );

    React.useEffect(() => {
        if (remindersQuery.data) {
            let updateAt: number;
            for (const reminder of remindersQuery.data.reminders) {
                if (reminder.remindAt > Date.now()) {
                    updateAt = updateAt ? Math.min(updateAt, reminder.remindAt) : reminder.remindAt;
                }
            }
            const updateDelay = updateAt ? Math.min(updateAt - Date.now(), maxUpdateDelay) : maxUpdateDelay;
            const timeout = setTimeout(() => {
                setLastUpdateAt(Date.now());
                setMaxRemindAt(moment().endOf('day').valueOf());
            }, updateDelay);
            setReminders(remindersQuery.data.reminders.filter((r) => r.remindAt <= Date.now()));
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [remindersQuery.data, lastUpdateAt]);

    React.useEffect(() => {
        if (!pendingEmailsQuery.loading) {
            pendingEmailsQuery.refetch();
        }
        if (!archivedPendingEmailsQuery.loading) {
            archivedPendingEmailsQuery.refetch();
        }
    }, [dataModificationTs?.lastCandidateModifiedAt]);

    React.useEffect(() => {
        if (!remindersQuery.loading) {
            remindersQuery.refetch();
        }
    }, [dataModificationTs?.lastReminderModifiedAt]);

    React.useEffect(() => {
        if (!mentionsQuery.loading) {
            mentionsQuery.refetch();
        }
    }, [dataModificationTs?.lastMentionModifiedAt]);

    const contextData = {
        mentions: mentionsQuery?.data?.mentions ?? [],
        pendingEmails: pendingEmailsQuery?.data?.candidates ?? [],
        pendingEmailsArchived: archivedPendingEmailsQuery?.data?.candidates ?? [],
        reminders
    };

    return <NotificationsDataContext.Provider value={contextData}>{props.children}</NotificationsDataContext.Provider>;
};

const useNotificationsData = () => useContext(NotificationsDataContext);

export { NotificationsDataProvider, useNotificationsData };
