import * as moment from 'moment';
import * as React from 'react';

import * as JobStages from 'shared/models/job-stages';

import { CandidateWithPerson, JobForClientUpdate, StageCounts } from '../state';
import { WeeklyUpdateCandidates } from './weekly-update-candidates';

interface WeeklyUpdateJobSectionProps {
    jobAndCandidates: JobForClientUpdate;
    stageCounts: StageCounts;
    startDate: number;
    endDate: number;
    clientName: string;
}

const headerStyle = {
    fontSize: '16px',
    fontWeight: 'bold' as any,
    lineHeight: '1.8'
};

const sectionStyle = {
    lineHeight: '1.7'
};

export class WeeklyUpdateJobSectionComponent extends React.Component<WeeklyUpdateJobSectionProps, undefined> {
    constructor(props: WeeklyUpdateJobSectionProps) {
        super(props);
    }

    render() {
        const jobAndCandidates = this.props.jobAndCandidates;

        const offerStageCandidates: CandidateWithPerson[] = [];
        const secondRoundPlusStageCandidates: CandidateWithPerson[] = [];
        const firstRoundStageCandidates: CandidateWithPerson[] = [];
        const presentedForFeedbackStageCandidates: CandidateWithPerson[] = [];
        const rocketScreenStageCandidates: CandidateWithPerson[] = [];
        const candidatesWithActivity: CandidateWithPerson[] = [];

        for (const c of jobAndCandidates.candidates) {
            if (
                c.candidate.modifiedAt >= this.props.startDate.valueOf() &&
                c.candidate.modifiedAt <= this.props.endDate.valueOf()
            ) {
                // Candidates who we modified in the given period and are in the stages we care to report about
                // These are both qualified and disqualified
                if (
                    c.candidate.stage === JobStages.clientFirstRoundStage ||
                    c.candidate.stage === JobStages.clientMiddleRoundStage ||
                    c.candidate.stage === JobStages.clientFinalRoundStage ||
                    c.candidate.stage === JobStages.offerStage ||
                    c.candidate.stage === JobStages.hiredStage ||
                    c.candidate.stage === JobStages.rocketScreenScheduledStage ||
                    c.candidate.stage === JobStages.rocketScreenCompleteStage ||
                    c.candidate.stage === JobStages.awaitingClientStage
                ) {
                    candidatesWithActivity.push(c);
                }
            }

            // Candidates that are qualified and in key stages that we want to report about
            if (!c.candidate.disqualified) {
                if (c.candidate.stage === JobStages.offerStage) {
                    offerStageCandidates.push(c);
                } else if (
                    c.candidate.stage === JobStages.clientMiddleRoundStage ||
                    c.candidate.stage === JobStages.clientFinalRoundStage
                ) {
                    secondRoundPlusStageCandidates.push(c);
                } else if (c.candidate.stage === JobStages.clientFirstRoundStage) {
                    firstRoundStageCandidates.push(c);
                } else if (c.candidate.stage === JobStages.awaitingClientStage) {
                    presentedForFeedbackStageCandidates.push(c);
                } else if (
                    c.candidate.stage === JobStages.rocketScreenScheduledStage ||
                    c.candidate.stage === JobStages.rocketScreenCompleteStage
                ) {
                    rocketScreenStageCandidates.push(c);
                }
            }
        }

        const activityPeriod =
            moment(this.props.startDate).format('MM/DD') + ' - ' + moment(this.props.endDate).format('MM/DD');

        // tslint:disable:max-line-length
        return (
            <div style={sectionStyle}>
                <h3 style={headerStyle}>
                    {jobAndCandidates.job.title} ({jobAndCandidates.job.location})
                </h3>
                <WeeklyUpdateCandidates
                    candidates={offerStageCandidates}
                    sectionName={'Offer Stage'}
                    clientName={this.props.clientName}
                    job={jobAndCandidates.job}
                    showLabels={false}
                />
                <WeeklyUpdateCandidates
                    candidates={secondRoundPlusStageCandidates}
                    sectionName={`${this.props.clientName} Onsite Interview Stage`}
                    clientName={this.props.clientName}
                    job={jobAndCandidates.job}
                    showLabels={false}
                />
                <WeeklyUpdateCandidates
                    candidates={firstRoundStageCandidates}
                    sectionName={`${this.props.clientName} Phone Screen Stage`}
                    clientName={this.props.clientName}
                    job={jobAndCandidates.job}
                    showLabels={false}
                />
                <WeeklyUpdateCandidates
                    candidates={presentedForFeedbackStageCandidates}
                    sectionName={`Presented to ${this.props.clientName} ***Feedback Needed***`}
                    clientName={this.props.clientName}
                    job={jobAndCandidates.job}
                    showLabels={false}
                />
                <WeeklyUpdateCandidates
                    candidates={rocketScreenStageCandidates}
                    sectionName="Rocket Screening Stage"
                    clientName={this.props.clientName}
                    job={jobAndCandidates.job}
                    showLabels={false}
                />
                <WeeklyUpdateCandidates
                    candidates={candidatesWithActivity}
                    sectionName={`Weekly Activity (${activityPeriod})`}
                    clientName={this.props.clientName}
                    job={jobAndCandidates.job}
                    showLabels={true}
                />
                <hr />
            </div>
        );
        // tslint:enable:max-line-length
    }
}
