import { MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash';
import { identity } from 'lodash';
import * as React from 'react';

import { Editor } from 'react-ce';
import { JobDetails as JobDetailsType } from 'shared/models/job';
import { idealGroups } from '../common/job-disciplines';

import { TextfieldWithChips } from '../core-ui/textfield-with-chips';
import { Job } from '../state';
import { JobOnePager } from './job-one-pager';

interface JobDetailsProps {
    jobId: string;
    onePager?: {
        key: string;
        size: number;
    };
    showOnePager: boolean;
    location: string;
    discipline: string;
    details: JobDetailsType;
    isEditable: boolean;
    onChange: <K extends keyof Job>(field: K, value: Job[K]) => void;
}

export const JobDetailsForm: React.FC<JobDetailsProps> = ({
    jobId,
    onePager,
    details,
    location,
    discipline,
    isEditable,
    showOnePager,
    onChange
}) => {
    const handleDetailsFieldChange = <K extends keyof JobDetailsType>(key: K, value: JobDetailsType[K]) => {
        const updated = Object.assign({}, details, { [key]: value });
        onChange('details', updated);
    };

    const handleEditorTextChange = <K extends keyof JobDetailsType>(field: K) => (value: JobDetailsType[K]) => {
        handleDetailsFieldChange(field, value);
    };

    const handleSelectFieldChange = <K extends keyof JobDetailsType>(field: K) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        handleDetailsFieldChange(field, event.target.value as JobDetailsType[K]);
    };

    const handleDetailsTextfieldChange = <K extends keyof JobDetailsType>(field: K) => (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        handleDetailsFieldChange(field, event.target.value as JobDetailsType[K]);
    };

    const handleJobTextFieldChange = (field: 'location') => (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(field, event.target.value);
    };

    const handleExperienceChange = (kind: 'minYears' | 'maxYears') => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updated = { ...details.experience, [kind]: Number(event.target.value) };
        handleDetailsFieldChange('experience', updated);
    };

    const onePagerForm = showOnePager ? (
        <div className="job-details-one-pager">
            <label>One Pager</label>
            <JobOnePager jobId={jobId} onePager={onePager} />
        </div>
    ) : null;

    const idealGroupsElements = discipline
        ? idealGroups[discipline].map((item, index) => {
              return (
                  <MenuItem selected={details.idealGroup.indexOf(item) > -1} key={index} value={item}>
                      {_.startCase(item)}
                  </MenuItem>
              );
          })
        : [];

    const jobGroupSelectProps = {
        multiple: true,
        onChange: handleSelectFieldChange('idealGroup'),
        value: details.idealGroup
    };

    const visaSelectProps = {
        multiple: true,
        onChange: handleSelectFieldChange('visa'),
        value: details.visa
    };

    return (
        <>
            <TextField
                label="Job Group"
                select={true}
                value={details.idealGroup}
                fullWidth={true}
                SelectProps={jobGroupSelectProps}
                disabled={!isEditable || !discipline}
            >
                {idealGroupsElements}
            </TextField>

            <label className="textfield-like-label">Keywords</label>
            <TextfieldWithChips
                chips={details.keywords}
                onChange={handleEditorTextChange('keywords')}
                getChipFromString={identity}
                getStringFromChip={identity}
                readonly={!isEditable}
            />
            <TextField
                label="Role description short"
                value={details.onelineDescription}
                onChange={handleDetailsTextfieldChange('onelineDescription')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <Editor
                value={details.description}
                onChange={handleEditorTextChange('description')}
                label="Role description detailed"
                className="job-details-text-editor"
                disabled={!isEditable}
            />
            <TextField
                label="Link to JD"
                value={details.linkToJobDescription}
                onChange={handleDetailsTextfieldChange('linkToJobDescription')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <div style={{ display: 'flex', margin: '10px 0' }}>
                <div style={{ flex: '1 1 auto', marginRight: '25px' }}>
                    <TextField
                        label="Min Years of Experience"
                        value={details.experience?.minYears}
                        onChange={handleExperienceChange('minYears')}
                        fullWidth={true}
                        type="number"
                        disabled={!isEditable}
                    />
                </div>
                <div style={{ flex: '1 1 auto', marginLeft: '25px' }}>
                    <TextField
                        label="Max Years of Experience"
                        value={details.experience?.maxYears}
                        onChange={handleExperienceChange('maxYears')}
                        fullWidth={true}
                        type="number"
                        disabled={!isEditable}
                    />
                </div>
            </div>

            <TextField
                label="Technical Skills"
                value={details.skills}
                onChange={handleDetailsTextfieldChange('skills')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <TextField
                label="Hiring Manager"
                value={details.hiringManagers}
                onChange={handleDetailsTextfieldChange('hiringManagers')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <Editor
                value={details.interviewPrep}
                onChange={handleEditorTextChange('interviewPrep')}
                className="job-details-text-editor"
                label="Interview Prep"
                disabled={!isEditable}
            />
            <TextField
                label="Compensation"
                value={details.compensation}
                onChange={handleDetailsTextfieldChange('compensation')}
                fullWidth={true}
                disabled={!isEditable}
            />
            <TextField
                label="Location"
                value={location}
                onChange={handleJobTextFieldChange('location')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <TextField
                label="Diversity"
                select={true}
                value={details.diversity}
                onChange={handleSelectFieldChange('diversity')}
                disabled={!isEditable}
                fullWidth={true}
            >
                <MenuItem selected={details.diversity === 'diversity_only'} value={'diversity_only'}>
                    Diversity Only
                </MenuItem>
                <MenuItem selected={details.diversity === 'diversity_preferred'} value={'diversity_preferred'}>
                    Diversity Preferred
                </MenuItem>
                <MenuItem selected={details.diversity === 'not_relevant'} value={'not_relevant'}>
                    Not Relevant
                </MenuItem>
            </TextField>

            <TextField
                label="Visa"
                select={true}
                value={details.visa}
                disabled={!isEditable}
                fullWidth={true}
                SelectProps={visaSelectProps}
            >
                <MenuItem selected={details.visa.includes('no_visa')} value={'no_visa'}>
                    No Visa Sponsorship
                </MenuItem>
                <MenuItem selected={details.visa.includes('h1_transfers')} value={'h1_transfers'}>
                    Visa Transfers OK
                </MenuItem>
                <MenuItem selected={details.visa.includes('tn_visa')} value={'tn_visa'}>
                    TN Visa OK
                </MenuItem>
                <MenuItem selected={details.visa.includes('h1_sponsorships')} value={'h1_sponsorships'}>
                    New Visa Sponsorship OK
                </MenuItem>
            </TextField>

            {onePagerForm}
        </>
    );
};
