import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import { Map } from 'immutable';
import * as React from 'react';
import { getPreviousMonths, getPreviousWeeks } from '../common/timestamp';

const styles = css`
    background: white;
    margin: 0 0 0 15px;
    .MuiOutlinedInput-input {
        padding-top: 14px;
        padding-bottom: 14px;
        background: white;
    }
`;

export type RecentJobPeriodFilter =
    | 'lastWeek'
    | 'lastTwoWeeks'
    | 'lastThreeWeeks'
    | 'lastMonth'
    | 'lastSixWeeks'
    | 'lastTwoMonths'
    | 'lastThreeMonths'
    | 'now';

export const recentJobPeriods: RecentJobPeriodFilter[] = [
    'now',
    'lastWeek',
    'lastTwoWeeks',
    'lastThreeWeeks',
    'lastMonth',
    'lastSixWeeks',
    'lastTwoMonths',
    'lastThreeMonths'
];

export const recentJobPeriodFilterLabels: Map<RecentJobPeriodFilter, string> = Map({
    lastMonth: 'One Month Ago',
    lastSixWeeks: 'Six Weeks Ago',
    lastThreeMonths: 'Three Months Ago',
    lastThreeWeeks: 'Three Weeks Ago',
    lastTwoMonths: 'Two Months Ago',
    lastTwoWeeks: 'Two Weeks Ago',
    lastWeek: 'One Week Ago',
    now: 'Now'
}) as Map<RecentJobPeriodFilter, string>;

export enum RecentJobPeriods {
    LastWeek,
    LastTwoWeeks,
    LastThreeWeeks,
    LastMonth,
    LastSixWeeks,
    LastTwoMonths,
    LastThreeMonths,
    Now
}

// tslint:disable:no-magic-numbers
export const recentJobPeriodForFilter: Map<RecentJobPeriodFilter, number> = Map({
    lastMonth: getPreviousMonths(1),
    lastSixWeeks: getPreviousWeeks(6),
    lastThreeMonths: getPreviousMonths(3),
    lastThreeWeeks: getPreviousWeeks(3),
    lastTwoMonths: getPreviousMonths(2),
    lastTwoWeeks: getPreviousWeeks(2),
    lastWeek: getPreviousWeeks(1),
    now: Date.now()
}) as Map<RecentJobPeriodFilter, number>;
// tslint:enable:no-magic-numbers

interface RecentJobsPeriodFiltersProps {
    begin: boolean;
    selectedFilter: RecentJobPeriodFilter;
    onSelectFilter: (filter: RecentJobPeriodFilter) => void;
}

export const RecentJobsPeriodFilters: React.FC<RecentJobsPeriodFiltersProps> = (props) => {
    const { selectedFilter, begin } = props;

    const handleChange = (event: React.ChangeEvent<{ value: RecentJobPeriodFilter }>) => {
        props.onSelectFilter(event.target.value);
    };

    const menuItems = begin
        ? recentJobPeriods
              .filter((_, i) => i !== recentJobPeriods.length - 1)
              .map((filter) => (
                  <MenuItem key={filter} value={filter}>
                      {recentJobPeriodFilterLabels.get(filter)}
                  </MenuItem>
              ))
        : recentJobPeriods
              .filter((_, i) => i !== 0)
              .map((filter) => (
                  <MenuItem key={filter} value={filter}>
                      {recentJobPeriodFilterLabels.get(filter)}
                  </MenuItem>
              ));

    return (
        <Select css={styles} value={selectedFilter} onChange={handleChange} variant="outlined">
            {menuItems}
        </Select>
    );
};
