import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, CircularProgress, InputBase, Paper, Theme, Tooltip, useTheme } from '@material-ui/core';
import { ChevronRight, Phone, Search, Shuffle } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';

import { JobFilter } from 'shared/models/job';
import { addFilter, isJobPassingFilters, phoneScreenFilterPresets } from '../common/job';
import { HOT_CLIENTS } from '../graphql/queries/clients';
import { JobWithDetails, NEW_JOBS } from '../graphql/queries/jobs-table';

import {
    Job,
    PHONESCREEN_JOBS,
    PHONESCREEN_SEARCH_JOBS,
    PhoneScreenJobs as PhoneScreenJobsType
} from '../graphql/queries/person';
import { usePrevious } from '../hooks/use-previous';
import { Job as JobData, JobStatus, JobType, placementFeeJobTypes } from '../state';
import { JobDetails } from './job-details';
import { JobsFilters } from './jobs-filters';

interface PhoneScreenJobsProps {
    personId: string;
    job: JobData;
}

const collapsedStyles = css`
    margin: 14px 15px;
`;

const styles = (theme: Theme) => css`
    flex-grow: 1;
    flex-basis: 0;
    margin: 11px 12px 12px 10px;
    overflow: hidden;
    padding: 3px;

    .phonescreen-jobs-container {
        overflow: hidden;
        padding: 3px;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: -3px;
    }

    .phonescreen-jobs-header {
        display: flex;
        flex: 0 0 auto;
        margin-bottom: 8px;

        .phonescreen-jobs-search-field {
            border-radius: 0;
            border-top-left-radius: ${theme.shape.borderRadius}px;
            flex: 1 1 auto;
            margin-right: 8px;
            display: flex;
            align-items: center;

            .icon {
                flex: 0 0 auto;
                padding: 4px 10px 0;
            }

            .MuiInputBase-root {
                flex: 1 1 auto;
            }
        }
    }

    .phonescreen-jobs-filters {
        transform: scale(0.9);
        transform-origin: top left;
        width: 111%;

        .jobs-filters-chips-section {
            margin: 5px 5px 0;

            .presets-cloud {
                gap: 10px;
            }

            .filter-chips {
                margin: 8px 0;
                gap: 8px;
            }
        }
    }

    .spinner-centered {
        text-align: center;
        padding: 20px;
    }

    .cp-job-title {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        flex: 1 1 auto;
        padding: 12px 20px;
        border-bottom: thin solid ${theme.palette.divider};
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

        .job-title-with-icon {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                margin-right: 10px;
            }
        }
    }

    .panel-body {
        padding: 0;

        .job-details-content {
            padding: 8px 16px;
        }
    }

    .job-details {
        flex: 1 1 auto;
    }

    .collapse-button {
        border-radius: 0;
        border-top-right-radius: ${theme.shape.borderRadius}px;
        cursor: pointer;
        height: 36px;

        .button-icon-wrapper {
            border-radius: 50%;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: ${theme.palette.action.hover};
            }
        }
    }

    .phonescreen-jobs-panel {
        border-radius: 0;
        margin-bottom: 10px;

        &:first-child {
            border-top-left-radius: ${theme.shape.borderRadius}px;
        }

        &:last-child {
            border-bottom-left-radius: ${theme.shape.borderRadius}px;
            border-bottom-right-radius: ${theme.shape.borderRadius}px;
            margin-bottom: 0;
        }
    }

    .phonescreen-jobs-list {
        overflow: auto;
    }

    .phonescreen-jobs-more-list {
        flex: 0 0 auto;
    }

    .phonescreen-job-details {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .job-details-container {
            overflow-y: auto;

            .tabs {
                border-top: none;
            }

            .job-details-content {
                padding: 0 10px;
            }
        }
    }
`;

export const PhoneScreenJobs: React.FC<PhoneScreenJobsProps> = ({ personId, job }) => {
    const theme = useTheme();
    const disciplineFilter = { field: 'discipline' as 'discipline', value: { name: job.discipline } };
    const presets = phoneScreenFilterPresets;
    const { data } = useQuery<PhoneScreenJobsType>(PHONESCREEN_JOBS, {
        variables: { personId, clientId: job.clientId, jobId: job.id }
    });
    const { data: activeJobsData, loading: loadingJobsData } = useQuery<{ jobs: JobWithDetails[] }>(
        PHONESCREEN_SEARCH_JOBS
    );
    const [newJobsCreatedAfter] = React.useState(moment().subtract(1, 'month').valueOf());
    const { data: hotClientsData } = useQuery<{ clients: Array<{ id: string }> }>(HOT_CLIENTS);
    const { data: newJobsData } = useQuery<
        { jobs: Array<{ id: string }> },
        { status: JobStatus[]; types: JobType[]; createdAfter: number }
    >(NEW_JOBS, {
        variables: {
            createdAfter: newJobsCreatedAfter,
            status: [JobStatus.Active],
            types: placementFeeJobTypes
        }
    });
    const [expandedJobId, setExpandedJobId] = React.useState(job.id);
    const [searchText, setSearchText] = React.useState<string>('');
    const [filters, setFilters] = React.useState<JobFilter[]>([]);
    const previousFilters = usePrevious(filters);
    const [collapsed, setCollapsed] = React.useState(false);
    const [disciplineFilterRemoved, setDisciplineFilterRemoved] = React.useState(false);

    const handleSearchKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (searchText?.match(/^anywhere /)) {
                setFilters(
                    addFilter(filters, { field: 'anywhere', value: { name: searchText.replace(/^anywhere /, '') } })
                );
            } else if (searchText?.match(/^and /)) {
                setFilters(
                    addFilter(filters, { field: 'keywords-and', value: { name: searchText.replace(/^and /, '') } })
                );
            } else {
                setFilters(addFilter(filters, { field: 'keywords', value: { name: searchText } }));
            }
            setSearchText('');
        }
    };

    const handleSearchTextChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearchText(event.target.value);
    };

    React.useEffect(() => {
        if (filters.length === 0) {
            setExpandedJobId(job.id);
        } else {
            setExpandedJobId(undefined);
        }
    }, [filters]);

    React.useEffect(() => {
        const removed =
            previousFilters &&
            previousFilters.findIndex((f) => f.field === 'discipline') !== -1 &&
            filters.findIndex((f) => f.field === 'discipline') === -1;
        if (removed) {
            setDisciplineFilterRemoved(true);
        }
        // make sure we search within the job discipline UNLESS user removes that
        if (
            !removed &&
            !disciplineFilterRemoved &&
            filters.length > 0 &&
            !filters.find(
                (f) =>
                    f.field === disciplineFilter.field &&
                    f.values.findIndex((v) => v.name === disciplineFilter.value.name) !== -1
            )
        ) {
            setFilters(addFilter(filters, disciplineFilter));
        }
    }, [filters]);

    React.useEffect(() => {
        if (expandedJobId) {
            if (
                expandedJobId !== job.id &&
                data.cpJobs
                    .map((j) => j.job)
                    .concat(searchMatchJobs)
                    .findIndex((j) => j.id === expandedJobId) === -1
            ) {
                setExpandedJobId(undefined);
            }
        }
    }, [filters]);

    const handlePanelToggle = (id: string) => () => {
        setExpandedJobId(expandedJobId === id ? undefined : id);
    };

    const handleCollapse = () => setCollapsed(!collapsed);

    if (!data) {
        return (
            <div css={styles(theme)}>
                <div className="spinner-centered">
                    <CircularProgress />
                </div>
            </div>
        );
    }

    if (collapsed) {
        return (
            <div css={collapsedStyles}>
                <Paper>
                    <Button onClick={handleCollapse} size="large">
                        Jobs Info
                    </Button>
                </Paper>
            </div>
        );
    }

    let searchMatchJobs: Job[] = [];
    const hotClients = hotClientsData?.clients.map((c) => c.id);
    const newJobs = newJobsData?.jobs.map((j) => j.id);
    if (filters.length > 0 && activeJobsData?.jobs) {
        searchMatchJobs = activeJobsData.jobs?.filter((j) =>
            isJobPassingFilters({ hotClients, newJobs, jobKeywords: job.details.keywords }, filters, j)
        );
    }

    const loadingJobsSpinner =
        filters.length > 0 && loadingJobsData ? (
            <div className="spinner-centered">
                <CircularProgress />
            </div>
        ) : null;

    const scheduledJobIcon = (
        <Tooltip title="Scheduled Call">
            <Phone fontSize="small" />
        </Tooltip>
    );
    const cpJobIcon = (
        <Tooltip title="Crosspitchable Job">
            <Shuffle fontSize="small" />
        </Tooltip>
    );
    const searchedJobIcon = (
        <Tooltip title="Job Matching Search Terms">
            <Search fontSize="small" />
        </Tooltip>
    );

    const listWithIcons: Array<[JSX.Element, Job[]]> = [
        [scheduledJobIcon, data.jobs],
        [cpJobIcon, data.cpJobs.map((j) => j.job)],
        [searchedJobIcon, searchMatchJobs]
    ];

    let content;
    if (expandedJobId) {
        const moreJobsCount = data.jobs.length + data.cpJobs.length + searchMatchJobs.length - 1;
        let moreJobsIndicator;
        if (moreJobsCount === 1) {
            let secondJob;
            let secondJobIcon;
            for (const [icon, jobs] of listWithIcons) {
                secondJob = jobs.find((j) => j.id !== expandedJobId);
                if (secondJob) {
                    secondJobIcon = icon;
                    break;
                }
            }
            moreJobsIndicator = secondJob ? (
                <Paper className="phonescreen-jobs-panel phonescreen-jobs-more-list">
                    <div className="cp-job-title" onClick={handlePanelToggle(secondJob.id)}>
                        <div className="job-title-with-icon">
                            {secondJobIcon} {secondJob.client.name} - {secondJob.title}
                        </div>
                    </div>
                </Paper>
            ) : null;
        } else if (moreJobsCount > 1) {
            moreJobsIndicator = (
                <Paper className="phonescreen-jobs-panel phonescreen-jobs-more-list">
                    <div className="cp-job-title" onClick={handlePanelToggle(undefined)}>
                        <div className="job-title-with-icon">{moreJobsCount} More Jobs</div>
                    </div>
                </Paper>
            );
        }

        let selectedJob;
        let selectedJobIcon;
        for (const [icon, jobs] of listWithIcons) {
            selectedJob = jobs.find((j) => j.id === expandedJobId);
            if (selectedJob) {
                selectedJobIcon = icon;
                break;
            }
        }

        content = selectedJob ? (
            <>
                <Paper className="phonescreen-jobs-panel phonescreen-job-details">
                    <div className="cp-job-title" onClick={handlePanelToggle(expandedJobId)}>
                        <div className="job-title-with-icon">
                            {selectedJobIcon} {selectedJob ? selectedJob.client.name + ' - ' + selectedJob.title : ''}
                        </div>
                    </div>
                    <div className="job-details-container">
                        <JobDetails job={selectedJob} />
                    </div>
                </Paper>
                {moreJobsIndicator}
            </>
        ) : null;
    } else {
        const jobsList = listWithIcons.map(([icon, jobs], i) => {
            const jobRows = jobs.map((j) => (
                <div className="cp-job-title" onClick={handlePanelToggle(j.id)} key={j.id}>
                    <div className="job-title-with-icon">
                        {icon} {j.client.name} - {j.title}
                    </div>
                </div>
            ));
            return <React.Fragment key={i}>{jobRows}</React.Fragment>;
        });
        content = (
            <Paper className="phonescreen-jobs-panel phonescreen-jobs-list">
                {jobsList}
                {loadingJobsSpinner}
            </Paper>
        );
    }

    return (
        <div css={styles(theme)}>
            <div className="phonescreen-jobs-container">
                <div className="phonescreen-jobs-header">
                    <Paper className="phonescreen-jobs-search-field">
                        <div className="icon">
                            <Search />
                        </div>
                        <InputBase
                            fullWidth={true}
                            placeholder="Search…"
                            value={searchText}
                            onChange={handleSearchTextChange}
                            onKeyPress={handleSearchKeyPress}
                        />
                    </Paper>
                    <Paper className="collapse-button" onClick={handleCollapse}>
                        <div className="button-icon-wrapper">
                            <Tooltip title="Collapse">
                                <ChevronRight />
                            </Tooltip>
                        </div>
                    </Paper>
                </div>
                <div className="phonescreen-jobs-filters">
                    <JobsFilters presets={presets} selected={filters} onChange={setFilters} />
                </div>
                {content}
            </div>
        </div>
    );
};
