import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { omit } from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { JobStatus } from 'shared/models/job';
import { SequenceBaseData } from 'shared/models/sequence';
import { hasRole } from 'shared/models/user';

import { CREATE_JOB, JobWithSequence } from '../graphql/queries/job';
import { JOB_SEQUENCES_DATA } from '../graphql/queries/sequences';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { Job } from '../state';

interface JobCloneProps {
    sourceJobId: string;
    data: Partial<Job>;
    disabled: boolean;
}

export const JobCloneButton: React.FC<JobCloneProps> = ({ sourceJobId, data, disabled }) => {
    const [saving, setSaving] = React.useState(false);
    const [redirectTo, setRedirectTo] = React.useState<string>(null);
    const { user, userPermissions } = useSession();
    const { getConfirmation } = useModal();
    const { setSnackbar } = useSnackbar();
    const { data: sequencesData } = useQuery<
        { sequences: Array<SequenceBaseData & { archived: boolean }> },
        { jobId: string }
    >(JOB_SEQUENCES_DATA, {
        variables: { jobId: sourceJobId }
    });
    const [insertJob] = useMutation<{ jobs: { returning: Array<{ id: string }> } }, { job: JobWithSequence }>(
        CREATE_JOB
    );

    const createJob = async () => {
        const sequences = sequencesData.sequences.filter((s) => !s.archived).map((s) => omit(s, ['__typename']));
        const job = {
            ...data,
            accountManagerId: user.id,
            assignee: user.id,
            sequences: { data: sequences },
            status: JobStatus.Paused,
            title: 'Copy of ' + data.title
        };
        setSaving(true);
        const result = await insertJob({ variables: { job } });
        setSnackbar('Job created');
        setRedirectTo(`/job/${result.data.jobs.returning[0].id}/edit`);
    };

    const handleClick = () => {
        getConfirmation(
            createJob,
            'This will create a new Job with same settings as the current job',
            'Please confirm'
        );
    };

    if (!hasRole(userPermissions, 'job_creator')) return null;

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    const modal = saving ? (
        <Dialog open={true}>
            <DialogContent style={{ padding: '20px 40px' }}>Please wait...</DialogContent>
        </Dialog>
    ) : null;

    return (
        <>
            <Button variant="contained" disabled={disabled} onClick={handleClick}>
                Clone This Job
            </Button>
            {modal}
        </>
    );
};
