import { css } from '@emotion/core';
import {
    Dialog,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    IconButton,
    MenuItem,
    Typography
} from '@material-ui/core';
import { Edit, ExpandMore } from '@material-ui/icons';
import { Map } from 'immutable';
import * as React from 'react';

import { EmailTemplateView } from 'shared/models/email-templates';

import { ControlledSelectField } from '../core-ui/controlled-select-field';
import { JobTemplates, JobType } from '../state';
import { EmailTemplate } from './email-template';

const styles = css`
    .MuiDialog-paper {
        width: 60%;
    }
`;

interface JobTemplateSettingCardProps {
    isEditable: boolean;
    jobTemplates: JobTemplates;
    onChange: (templates: JobTemplates) => void;
    showModalAlert: (description: string | JSX.Element, title: string) => void;
    emailTemplates: Map<string, EmailTemplateView[]>;
    jobType: JobType;
}

const templateTypeLabel: { [type in keyof JobTemplates]: string } = {
    introduction: 'Introduction',
    phonescreen: 'Phonescreen',
    preIntroduction: 'Pre Introduction',
    submission: 'Submission'
};

export const JobTemplateSettingCard: React.FC<JobTemplateSettingCardProps> = (props) => {
    const { jobTemplates, isEditable, emailTemplates, jobType } = props;

    const [editingTemplate, setEditingTemplate] = React.useState<{
        type: keyof JobTemplates;
        template: EmailTemplateView;
    }>(null);
    const [openMenuType, setOpenMenuType] = React.useState<keyof JobTemplates>(null);

    const handleRequestOpen = (type: keyof JobTemplates) => () => {
        setOpenMenuType(type);
    };
    const handleRequestClose = () => {
        setOpenMenuType(null);
    };

    const defaultTemplate = (type: keyof JobTemplates) => {
        const typeDefault: {
            [t in keyof JobTemplates]: {
                group: string;
                kind: string;
            };
        } = {
            introduction: {
                group: 'introduction',
                kind: 'Intro Client - Candidate'
            },
            phonescreen: {
                group: 'phonescreen',
                kind: 'Phonescreen'
            },
            preIntroduction: {
                group: 'introduction',
                kind: 'Pre - intro email to candidate'
            },
            submission: {
                group: 'submission',
                kind: jobType === 'S' ? 'Client Submission - Sourcer' : 'Client Submission - Default'
            }
        };

        let template = emailTemplates.get(typeDefault[type].group).find((t) => t.kind === typeDefault[type].kind);
        template = { ...template, id: null, createdAt: null, modifiedAt: null };
        return template;
    };

    const handleTemplateSelect = (type: keyof JobTemplates, value: 'default' | 'custom') => () => {
        if (value === 'default') {
            props.onChange({ ...jobTemplates, [type]: null });
        } else if (value === 'custom') {
            props.onChange({
                ...jobTemplates,
                [type]: jobTemplates[type] || defaultTemplate(type)
            });
        }
        handleRequestClose();
    };

    const openEmailTemplateEditor = (type: keyof JobTemplates) => () => {
        const templateToEdit = jobTemplates[type] || defaultTemplate(type);
        setEditingTemplate({
            template: templateToEdit,
            type
        });
        handleRequestClose();
    };

    const closeEmailTemplateEditor = () => {
        setEditingTemplate(null);
    };

    const handleEditTemplate = (type: keyof JobTemplates) => (data: Partial<EmailTemplateView>) => {
        props.onChange({
            ...jobTemplates,
            [type]: { ...jobTemplates[type], ...data }
        });
        closeEmailTemplateEditor();
    };

    const templates = (['phonescreen', 'submission', 'preIntroduction', 'introduction'] as Array<
        keyof JobTemplates
    >).map((type) => {
        const value = jobTemplates[type] ? 'custom' : 'default';
        const valueLabel = value === 'custom' ? 'Custom' : 'Default';
        const editButton = (
            <IconButton size="small" onClick={openEmailTemplateEditor(type)}>
                <Edit fontSize="small" />
            </IconButton>
        );

        const menuOpen = openMenuType === type;
        return (
            <ControlledSelectField
                key={type}
                open={menuOpen}
                selectedText={valueLabel}
                label={templateTypeLabel[type]}
                handleRequestClose={handleRequestClose}
                handleRequestOpen={handleRequestOpen(type)}
            >
                <MenuItem
                    selected={value === 'default'}
                    disabled={!isEditable}
                    onClick={handleTemplateSelect(type, 'default')}
                    value="default"
                >
                    Default
                </MenuItem>
                <MenuItem
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    selected={value === 'custom'}
                    disabled={!isEditable}
                    onClick={handleTemplateSelect(type, 'custom')}
                    value="custom"
                >
                    Custom
                    {editButton}
                </MenuItem>
            </ControlledSelectField>
        );
    });

    let dialog;
    if (editingTemplate) {
        dialog = (
            <Dialog open={true} maxWidth={'md'} css={styles}>
                <EmailTemplate
                    template={editingTemplate.template}
                    onSave={handleEditTemplate(editingTemplate.type)}
                    onCancel={closeEmailTemplateEditor}
                    onDelete={null}
                    showModalAlert={props.showModalAlert}
                    saving={false}
                    readonly={!isEditable}
                />
            </Dialog>
        );
    }
    return (
        <div className="job-settings-card">
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ lineHeight: '24px' }}>
                    <div>
                        <Typography variant="h5" component="div">
                            Job Templates
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="div">
                            View/Edit Job Templates
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <div>{templates}</div>
                </ExpansionPanelDetails>
                {dialog}
            </ExpansionPanel>
        </div>
    );
};
