// cspell:ignore Accountmanagerid
import { gql } from '@apollo/client';

import { CandidateDisqualReason } from 'shared/models/job-stages';

export interface CandidateScore {
    id: string;
    score: number;
    scoreExplanation: string;
    generationDetails: string;
    searchProfilesScoreId: string;
    user: {
        id: string;
        name: string;
    };
    createdAt: number;
}

export interface Candidate {
    personId: string;
    jobId: string;
    lastStageChangedAt: number;
    disqualifiedAt: number;
    disqualReason: CandidateDisqualReason;
    job: {
        title: string;
        client: {
            name: string;
        };
    };
    assignee: {
        name: string;
        id: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    person: {
        name: string;
    };
    scores: CandidateScore[];
}

export interface CandidateUsers {
    assignee: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
}

const candidateFields = gql`
    fragment candidateFields on candidates {
        personId
        jobId
        lastStageChangedAt
        job {
            title
            client {
                name
            }
        }
        accountManager: userByAccountmanagerid {
            id
            name(path: "full")
        }
        assignee: userByAssignee {
            name(path: "full")
            id
        }
        person {
            name(path: "full")
        }
        scores {
            id
            score
            scoreExplanation
            generationDetails
            searchProfilesScoreId
            user {
                id
                name(path: "full")
            }
            createdAt
        }
        disqualifiedAt
        disqualReason
    }
`;

export const PLACEMENTS = gql`
    ${candidateFields}
    query Placements($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        candidates(
            where: {
                job: { jobType: { _in: $jobType }, clientId: { _nin: $excludeClientIds } }
                stage: { _eq: "hired" }
                disqualified: { _eq: false }
                hiredAt: { _gte: $startTime, _lte: $endTime }
            }
            order_by: { lastStageChangedAt: desc }
        ) {
            ...candidateFields
            hiredAt
            fees
            startDate
        }
    }
`;

export const STAGE_CANDIDATES = gql`
    ${candidateFields}
    query StageCandidates($jobType: [String!], $excludeClientIds: [String!], $stages: [String!]) {
        candidates(
            where: {
                job: { jobType: { _in: $jobType }, clientId: { _nin: $excludeClientIds }, status: { _in: [1, 2] } }
                stage: { _in: $stages }
                disqualified: { _eq: false }
            }
            order_by: { lastStageChangedAt: desc }
        ) {
            ...candidateFields
        }
    }
`;

export const DISQUALIFIED_CANDIDATES = gql`
    ${candidateFields}
    query DisqualifiedCandidates(
        $jobType: [String!]
        $excludeClientIds: [String!]
        $stages: [String!]
        $startTime: bigint!
        $endTime: bigint!
    ) {
        candidates(
            where: {
                job: { jobType: { _in: $jobType }, clientId: { _nin: $excludeClientIds } }
                stage: { _in: $stages }
                disqualified: { _eq: true }
                disqualifiedAt: { _gte: $startTime, _lte: $endTime }
            }
            order_by: { lastStageChangedAt: desc }
        ) {
            ...candidateFields
        }
    }
`;

export const UPDATE_CANDIDATE_HIRING_INFO = gql`
    mutation UpdateCandidate($personId: String!, $jobId: String!, $updates: candidates_set_input!) {
        update_candidates(where: { personId: { _eq: $personId }, jobId: { _eq: $jobId } }, _set: $updates) {
            returning {
                _id
                hiredAt
                hiredSalary
                paidAt
                invoicedAt
                prePaidFees
                signOnBonus
                fees
                startDate
                recruiterCommission
                amCommission
                recruiterCommissionPaidAt
                amCommissionPaidAt
            }
        }
    }
`;

export const CANDIDATE_USERS = gql`
    query CandidateUsers($personId: String!, $jobId: String!) {
        candidates(where: { personId: { _eq: $personId }, jobId: { _eq: $jobId } }) {
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
        }
    }
`;
