import { Map } from 'immutable';
import { difference } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { createNewJob, getConfirmation, showModalAlert } from '../actions';
import { JobForm } from '../components/job-form';
import { Client, Job, List, RequestErrors, State } from '../state';

interface ConnectedProps {
    clients: List<Client>;
    pendingRequests: Map<string, RequestErrors>;
    user: UserData;
    jobs: Map<string, Job>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    createNewJob: (params: Partial<Job>) => void;
    showModalAlert: (description: string | JSX.Element, title: string) => void;
    getConfirmation: (onConfirm: () => void, description?: string | JSX.Element, title?: string) => void;
}

type NewJobComponentProps = ConnectedDispatch & ConnectedProps;

interface NewJobComponentState {
    redirect: boolean;
    newJobId: string;
}

class NewJobComponent extends React.Component<NewJobComponentProps, NewJobComponentState> {
    constructor(props: NewJobComponentProps) {
        super(props);
        this.state = { redirect: false, newJobId: null };
    }

    componentDidUpdate(prevProps: NewJobComponentProps) {
        const requestKey = 'job-create';
        if (prevProps.pendingRequests.has(requestKey) && !this.props.pendingRequests.has(requestKey)) {
            const oldJobIds = prevProps.jobs.keySeq().toArray();
            const newJobIds = this.props.jobs.keySeq().toArray();
            const newJobId = difference(newJobIds, oldJobIds)[0];
            this.setState({ redirect: true, newJobId });
        }
    }

    handleSave = (params: Partial<Job>) => {
        this.props.createNewJob(params);
    };

    handleCancel = () => {
        this.setState({ redirect: true });
    };

    render() {
        const { pendingRequests, user, clients, userPermissions } = this.props;
        if (!hasRole(userPermissions, 'job_creator')) {
            return <Redirect to="/jobs" />;
        }
        if (this.state.redirect) {
            if (this.state.newJobId) {
                return <Redirect to={`/job/${this.state.newJobId}/edit`} push={true} />;
            } else {
                return <Redirect to="/jobs" push={true} />;
            }
        }
        const requestKey = 'job-create';
        const isCreating = pendingRequests.has(requestKey) && pendingRequests.get(requestKey).isEmpty();
        return (
            <JobForm
                clients={clients}
                formTitle="New Job"
                isCreating={isCreating}
                isEditable={true}
                onSave={this.handleSave}
                newJob={true}
                user={user}
                userPermissions={userPermissions}
                getConfirmation={this.props.getConfirmation}
                showModalAlert={this.props.showModalAlert}
            />
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    jobs: state.jobs,
    pendingRequests: state.pendingRequests,
    user: state.session.user,
    userPermissions: state.session.userPermissions
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    createNewJob,
    getConfirmation,
    showModalAlert
};
export const NewJob = connect<ConnectedProps, ConnectedProps, {}>(mapStateToProps, mapDispatchToProps)(NewJobComponent);
