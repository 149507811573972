import { TextField } from '@material-ui/core';
import _ from 'lodash';
import * as React from 'react';

import { Job } from '../state';

interface JobAIContextFormProps {
    outreachAIAdditionalContext: string;
    scoringAIAdditionalContext: string;
    submissionGenAIAdditionalContext: string;
    isEditable: boolean;
    onChange: <K extends keyof Job>(field: K, value: Job[K]) => void;
}

export const JobAIContextForm: React.FC<JobAIContextFormProps> = ({
    isEditable,
    onChange,
    outreachAIAdditionalContext,
    scoringAIAdditionalContext,
    submissionGenAIAdditionalContext
}) => {
    const handleJobTextFieldChange = (
        field: 'submissionGenAIAdditionalContext' | 'outreachAIAdditionalContext' | 'scoringAIAdditionalContext'
    ) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(field, event.target.value);
    };

    return (
        <>
            <TextField
                label="Initial Outreach Generation"
                value={outreachAIAdditionalContext ?? ''}
                onChange={handleJobTextFieldChange('outreachAIAdditionalContext')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <TextField
                label="Profile Scoring"
                value={scoringAIAdditionalContext ?? ''}
                onChange={handleJobTextFieldChange('scoringAIAdditionalContext')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
            <TextField
                label="Submission Generation"
                value={submissionGenAIAdditionalContext ?? ''}
                onChange={handleJobTextFieldChange('submissionGenAIAdditionalContext')}
                fullWidth={true}
                multiline={true}
                disabled={!isEditable}
            />
        </>
    );
};
