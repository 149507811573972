import { MenuList, Popover } from '@material-ui/core';
import * as React from 'react';

interface ControlledSelectFieldProps {
    open: boolean;
    label: string;
    selectedText: string;

    handleRequestClose: () => void;
    handleRequestOpen: () => void;
}

export const ControlledSelectField: React.FC<ControlledSelectFieldProps> = (props) => {
    const { label, open, selectedText } = props;

    const menuRef = React.useRef(null);
    const [anchor, setAnchor] = React.useState<Element>(null);

    const handleRequestOpen = (event: React.MouseEvent<HTMLElement>) => {
        props.handleRequestOpen();
        setAnchor(event.currentTarget);
    };

    const handleRequestClose = () => {
        props.handleRequestClose();
        setAnchor(null);
    };

    const popupWidth = menuRef && menuRef.current ? menuRef.current.clientWidth : 0;

    return (
        <div className="multi-select">
            <label className="editor-label">{label}</label>
            <div className="multi-select-text" ref={menuRef} onClick={handleRequestOpen}>
                {selectedText}
                <div className="multi-select-dropdown-arrow">
                    <i className="material-icons">arrow_drop_down</i>
                </div>
            </div>
            <Popover
                open={open}
                anchorEl={anchor}
                onClose={handleRequestClose}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                className="multi-select-popup"
            >
                <MenuList style={{ width: popupWidth }}>{props.children}</MenuList>
            </Popover>
        </div>
    );
};
