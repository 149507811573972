import { useQuery, useSubscription } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    CircularProgress,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Tooltip,
    useTheme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Redirect } from 'react-router';

import { hasJobPermission } from 'shared/models/job';
import { BASELINE_SEARCH_SORT_RANK, SearchResultsViewType } from 'shared/models/search';
import { hasRole } from 'shared/models/user';

import {
    deleteSearch,
    fetchJobInfo,
    fetchJobSearchStats,
    pauseJobSearches,
    receiveNewSearch,
    updateSearch
} from '../actions';
import { JOB_OUTREACHED_CANDIDATES_COUNT } from '../graphql/queries/job';
import { JOB_SEARCHES, JobSearch } from '../graphql/queries/search';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useModal } from '../hooks/use-modal';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { User } from '../sfc/user';
import { JobSearchesState as JobSearchesStateType, Search, SearchStatus, State } from '../state';
import { JobPageHeader } from './job-page-header';
import { JobSearchesProgress } from './job-searches-progress';
import { JobSearchesStats } from './job-searches-stats';
import { SimilaritySearchButton } from './similarity-search-button';

const styles = css`
    .MuiPaper-root {
        overflow: hidden;
    }

    .MuiTableContainer-root {
        &::-webkit-scrollbar:horizontal {
            border-top: thin solid #e0e0e0;
        }

        &::-webkit-scrollbar:vertical {
            border-left: thin solid #e0e0e0;
        }

        .MuiTableRow-hover {
            &.inactive {
                background: #fafafa;
                color: rgba(0, 0, 0, 0.6);
            }

            &:hover {
                background: rgba(0, 0, 0, 0.06);
            }
        }

        .MuiTableRow-root:last-child {
            td.MuiTableCell-root.MuiTableCell-body {
                border-bottom: none;
            }
        }

        .baseline {
            .MuiTableCell-root {
                font-weight: 500;
            }
        }
    }
`;

const settingsStyles = css`
    .MuiInputBase-root {
        margin-right: 20px;

        &:before {
            border-bottom: none;
        }
        &:after {
            border-bottom: none;
        }
        &:hover:not(.Mui-disabled):before {
            border-bottom: none;
        }
    }
`;

const loadingTableStyle = (theme: Theme) => css`
    background: white;
    border: thin solid ${theme.palette.divider};
    border-radius: ${theme.shape.borderRadius}px;
`;

export const JobSearches: React.FC<{ id: string }> = ({ id }) => {
    const theme = useTheme();
    const { user, userPermissions } = useSession();
    const [statusFilter, setStatusFilter] = useLocalStorage<'active' | 'paused' | 'activeOrPaused'>(
        'job-searches-status-filter-key',
        'active'
    );
    const [savedSearchOwner, setSavedSearchOwner] = useLocalStorage('job-searches-owner-filter-key', user.id);
    const [searchOwner, setSearchOwner] = useState(savedSearchOwner);
    const [viewingSearchInfoId, setViewingSearchInfoId] = useState<string | null>(null);
    const [viewingResults, setViewingResults] = useState<{
        searchId: string;
        resultsType: SearchResultsViewType;
    } | null>(null);
    const [creatingSearch, setCreatingSearch] = useState(false);
    const [stats, setStats] = useState<'sourcing' | 'progress'>('sourcing');
    const [displayPercent, setDisplayPercent] = useState<'count' | 'percent' | 'both'>('count');
    const [displayDetail, setDisplayDetail] = useState(false);
    const dispatch = useReduxDispatch();
    const jobs = useReduxState((state: State) => state.jobs);
    const searches = useReduxState((state: State) => state.searches);
    const pendingRequests = useReduxState((state: State) => state.pendingRequests);
    const jobStages = useReduxState((state: State) => state.appConstants.constants.jobStages);
    const { getConfirmation } = useModal();
    const { data: searchDataSub } = useSubscription<{ searches: JobSearch[] }, { jobId: string }>(JOB_SEARCHES, {
        variables: { jobId: id }
    });
    const { data: outreachedData } = useQuery<{ job: { outreachedCandidatesCount: number } }, { id: string }>(
        JOB_OUTREACHED_CANDIDATES_COUNT,
        { variables: { id } }
    );
    const outreachedCandidatesCount = outreachedData?.job?.outreachedCandidatesCount;

    useEffect(() => {
        if (!jobs || !jobs.has(id)) {
            dispatch(fetchJobInfo(id));
        }
        if (!searches || !searches.has(id)) {
            dispatch(fetchJobSearchStats(id));
        }
    }, [id, dispatch, jobs, searches]);

    useEffect(() => {
        const reduxStateSearches = searches?.get(id)?.searches;
        if (searchDataSub && reduxStateSearches) {
            // find any searches that are in this sub
            // and are not in the searches object or the status does not match
            const mismatch =
                searchDataSub.searches.some((s) => {
                    const search = reduxStateSearches.find((ss) => ss.id === s.id);
                    return (
                        !search ||
                        search.status !== s.status ||
                        search.searchProfilesScoreId !== s.searchProfilesScoreId
                    );
                }) ||
                reduxStateSearches
                    .filter((s) => s.id)
                    .some((s) => {
                        const search = searchDataSub.searches.find((ss) => ss.id === s.id);
                        return (
                            !search ||
                            search.status !== s.status ||
                            search.searchProfilesScoreId !== s.searchProfilesScoreId
                        );
                    });
            if (mismatch) {
                dispatch(fetchJobSearchStats(id));
            }
        }
    }, [searchDataSub, dispatch, searches]);

    const handleSetStatsView = (updatedStats: 'sourcing' | 'progress') => () => {
        let updatedDisplayPercent = displayPercent;
        let updatedDisplayDetail = displayDetail;
        if (updatedStats === 'progress' && updatedStats === 'progress') {
            updatedDisplayPercent =
                updatedDisplayPercent === 'count' ? 'both' : updatedDisplayPercent === 'both' ? 'percent' : 'count';
        } else if (updatedStats === 'sourcing' && updatedStats === 'sourcing') {
            updatedDisplayDetail = !updatedDisplayDetail;
        } else {
            updatedDisplayPercent = 'count';
            updatedDisplayDetail = false;
        }
        setStats(updatedStats);
        setDisplayPercent(updatedDisplayPercent);
        setDisplayDetail(updatedDisplayDetail);
    };

    const handleViewSearchInfo = (searchId: string) => {
        setViewingSearchInfoId(searchId);
    };

    const handleViewSearchResults = (viewing: { searchId: string; resultsType: SearchResultsViewType }) => () => {
        setViewingResults(viewing);
    };

    const handleOpenNewSearchDialog = () => {
        setCreatingSearch(true);
    };

    const handleCloneSearch = (searchData: Search) => {
        const searchInProgress = jobSearches.find((s) => s.status === SearchStatus.Initial);
        if (searchInProgress) {
            dispatch(deleteSearch(searchInProgress));
        }
        const searchToClone = Object.assign({}, searchData, {
            createdAt: undefined,
            createdBy: user.id,
            id: undefined,
            jobId: id,
            maxProfileAgeInDays: -1,
            minSearchProfileScore: 0,
            modifiedAt: undefined,
            name: `Copy of ${searchData.name}`,
            searchProfilesScoreId: null,
            sortRank: undefined,
            status: SearchStatus.Initial,
            userId: user.id
        });
        dispatch(receiveNewSearch(searchToClone));
        setCreatingSearch(true);
        setViewingSearchInfoId(null);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<{ value: 'active' | 'paused' | 'activeOrPaused' }>) => {
        setStatusFilter(event.target.value);
    };

    const handleSearchOwnerChange = (event: React.ChangeEvent<{ value: string }>) => {
        setSearchOwner(event.target.value);
        if (event.target.value === 'all' || event.target.value === user.id) {
            setSavedSearchOwner(event.target.value);
        }
    };

    const filteredSearches = (jobSearch: JobSearchesStateType) => {
        const list = jobSearch?.searches ?? [];
        if (statusFilter === 'active') {
            return list.filter(
                (s) =>
                    (s.status !== SearchStatus.Paused && s.status !== SearchStatus.Archived) ||
                    s.sortRank === BASELINE_SEARCH_SORT_RANK
            );
        } else if (statusFilter === 'paused') {
            return list.filter((s) => s.status === SearchStatus.Paused || s.status === SearchStatus.Archived);
        } else {
            return list;
        }
    };

    const handlePauseAllSearches = () => {
        getConfirmation(
            () => {
                dispatch(pauseJobSearches(id));
            },
            'This will pause all of your searches in this job',
            'Confirm Pause Searches'
        );
    };

    const handleUpdateSearch = (searchId: string, updates: Partial<Search>, refetchSearches: boolean) => {
        dispatch(updateSearch(searchId, updates, refetchSearches));
    };

    const handleDeleteSearch = (search: Search) => {
        dispatch(deleteSearch(search));
    };

    const job = jobs?.get?.(id);
    const jobSearchData = searches?.get?.(id);

    const hasSearchAccess = hasRole(userPermissions, 'search_create_access');

    const leftJobSearchActions: JSX.Element[] = [];
    const rightJobSearchActions: JSX.Element[] = [];
    if (hasSearchAccess) {
        const pausingDisabled =
            !jobSearchData ||
            !jobSearchData.searches?.find((s) => s.status === SearchStatus.Active) ||
            pendingRequests.has(`job-${id}-pause-all-searches`);

        leftJobSearchActions.push(
            <Button onClick={handlePauseAllSearches} disabled={pausingDisabled} key="pause-all-searches">
                Pause All
            </Button>
        );

        const hasSearchCreatePermission = hasJobPermission(job?.recruiterPermissions.createSearch, user.id);

        const disabled = pendingRequests.has('search-create') || !hasSearchCreatePermission;

        const addNewSearchButton = (
            <Button
                onClick={handleOpenNewSearchDialog}
                disabled={disabled || !jobSearchData}
                style={disabled ? { pointerEvents: 'none' } : {}}
            >
                Add new search
            </Button>
        );

        const addNewSearchButtonWithTooltip = (
            <Tooltip title={'Search create disallowed - contact job AM'}>
                <span>{addNewSearchButton}</span>
            </Tooltip>
        );

        rightJobSearchActions.push(
            <div key="add-search">
                {!hasSearchCreatePermission ? addNewSearchButtonWithTooltip : addNewSearchButton}
            </div>
        );
    }

    const jobSearches = filteredSearches(jobSearchData);
    const jobProgressInfo = jobSearchData ? jobSearchData.progressCounts : [];
    const searchStats = jobSearchData ? jobSearchData.searchStats : [];

    if (creatingSearch) {
        const creatingSearchUrl = `/job/${id}/search/`;
        return <Redirect to={creatingSearchUrl} push={true} />;
    } else if (viewingSearchInfoId) {
        const viewingSearchUrl = `/job/${id}/search/${viewingSearchInfoId}/total`;
        return <Redirect to={viewingSearchUrl} push={true} />;
    } else if (viewingResults) {
        const search = jobSearches.find((s) => s.id === viewingResults.searchId);
        const viewingResultsUrl = `/job/${id}/search/${search.id}/${viewingResults.resultsType}`;
        return <Redirect to={viewingResultsUrl} push={true} />;
    }

    const loadingRowsCount = 5;
    const loadingRows = !jobSearchData
        ? Array.from({ length: loadingRowsCount }).map((_, i) => (
              <TableRow key={i}>
                  <TableCell>
                      <Skeleton className="interview skeleton" variant="rect" key={i} />
                  </TableCell>
              </TableRow>
          ))
        : null;

    const jobSearchesByUser =
        searchOwner === 'all'
            ? jobSearches
            : jobSearches.filter((s) => s.userId === searchOwner || s.sortRank === BASELINE_SEARCH_SORT_RANK);
    const statsTable = !jobSearchData ? (
        <Table css={loadingTableStyle(theme)}>
            <TableBody>{loadingRows}</TableBody>
        </Table>
    ) : stats === 'sourcing' ? (
        <JobSearchesStats
            displayDetail={displayDetail}
            job={job}
            searches={jobSearchesByUser}
            searchStats={searchStats}
            user={user}
            outreachedCandidatesCount={outreachedCandidatesCount}
            onDisplaySearchInfo={handleViewSearchInfo}
            onViewResults={handleViewSearchResults}
            onCloneSearch={handleCloneSearch}
            onUpdateSearch={handleUpdateSearch}
            onDeleteSearch={handleDeleteSearch}
            userPermissions={userPermissions}
        />
    ) : (
        <JobSearchesProgress
            job={job}
            progressCounts={jobProgressInfo}
            searches={jobSearchesByUser}
            onDisplaySearchInfo={handleViewSearchInfo}
            displayPercent={displayPercent}
            onCloneSearch={handleCloneSearch}
            jobStages={jobStages}
            onUpdateSearch={handleUpdateSearch}
            onDeleteSearch={handleDeleteSearch}
            user={user}
            userPermissions={userPermissions}
        />
    );
    const loadingSize = 20;
    const loadingThickness = 2;
    const statsSelector = pendingRequests.has(`job-searches-stats-${id}`) ? (
        <div className="job-searches-table-stats-selector loading">
            Loading Stats
            <CircularProgress size={loadingSize} thickness={loadingThickness} style={{ marginLeft: 15, bottom: 1 }} />
        </div>
    ) : (
        <div className="job-searches-table-stats-selector">
            <span className={stats === 'sourcing' ? 'active' : ''} onClick={handleSetStatsView('sourcing')}>
                Sourcing
                {displayDetail ? ' (detailed stats)' : ''}
            </span>
            <span className={stats === 'progress' ? 'active' : ''} onClick={handleSetStatsView('progress')}>
                Candidate Progress
                {displayPercent === 'count' ? '' : displayPercent === 'both' ? ' (%)' : '%'}
            </span>
        </div>
    );

    const filterLabels: { [status: string]: string } = {
        active: 'Active',
        activeOrPaused: 'Active / Paused',
        paused: 'Paused'
    };

    const searchStatusFilterOptions = ['active', 'paused', 'activeOrPaused'].map((s) => (
        <MenuItem key={s} value={s}>
            {filterLabels[s]}
        </MenuItem>
    ));
    const searchStatusFilterSelect = (
        <Select value={statusFilter} onChange={handleStatusFilterChange}>
            {searchStatusFilterOptions}
        </Select>
    );

    const searchOwnerOptions = ['all', user.id]
        .concat(uniq(jobSearches.filter((s) => s.userId !== user.id).map((s) => s.userId)))
        .map((s) => {
            const text = s === 'all' ? <span>{'All'}</span> : <User id={s} />;
            return (
                <MenuItem key={s} value={s}>
                    {text}
                </MenuItem>
            );
        });

    const searchOwnerSelect = (
        <Select value={searchOwner} onChange={handleSearchOwnerChange}>
            {searchOwnerOptions}
        </Select>
    );

    return (
        <DocumentTitle title="Job Searches">
            <div id="container">
                <JobPageHeader jobId={id} actions={[]} activeTab="Searches" />
                <div id="content" className="flex-fill">
                    <div className="job-searches-container">
                        <div className="job-searches-section">
                            <div className="job-searches-header">
                                <div className="job-searches-job-settings" css={settingsStyles}>
                                    <div className="job-searches-filter-select with-border">
                                        {searchStatusFilterSelect}
                                    </div>
                                    <div className="job-searches-filter-select">{searchOwnerSelect}</div>
                                </div>
                                {statsSelector}
                            </div>
                        </div>
                        <div className="job-searches-section job-searches-table" css={styles}>
                            {statsTable}
                        </div>
                        <div className="job-searches-section job-searches-actions">
                            <div>{leftJobSearchActions}</div>
                            <div>{rightJobSearchActions}</div>
                        </div>
                        <SimilaritySearchButton />
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
