import { css } from '@emotion/core';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    MenuItem,
    Paper,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Map } from 'immutable';
import * as _ from 'lodash';
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { EmailTemplateView } from 'shared/models/email-templates';
import {
    defaultJobDetails,
    JobDetails,
    jobPrepayRequired,
    JobRecruiterPermissions as JobRecruiterPermissionsType,
    JobSubmissionSetting,
    JobSubmissionType
} from 'shared/models/job';
import { JobDetails as JobDetailsType } from 'shared/models/job';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { borderColor } from '../common/css-variables';
import { jobDisciplines } from '../common/job-disciplines';
import { JobSequencesForm } from '../containers/job-sequences-form';
import {
    Client,
    ContactType,
    DefaultJobType,
    EmailAccount,
    Job,
    JobStatus,
    JobStatusLabels,
    JobTemplates,
    JobType,
    JobTypes,
    List,
    PreferredContactType,
    RequestErrors
} from '../state';
import { ClientFormDialog } from './client-form-dialog';
import { ClientSelector } from './client-selector';
import { CompanyInfoForm } from './company-info-form';
import { JobAIContextForm } from './job-ai-context-form';
import { JobBillingInfo } from './job-billing-info';
import { JobClientInfoCard } from './job-client-info-card';
import { JobCloneButton } from './job-clone-button';
import { JobDetailsForm } from './job-details-form';
import { JobFunnelTargetsForm } from './job-funnel-targets-form';
import { JobRecruiterPermissions } from './job-recruiter-permissions';
import { JobTemplateSettingCard } from './job-template-setting-card';
import { UserSelector } from './user-selector';

const styles = css`
    .MuiFormControl-root {
        margin: 15px 0;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .job-settings-card {
        .MuiExpansionPanelDetails-root {
            display: block;
        }

        .MuiExpansionPanel-root {
            box-shadow: none;
            border: thin solid ${borderColor};
            border-radius: 4px;
            padding: 8px 16px;
        }
    }
`;

const maxDailyEmailLimit = 500;
const minRecruiterPercentage = 8;
const totalPercentage = 15;

interface JobFormProps extends Partial<Job> {
    formTitle: string;
    clients: List<Client>;
    newJob: boolean;
    user: UserData;
    userPermissions: Permissions;
    isCreating: boolean;
    isEditable: boolean;
    onSave: (params: Partial<Job>) => void;
    jobEmails?: EmailAccount[];
    pendingRequests?: Map<string, RequestErrors>;
    updateClient?: (clientId: string, updates: Partial<Client>) => void;
    getConfirmation?: (onConfirm: () => void, description?: string, title?: string) => void;
    updateJobPrimaryEmail?: (jobId: string, primaryEmail: string) => void;
    showModalAlert?: (description: string | JSX.Element, title: string) => void;
    emailTemplates?: Map<string, EmailTemplateView[]>;
}

interface JobFormState {
    title: string;
    address: string;
    clientId: string;
    dailyEmailLimit: number;
    dailyPerUserEmailLimit: number;
    discipline: string;
    location: string;
    assignee: string;
    accountManagerId: string;
    status: JobStatus;
    phoneCallRequired: boolean;
    mixmaxTokenDialog: {
        email: string;
        open: boolean;
    };
    preferredContactType: PreferredContactType;
    hiringManagerEmails: string[];
    hiringManagerCc: string[];
    introEmailContact: string;
    introEmailCc: string[];
    clientFormOpen: boolean;
    jobType: JobType;
    headcount: number;
    visaStatusRequired: boolean;
    removeUnrespondedCandidates: boolean;
    errors: Map<string, string>;
    submissionSetting: JobSubmissionSetting;
    templates: JobTemplates;
    maxIdleDays: number;
    details: JobDetails;
    recruiterPermissions: JobRecruiterPermissionsType;
    mlAutoOutreachLimit: number;
    mlMaxResultsAutoOutreach: number;
    redirect: boolean;
    outreachAIAdditionalContext: string;
    scoringAIAdditionalContext: string;
    submissionGenAIAdditionalContext: string;
}

export class JobForm extends React.Component<JobFormProps, JobFormState> {
    constructor(props: JobFormProps) {
        super(props);
        this.state = this.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: JobFormProps) {
        if ((prevProps.isCreating && !this.props.isCreating) || prevProps.id !== this.props.id) {
            this.setState(this.getStateFromProps(this.props));
        }
    }

    convertToPreferredContactType = (contactTypes: ContactType[]) => {
        if (contactTypes) {
            if (_.isEqual(contactTypes, [ContactType.Personal, ContactType.Unknown, ContactType.Work])) {
                return PreferredContactType.PersonalPreferred;
            } else if (_.isEqual(contactTypes, [ContactType.Personal])) {
                return PreferredContactType.PersonalOnly;
            } else if (_.isEqual(contactTypes, [ContactType.Work, ContactType.Unknown, ContactType.Personal])) {
                return PreferredContactType.WorkPreferred;
            } else {
                return PreferredContactType.WorkOnly;
            }
        } else {
            return null;
        }
    };

    convertToContactTypes = (preferredContactType: PreferredContactType) => {
        switch (preferredContactType) {
            case PreferredContactType.PersonalOnly:
                return [ContactType.Personal];
            case PreferredContactType.PersonalPreferred:
                return [ContactType.Personal, ContactType.Unknown, ContactType.Work];
            case PreferredContactType.WorkOnly:
                return [ContactType.Work];
            case PreferredContactType.WorkPreferred:
                return [ContactType.Work, ContactType.Unknown, ContactType.Personal];
        }
    };

    // tslint:disable:no-magic-numbers
    getStateFromProps = (props: JobFormProps): JobFormState => {
        return {
            accountManagerId: props.accountManagerId,
            address: props.address || '',
            assignee: props.assignee || '',
            clientFormOpen: false,
            clientId: props.clientId || '',
            dailyEmailLimit: props.dailyEmailLimit ?? 300,
            dailyPerUserEmailLimit: props.dailyPerUserEmailLimit ?? 75,
            details: Object.assign({}, defaultJobDetails, props.details),
            discipline: props.discipline || 'N/A',
            errors: Map(),
            headcount: props.headcount || 1,
            hiringManagerCc: props.hiringManagerCc || [],
            hiringManagerEmails: props.hiringManagerEmails || [],
            introEmailCc: props.introEmailCc,
            introEmailContact: props.introEmailContact,
            jobType: props.jobType || DefaultJobType,
            location: props.location || '',
            maxIdleDays: props.maxIdleDays,
            mixmaxTokenDialog: { email: undefined as string, open: false },
            mlAutoOutreachLimit: props.mlAutoOutreachLimit ?? 3000,
            mlMaxResultsAutoOutreach: props.mlMaxResultsAutoOutreach ?? 200,
            outreachAIAdditionalContext: props.outreachAIAdditionalContext,
            phoneCallRequired: props.phoneCallRequired ?? true,
            preferredContactType:
                this.convertToPreferredContactType(props.contactTypes) || PreferredContactType.PersonalPreferred,
            recruiterPermissions: props.recruiterPermissions,
            redirect: false,
            removeUnrespondedCandidates: props.removeUnrespondedCandidates ?? true,
            scoringAIAdditionalContext: props.scoringAIAdditionalContext,
            status: props.status === undefined ? JobStatus.Paused : props.status,
            submissionGenAIAdditionalContext: props.submissionGenAIAdditionalContext,
            submissionSetting: props.submissionSetting,
            templates: props.templates || {
                introduction: null,
                phonescreen: null,
                preIntroduction: null,
                submission: null
            },
            title: props.title,
            visaStatusRequired: props.visaStatusRequired ?? true
        };
    };
    // tslint:enable:no-magic-numbers

    getBaseCompRange = (baseCompMin: number, baseCompMax: number) => {
        // tslint:disable: no-magic-numbers
        if (baseCompMin !== undefined && baseCompMax !== undefined) {
            return `${baseCompMin / 1000}K - ${baseCompMax / 1000}K`;
        } else if (baseCompMax !== undefined) {
            return ` ${baseCompMax / 1000}K`;
        } else {
            return '';
        }
    };

    handleTextFieldChange = (field: keyof Job) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [field]: event.target.value } as any);
    };

    handleSelectFieldChange = (field: keyof JobFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({ [field]: event.target.value } as any);
    };

    handleJobTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({ jobType: event.target.value as JobType });
    };

    handleTextfieldChange = <K extends keyof JobDetailsType>(field: K) => (event: {
        target: { value: JobDetailsType[K] };
    }) => {
        this.handleDetailsFieldChange(field, event.target.value);
    };

    handleDetailsFieldChange = <K extends keyof JobDetailsType>(key: K, value: JobDetailsType[K]) => {
        const updated = Object.assign({}, this.state.details, { [key]: value });
        this.handleJobDetailsChange('details', updated);
    };

    handleJobDetailsChange = <K extends keyof Job>(field: K, value: Job[K]) => {
        this.handleFieldChange(field)(value);
    };

    handleRecruiterPermissionsChange = (permissions: JobRecruiterPermissionsType) => {
        this.handleFieldChange('recruiterPermissions')(permissions);
    };

    handleFieldChange = (field: keyof Job) => (value: any) => {
        this.setState({ [field]: value } as any);
    };

    handleRecruiterPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const recruiterPercentage = Math.round(
            _.clamp(Number(event.target.value), minRecruiterPercentage, totalPercentage)
        );
        const accountManagerPercentage = totalPercentage - recruiterPercentage;
        const updated = Object.assign({}, this.state.details, { recruiterPercentage, accountManagerPercentage });

        this.handleJobDetailsChange('details', updated);
    };

    handleAMPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const accountManagerPercentage = _.clamp(
            Number(event.target.value),
            0,
            totalPercentage - minRecruiterPercentage
        );
        const recruiterPercentage = totalPercentage - accountManagerPercentage;
        const updated = Object.assign({}, this.state.details, { recruiterPercentage, accountManagerPercentage });

        this.handleJobDetailsChange('details', updated);
    };

    handleSelectDetailsFieldChange = <K extends keyof JobDetailsType>(field: K) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        this.handleDetailsFieldChange(field, event.target.value as JobDetailsType[K]);
    };

    handlePhoneCallRequired = () => {
        this.setState({ phoneCallRequired: !this.state.phoneCallRequired });
    };

    handleVisaStatusRequired = () => {
        this.setState({ visaStatusRequired: !this.state.visaStatusRequired });
    };

    handleRemoveUnrespondedCandidatesChange = () => {
        this.setState({ removeUnrespondedCandidates: !this.state.removeUnrespondedCandidates });
    };

    handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const status = Number(event.target.value);
        if (status !== JobStatus.Active) {
            const confirmationText =
                status === JobStatus.Archived
                    ? 'Archiving a job is a permanent change, and cannot be reversed. All current sequences will be canceled'
                    : 'Pausing a job will cancel all current sequences';
            this.props.getConfirmation(() => this.setState({ status }), confirmationText, 'Confirm Job Status Change');
        } else {
            this.setState({ status });
        }
    };

    handleDailyEmailLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.newJob || Number(event.target.value) <= maxDailyEmailLimit) {
            this.setState({ dailyEmailLimit: Number(event.target.value) });
        }
    };

    handleDailyPerUserEmailLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ dailyPerUserEmailLimit: Number(event.target.value) });
    };

    handleNumericFieldChange = (field: keyof JobFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [field]: Number(event.target.value) } as any);
    };

    handleMaxIdleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const num = Number(event.target.value) ?? 0;
        const minDays = 1;
        const maxDays = 7;
        const maxIdleDays = Math.min(Math.max(minDays, num), maxDays);
        this.setState({ maxIdleDays });
    };

    checkErrors = () => {
        let errors = Map<string, string>();
        const {
            accountManagerId,
            headcount,
            title,
            assignee,
            clientId,
            dailyEmailLimit,
            dailyPerUserEmailLimit,
            submissionSetting,
            maxIdleDays,
            details
        } = this.state;
        if (!accountManagerId) {
            errors = errors.set('accountManagerId', 'Account Manager must be set');
        }
        if (!title || title.trim() === '') {
            errors = errors.set('title', 'Title cannot be empty');
        }

        if (
            details.experience?.minYears !== undefined &&
            details.experience?.maxYears !== undefined &&
            details.experience?.minYears > details.experience?.maxYears
        ) {
            errors = errors.set('experience', 'Min Years should be less than Max Years of experience');
        }

        if (assignee.trim() === '') {
            errors = errors.set('assignee', 'There must be a recruiter assigned to a job');
        }
        if (!clientId || clientId.trim() === '') {
            errors = errors.set('clientId', 'There must be a client set for a job');
        }
        if (!headcount || headcount === 0) {
            errors = errors.set('headcount', 'Headcount must be at least 1');
        }

        if (
            (!dailyEmailLimit && dailyEmailLimit !== 0) ||
            Number(dailyEmailLimit) < 0 ||
            (this.props.newJob && Number(dailyEmailLimit) > maxDailyEmailLimit)
        ) {
            errors = errors.set('dailyEmailLimit', `Daily email limit must be 0 - ${maxDailyEmailLimit}`);
        }

        if (dailyEmailLimit < dailyPerUserEmailLimit) {
            errors = errors.set('dailyPerUserEmailLimit', 'Per user daily limit must be less than overall daily limit');
        }

        if (!this.props.newJob && (!maxIdleDays || maxIdleDays <= 0)) {
            errors = errors.set('maxIdleDays', 'Max idle days cannot be empty and must be greater than 0');
        }

        if (submissionSetting) {
            const { type, atsLink } = submissionSetting;

            if (type.length === 0) {
                errors = errors.set('submission-setting-type', 'A submission method must be chosen');
            }

            if (type.includes(JobSubmissionType.ATS)) {
                if (!atsLink) {
                    errors = errors.set('submission-setting-atsLink', 'A link to the ATS is required');
                }
            }
        }
        this.setState({ errors });
        return errors;
    };

    getJobDataFromState = (state: JobFormState) => {
        const {
            accountManagerId,
            address,
            assignee,
            title,
            discipline,
            hiringManagerEmails,
            hiringManagerCc,
            introEmailCc,
            introEmailContact,
            location,
            clientId,
            jobType,
            headcount,
            dailyEmailLimit,
            dailyPerUserEmailLimit,
            details,
            status,
            phoneCallRequired,
            maxIdleDays,
            mlAutoOutreachLimit,
            mlMaxResultsAutoOutreach,
            outreachAIAdditionalContext,
            preferredContactType,
            recruiterPermissions,
            removeUnrespondedCandidates,
            visaStatusRequired,
            scoringAIAdditionalContext,
            submissionGenAIAdditionalContext,
            submissionSetting,
            templates
        } = state;
        const job: Partial<Job> = {
            accountManagerId,
            address,
            assignee: assignee || accountManagerId,
            clientId,
            contactTypes: this.convertToContactTypes(preferredContactType),
            dailyEmailLimit,
            dailyPerUserEmailLimit,
            details,
            discipline: discipline === 'N/A' ? null : discipline,
            headcount,
            hiringManagerCc,
            hiringManagerEmails,
            introEmailCc,
            introEmailContact,
            jobType,
            location,
            maxIdleDays,
            mlAutoOutreachLimit,
            mlMaxResultsAutoOutreach,
            outreachAIAdditionalContext,
            phoneCallRequired,
            recruiterPermissions,
            removeUnrespondedCandidates,
            scoringAIAdditionalContext,
            status,
            submissionGenAIAdditionalContext,
            submissionSetting,
            templates,
            title,
            visaStatusRequired
        };
        return job;
    };

    hasDataChanged = () => {
        const jobDataFromState = this.getJobDataFromState(this.state);
        const jobDataFromProps = this.getJobDataFromState(this.getStateFromProps(this.props));
        return !_.isEqual(jobDataFromState, jobDataFromProps);
    };

    handleCancel = () => {
        if (!this.hasDataChanged()) {
            this.setState({ redirect: true });
        } else {
            this.props.getConfirmation(
                () => {
                    this.setState({ redirect: true });
                },
                'Are you sure you want to discard all changes?',
                'Discard Changes'
            );
        }
    };

    handleSubmit = () => {
        const job = this.getJobDataFromState(this.state);
        const errors = this.checkErrors();
        if (errors.size === 0) {
            this.props.onSave(job);
        } else {
            const title = 'Please fix these errors';
            const description = errors.valueSeq().map((error, i) => <li key={i}>{error}</li>);
            this.props.showModalAlert(<ul>{description}</ul>, title);
        }
    };

    handleShowMixmaxTokenDialog = (email: string) => () => {
        this.setState({ mixmaxTokenDialog: { email, open: true } });
    };

    handleCloseMixmaxTokenDialog = () => {
        this.setState({ mixmaxTokenDialog: { email: undefined as string, open: false } });
    };

    handleShowClientForm = () => {
        this.setState({ clientFormOpen: true });
    };

    handleCloseClientForm = () => {
        this.setState({ clientFormOpen: false });
    };

    userFilter = (user: UserData) => {
        return user.status === 'active';
    };

    updatePrimaryEmail = (email: string) => () => {
        this.props.updateJobPrimaryEmail(this.props.id, email);
    };

    handleSubmissionSettingChange = (submissionSetting: JobSubmissionSetting) => {
        this.setState({ submissionSetting });
    };

    handleJobTemplateChange = (templates: JobTemplates) => {
        this.setState({ templates });
    };

    render() {
        const {
            accountManagerId,
            address,
            discipline,
            title,
            location,
            clientId,
            clientFormOpen,
            dailyEmailLimit,
            dailyPerUserEmailLimit,
            details,
            jobType,
            headcount,
            status,
            assignee,
            phoneCallRequired,
            hiringManagerEmails,
            hiringManagerCc,
            introEmailCc,
            introEmailContact,
            preferredContactType,
            errors,
            templates,
            visaStatusRequired,
            maxIdleDays,
            scoringAIAdditionalContext,
            submissionGenAIAdditionalContext,
            submissionSetting,
            recruiterPermissions,
            mlAutoOutreachLimit,
            mlMaxResultsAutoOutreach,
            outreachAIAdditionalContext,
            redirect
        } = this.state;
        const { clients, isCreating, isEditable, formTitle, newJob, id, user, userPermissions } = this.props;

        if (redirect) {
            if (id) {
                return <Redirect to={`/job/${id}/board`} />;
            } else {
                return <Redirect to="/" />;
            }
        }

        const canEditOrAM = isEditable || user.id === accountManagerId;

        const disciplines = ['N/A'].concat(jobDisciplines).map((disciplineName) => {
            return (
                <MenuItem key={disciplineName} value={disciplineName}>
                    {_.startCase(disciplineName)}
                </MenuItem>
            );
        });

        const preferredContactTypes = [
            PreferredContactType.PersonalOnly,
            PreferredContactType.PersonalPreferred,
            PreferredContactType.WorkOnly,
            PreferredContactType.WorkPreferred
        ].map((type) => {
            return (
                <MenuItem key={type} value={type}>
                    {type}
                </MenuItem>
            );
        });

        const jobClientInfoSection = newJob ? null : (
            <JobClientInfoCard
                address={address}
                clientId={clientId}
                errors={errors}
                isEditable={isEditable}
                updateClient={this.props.updateClient}
                hiringManagerEmails={hiringManagerEmails}
                hiringManagerEmailsCC={hiringManagerCc}
                introEmailContact={introEmailContact}
                introEmailCc={introEmailCc}
                submissionSetting={submissionSetting}
                onJobFieldChange={this.handleFieldChange}
            />
        );

        const emailSequencesSection = newJob ? null : (
            <JobSequencesForm role={clients.list.get(clientId)?.name} jobId={id} isEditable={canEditOrAM} />
        );

        const clientInfo = this.props.clientId ? (
            <ClientFormDialog open={clientFormOpen} onRequestClose={this.handleCloseClientForm} clientId={clientId} />
        ) : null;

        const clientSelector = (
            <div
                className="client-selector-wrapper"
                onClick={clientInfo ? this.handleShowClientForm : null}
                style={{ cursor: 'pointer !important' }}
            >
                <ClientSelector
                    onSelect={this.handleFieldChange('clientId')}
                    disabled={isCreating || !!id || !isEditable}
                    selected={clientId}
                    errorText={errors.get('clientId', null)}
                />
                {clientInfo}
            </div>
        );

        const jobTypes = _.sortBy(_.entries(JobTypes), (j) => j[1].label).map(([jobTypeCode, jobTypeData]) => {
            return (
                <MenuItem key={jobTypeCode} value={jobTypeCode}>
                    {jobTypeData.label}
                </MenuItem>
            );
        });

        const statusMenuItems = [JobStatus.Active, JobStatus.Paused, JobStatus.Archived].map((s) => {
            return (
                <MenuItem key={s} selected={s === status} value={s} disabled={newJob}>
                    <div className="job-status-opt">{JobStatusLabels.get(s)}</div>
                </MenuItem>
            );
        });

        const jobDetails = newJob ? null : (
            <div className="job-settings-card">
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ lineHeight: '24px' }}>
                        <div>
                            <Typography variant="h5" component="div">
                                Job Description
                            </Typography>
                            <Typography variant="h6" color="textSecondary" component="div">
                                View/Edit Job Description
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <JobDetailsForm
                            jobId={id}
                            onePager={this.props.onePager}
                            showOnePager={true}
                            location={location}
                            discipline={discipline === 'N/A' ? null : discipline}
                            details={details}
                            isEditable={canEditOrAM}
                            onChange={this.handleJobDetailsChange}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );

        const companyInfo = newJob ? null : (
            <div className="job-settings-card">
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ lineHeight: '24px' }}>
                        <div>
                            <Typography variant="h5" component="div">
                                Company Info
                            </Typography>
                            <Typography variant="h6" color="textSecondary" component="div">
                                View/Edit Company Info
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <CompanyInfoForm
                            jobId={id}
                            details={details}
                            isEditable={canEditOrAM}
                            onChange={this.handleJobDetailsChange}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );

        const aiContextSection = newJob ? null : (
            <div className="job-settings-card">
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ lineHeight: '24px' }}>
                        <div>
                            <Typography variant="h5" component="div">
                                AI Context Settings
                            </Typography>
                            <Typography variant="h6" color="textSecondary" component="div">
                                View/Edit Context for AI to use
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <JobAIContextForm
                            outreachAIAdditionalContext={outreachAIAdditionalContext}
                            scoringAIAdditionalContext={scoringAIAdditionalContext}
                            submissionGenAIAdditionalContext={submissionGenAIAdditionalContext}
                            isEditable={canEditOrAM}
                            onChange={this.handleJobDetailsChange}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );

        const jobTemplateSettings = newJob ? null : (
            <JobTemplateSettingCard
                isEditable={isEditable}
                onChange={this.handleJobTemplateChange}
                jobTemplates={templates}
                showModalAlert={this.props.showModalAlert}
                emailTemplates={this.props.emailTemplates}
                jobType={this.props.jobType}
            />
        );

        const maxIdleDaysDisabled =
            user.id !== this.props.assignee &&
            user.id !== this.props.accountManagerId &&
            !hasRole(userPermissions, 'site_owner') &&
            !hasRole(userPermissions, 'settings_editor');

        const isSettingsEditorUser =
            hasRole(userPermissions, 'settings_editor') || hasRole(userPermissions, 'site_owner');

        const isOutreachSettingsEditorUser =
            isSettingsEditorUser || hasRole(userPermissions, 'job_outreach_limits_editor');

        const maxIdleDaysField = newJob ? null : (
            <TextField
                label="Max Client Round Candidate Idle Days"
                value={maxIdleDays}
                onChange={this.handleMaxIdleDaysChange}
                fullWidth={true}
                disabled={maxIdleDaysDisabled}
                error={errors.get('maxIdleDays', null)}
                helperText={errors.get('maxIdleDays', null)}
                type="number"
            />
        );

        const togglePhoneVisaArchive = newJob ? null : (
            <>
                <div>
                    <label className="form-settings-label">Phone call required</label>
                    <Switch
                        className="job-settings-switch"
                        color="primary"
                        checked={phoneCallRequired}
                        onChange={this.handlePhoneCallRequired}
                        disabled={isCreating || !isEditable}
                    />
                </div>
                <div>
                    <label className="form-settings-label">Visa status for candidates required</label>
                    <Switch
                        className="job-settings-switch"
                        color="primary"
                        checked={visaStatusRequired}
                        onChange={this.handleVisaStatusRequired}
                        disabled={!isEditable}
                    />
                </div>
            </>
        );
        const recruiterPermissionsSection = newJob ? null : (
            <JobRecruiterPermissions
                accountManagerId={accountManagerId}
                assignee={assignee}
                disabled={isCreating || !isEditable}
                permissions={recruiterPermissions}
                onChange={this.handleRecruiterPermissionsChange}
            />
        );

        const outreachSettingsSection = newJob ? null : (
            <div className="job-settings-card">
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ lineHeight: '24px' }}>
                        <div>
                            <Typography variant="h5" component="div">
                                Outreach Settings
                            </Typography>
                            <Typography variant="h6" color="textSecondary" component="div">
                                View/Edit Outreach Settings
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TextField
                            label="Outreach Email Type"
                            select={true}
                            value={preferredContactType}
                            onChange={this.handleSelectFieldChange('preferredContactType')}
                            fullWidth={true}
                            disabled={!isEditable}
                        >
                            {preferredContactTypes}
                        </TextField>
                        <div style={{ display: 'flex' }}>
                            <TextField
                                label="Max Daily Outreach"
                                value={dailyEmailLimit}
                                onChange={this.handleDailyEmailLimitChange}
                                fullWidth={true}
                                disabled={isCreating || !isEditable}
                                error={errors.get('dailyEmailLimit', null)}
                                helperText={errors.get('dailyEmailLimit', null)}
                                type="number"
                                style={{ marginRight: '10px' }}
                            />
                            <TextField
                                label="Max Daily Outreach Per User"
                                value={dailyPerUserEmailLimit}
                                onChange={this.handleDailyPerUserEmailLimitChange}
                                fullWidth={true}
                                disabled={isCreating || !isEditable}
                                type="number"
                                style={{ marginLeft: '10px' }}
                            />
                        </div>
                        <div>
                            <TextField
                                label="Max results in a search to allow outreach without review"
                                value={mlMaxResultsAutoOutreach}
                                onChange={this.handleNumericFieldChange('mlMaxResultsAutoOutreach')}
                                select={true}
                                fullWidth={true}
                                disabled={!isEditable}
                            >
                                <MenuItem value={200}>200</MenuItem>
                                <MenuItem value={300}>300</MenuItem>
                                <MenuItem value={400}>400</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                            </TextField>
                        </div>
                        <div>
                            <TextField
                                label="Max total outreach till outreach without review from searches is disabled"
                                value={mlAutoOutreachLimit}
                                onChange={this.handleNumericFieldChange('mlAutoOutreachLimit')}
                                fullWidth={true}
                                disabled={isCreating || !isEditable || !isOutreachSettingsEditorUser}
                                error={errors.get('dailyEmailLimit', null)}
                                helperText={errors.get('dailyEmailLimit', null)}
                                type="number"
                            />
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );

        const billingInfo = newJob ? null : (
            <JobBillingInfo
                jobId={id}
                disabled={!hasRole(userPermissions, 'billing_admin') || !jobPrepayRequired(jobType)}
            />
        );

        const jobFunnelTargets = newJob ? null : (
            <div className="job-settings-card">
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <div>
                            <Typography variant="h5" component="div">
                                Allocations & Funnel Targets
                            </Typography>
                            <Typography variant="h6" color="textSecondary" component="div">
                                View/Edit Funnel Targets & Allocations for a 14 day period
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <JobFunnelTargetsForm jobId={id} disabled={!isEditable} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );

        const cloneJobButton = id ? (
            <JobCloneButton sourceJobId={id} data={this.getJobDataFromState(this.state)} disabled={isCreating} />
        ) : null;

        const formSaveButtons =
            !isEditable && maxIdleDaysDisabled ? null : (
                <div className="form-buttons">
                    <div>{cloneJobButton}</div>
                    <div>
                        <Button variant="contained" onClick={this.handleCancel} disabled={isCreating}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            disabled={isCreating || !this.hasDataChanged()}
                        >
                            {isCreating ? 'Saving' : 'Save'}
                        </Button>
                    </div>
                </div>
            );

        return (
            <div className="jobs-container flex-fill" css={styles}>
                <div className="job-form">
                    <div className="job-settings-card main-card">
                        <Paper className="main-card-paper" square={true} elevation={0}>
                            <div className="job-form-title">
                                <h1 className="secondary">{formTitle}</h1>
                                <div className="vcenter">
                                    <TextField
                                        value={status}
                                        select={true}
                                        onChange={this.handleStatusChange}
                                        fullWidth={true}
                                        InputProps={{ disableUnderline: true }}
                                        disabled={!isEditable || status === JobStatus.Archived}
                                    >
                                        {statusMenuItems}
                                    </TextField>
                                </div>
                            </div>

                            {clientSelector}
                            <TextField
                                label="Title"
                                value={title}
                                onChange={this.handleTextFieldChange('title')}
                                disabled={isCreating || !isEditable}
                                fullWidth={true}
                                error={errors.get('title', null)}
                                helperText={errors.get('title', null)}
                            />
                            <br />
                            <TextField
                                label="Discipline"
                                select={true}
                                value={discipline}
                                onChange={this.handleSelectFieldChange('discipline')}
                                fullWidth={true}
                                disabled={!isEditable}
                            >
                                {disciplines}
                            </TextField>
                            <TextField
                                label="Job Type"
                                select={true}
                                value={jobType}
                                onChange={this.handleJobTypeChange}
                                fullWidth={true}
                                disabled={!isEditable}
                            >
                                {jobTypes}
                            </TextField>
                            <TextField
                                label="Headcount"
                                value={headcount}
                                onChange={this.handleTextFieldChange('headcount')}
                                fullWidth={true}
                                type="number"
                                disabled={!isEditable}
                            />
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <TextField
                                    label="Placement Fees"
                                    value={details.placementFees}
                                    onChange={this.handleTextfieldChange('placementFees')}
                                    fullWidth={true}
                                    disabled={!isEditable}
                                />
                                <TextField
                                    label="Recruiter Percentage"
                                    value={details.recruiterPercentage}
                                    onChange={this.handleRecruiterPercentageChange}
                                    fullWidth={true}
                                    type="number"
                                    disabled={!isEditable}
                                    style={{ margin: '0 10px' }}
                                />
                                <TextField
                                    label="AM Percentage"
                                    value={details.accountManagerPercentage}
                                    onChange={this.handleAMPercentageChange}
                                    fullWidth={true}
                                    type="number"
                                    disabled={!isEditable}
                                />
                            </div>

                            <UserSelector
                                onSelect={this.handleFieldChange('accountManagerId')}
                                disabled={isCreating || !isEditable}
                                value={accountManagerId}
                                fullWidth={true}
                                label="Account Manager"
                                userFilter={this.userFilter}
                                includeNoneOpt={true}
                            />

                            <UserSelector
                                onSelect={this.handleFieldChange('assignee')}
                                disabled={isCreating || !isEditable}
                                value={assignee}
                                fullWidth={true}
                                label="Assignee"
                                userFilter={this.userFilter}
                                includeNoneOpt={true}
                            />

                            {maxIdleDaysField}

                            {togglePhoneVisaArchive}
                        </Paper>
                    </div>
                    {jobFunnelTargets}
                    {outreachSettingsSection}
                    {recruiterPermissionsSection}
                    {billingInfo}
                    {jobDetails}
                    {companyInfo}
                    {aiContextSection}
                    {emailSequencesSection}
                    {jobClientInfoSection}
                    {jobTemplateSettings}
                    {formSaveButtons}
                </div>
            </div>
        );
    }
}
