import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    MenuItem,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';
import { JobRecruiterPermissions as JobRecruiterPermissionsType } from 'shared/models/job';
import { ACTIVE_USERS, User } from '../graphql/queries/user';
import { useModal } from '../hooks/use-modal';

const styles = (theme: Theme) => css`
    margin-bottom: 35px;
    width: 50%;

    .MuiExpansionPanel-root {
        box-shadow: none;
        border: thin solid ${theme.palette.divider};
        border-radius: 2px;
        padding: 8px 16px;
    }

    .MuiExpansionPanelDetails-root {
        display: block;

        .field-permissions {
            margin-bottom: 45px;

            .field-title {
                margin-bottom: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .copy-to-all {
                font-size: 14px;
                opacity: 0;
                transition: opacity 1s;
            }

            &:hover {
                .copy-to-all {
                    opacity: 1;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            .form-field {
                margin-top: 5px;
                margin-bottom: 10px;
            }
        }
    }
`;

export const JobRecruiterPermissions: React.FC<{
    accountManagerId: string;
    assignee: string;
    disabled: boolean;
    permissions: JobRecruiterPermissionsType;
    onChange: (permissions: JobRecruiterPermissionsType) => void;
}> = ({ accountManagerId, assignee, disabled, permissions, onChange }) => {
    const theme = useTheme();
    const { getConfirmation } = useModal();
    const { data: usersData } = useQuery<{ users: User[] }>(ACTIVE_USERS);

    const handleAllowAllChange = (field: keyof JobRecruiterPermissionsType) => (event: { target: { value: any } }) => {
        if (event.target.value === 'No') {
            onChange({
                ...permissions,
                [field]: {
                    ...permissions[field],
                    allowAll: event.target.value === 'Yes',
                    allowedUserIds: [accountManagerId, assignee]
                }
            });
        } else {
            onChange({ ...permissions, [field]: { ...permissions[field], allowAll: event.target.value === 'Yes' } });
        }
    };

    const handleUserIdsListChange = (field: keyof JobRecruiterPermissionsType) => (
        key: 'allowedUserIds' | 'disallowedUserIds'
    ) => (event: { target: { value: any } }) => {
        onChange({ ...permissions, [field]: { ...permissions[field], [key]: event.target.value } });
    };

    const handleCopyToAll = (field: keyof JobRecruiterPermissionsType) => () => {
        getConfirmation(
            () => {
                const permission = permissions[field];
                onChange({
                    createSearch: permission,
                    dqAtClientStage: permission,
                    replyToClientEmails: permission,
                    sendOutreach: permission
                });
            },
            <div>
                <b>All permissions</b> for the job will be set to the same as the permissions for{' '}
                <div style={{ margin: '15px 0 10px' }}>
                    <b>{permissionsDescription[field]}</b>
                </div>
            </div>,
            'Please confirm'
        );
    };

    const permissionFields: Array<keyof JobRecruiterPermissionsType> = [
        'sendOutreach',
        'createSearch',
        'replyToClientEmails',
        'dqAtClientStage'
    ];
    const permissionsDescription: { [Prop in keyof JobRecruiterPermissionsType]: string } = {
        createSearch: 'Create Searches',
        dqAtClientStage: 'Disqualifying in Client Stages',
        replyToClientEmails: 'Reply to Client Emails',
        sendOutreach: 'Sending Outreach'
    };

    const content = permissionFields.map((field) => {
        const allowUserOptions =
            usersData?.users
                .filter((u) => permissions[field].disallowedUserIds.indexOf(u.id) === -1)
                .map((u) => (
                    <MenuItem value={u.id} key={u.id}>
                        {u.name.full}
                    </MenuItem>
                )) ?? [];

        const disallowUserOptions =
            usersData?.users
                .filter((u) => permissions[field].allowedUserIds.indexOf(u.id) === -1)
                .map((u) => (
                    <MenuItem value={u.id} key={u.id}>
                        {u.name.full}
                    </MenuItem>
                )) ?? [];

        const allowSpecificUsersField = permissions[field].allowAll ? null : (
            <TextField
                value={permissions[field].allowedUserIds ?? []}
                label="Allow Specific Recruiters"
                select={true}
                fullWidth={true}
                SelectProps={{ multiple: true }}
                onChange={handleUserIdsListChange(field)('allowedUserIds')}
                size="small"
                className="form-field"
                disabled={disabled}
            >
                {allowUserOptions}
            </TextField>
        );

        const disallowSpecificUsersField = !permissions[field].allowAll ? null : (
            <TextField
                value={permissions[field].disallowedUserIds ?? []}
                label="Disallow Specific Recruiters"
                select={true}
                fullWidth={true}
                SelectProps={{ multiple: true }}
                onChange={handleUserIdsListChange(field)('disallowedUserIds')}
                size="small"
                className="form-field"
                disabled={disabled}
            >
                {disallowUserOptions}
            </TextField>
        );
        return (
            <div className="field-permissions" key={field}>
                <div className="field-title">
                    <Typography variant="h5" className={disabled ? 'secondary' : ''} component="div">
                        {permissionsDescription[field]}
                    </Typography>
                    <Button onClick={handleCopyToAll(field)} className="copy-to-all">
                        Copy to all
                    </Button>
                </div>
                <div className="field-form">
                    <TextField
                        value={permissions[field].allowAll ? 'Yes' : 'No'}
                        label="Allow everyone by default"
                        select={true}
                        fullWidth={true}
                        onChange={handleAllowAllChange(field)}
                        size="small"
                        className="form-field"
                        disabled={disabled}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                    {allowSpecificUsersField}
                    {disallowSpecificUsersField}
                </div>
            </div>
        );
    });

    return (
        <div css={styles(theme)}>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <div>
                        <Typography variant="h5" component="div">
                            Recruiter Permissions
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="div">
                            View/Edit Recruiter Permissions for this Job
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>{content}</ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};
