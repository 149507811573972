import { useQuery } from '@apollo/client';
import React from 'react';
import DocumentTitle from 'react-document-title';

import { Duration, getDurationOptions } from '../../common/duration';
import { funnelMetricFields } from '../../common/metrics';
import { isoDate } from '../../common/timestamp';
import { JOB_TITLE, JobTitleData } from '../../graphql/queries/job';
import { JOB_METRICS, JOB_OUTREACH_METRICS, MetricsData, OutreachMetrics } from '../../graphql/queries/metrics';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { JobPageHeader } from '../job-page-header';
import { FunnelMetrics } from './funnel-metrics';

export const JobMetrics: React.FC<{ jobId: string }> = ({ jobId }) => {
    const allUserSelectOpt = 'all';
    const { data: titleData } = useQuery<JobTitleData, { jobId: string }>(JOB_TITLE, { variables: { jobId } });

    const durationPeriods = { days: 0, weeks: 2, months: 2, quarters: 4, years: 2, allTime: true };
    const durationOpts = getDurationOptions(durationPeriods);
    const defaultOption = durationOpts.find((opt) => opt.label === 'This Week');
    const [duration, setDuration] = useLocalStorage<Duration>('job-metrics-duration', defaultOption);
    const [viewType, setViewType] = useLocalStorage<'timeline' | 'funnel' | 'table' | 'outreach'>(
        'job-metrics-view',
        'funnel'
    );
    const [selectedUser, setSelectedUser] = React.useState<string>(allUserSelectOpt);

    const { data } = useQuery<
        { metrics: MetricsData[] },
        { jobId: string; kinds: string[]; startDate: string; endDate: string }
    >(JOB_METRICS, {
        variables: {
            endDate: isoDate(duration.end),
            jobId,
            kinds: funnelMetricFields,
            startDate: isoDate(duration.start)
        }
    });

    const { data: outreachData } = useQuery<
        {
            outreachMetrics: OutreachMetrics[];
        },
        { jobId: string; startDate: string; endDate: string }
    >(JOB_OUTREACH_METRICS, {
        variables: { endDate: isoDate(duration.end), jobId, startDate: isoDate(duration.start) }
    });

    return (
        <DocumentTitle title={`Job Metrics - ${titleData?.job.client.name} - ${titleData?.job.title}`}>
            <div id="container">
                <JobPageHeader jobId={jobId} actions={[]} activeTab="Metrics" />
                <FunnelMetrics
                    metrics={data?.metrics}
                    outreachMetrics={outreachData?.outreachMetrics}
                    selectedUser={selectedUser}
                    onSelectUser={setSelectedUser}
                    viewType={viewType}
                    onSelectView={setViewType}
                    duration={duration}
                    onChangeDuration={setDuration}
                    showJobTitle={false}
                />
            </div>
        </DocumentTitle>
    );
};
