import { gql, useMutation, useQuery } from '@apollo/client';
import { Tooltip } from '@material-ui/core';
import { OrderedMap } from 'immutable';
import { Tab, Tabs } from 'material-ui';
import { white } from 'material-ui/styles/colors';
import { AvPauseCircleOutline, ContentArchive, ToggleStar, ToggleStarBorder } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { fetchJobInfo } from '../actions';
import { tabBackgroundColor } from '../common/css-variables';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { User } from '../sfc/user';
import { Job, JobStatus, State } from '../state';
import { ClientJobTitle } from './client-job-title';
import { Header } from './header';
import { JobInfoTooltip } from './job-info-tooltip';

type JobPageTab = 'Candidates' | 'Metrics' | 'Notes' | 'Searches' | 'Settings';

interface OwnProps {
    jobId: string;
    activeTab: string;
    actions?: JSX.Element[];
}

interface ConnectedProps {
    jobs: OrderedMap<string, Job>;
}

interface ConnectedDispatch {
    fetchJobInfo: (id: string) => void;
}

type JobPageHeaderProps = OwnProps & ConnectedProps & ConnectedDispatch;

const JOB_BOOKMARK_QUERY = gql`
    query JobBookMark($userId: String!, $jobId: String!) {
        job: user_bookmarked_jobs_by_pk(jobId: $jobId, userId: $userId) {
            id: jobId
        }
    }
`;

const CREATE_BOOKMARK = gql`
    mutation CreateJobBookmark($userId: String!, $jobId: String!) {
        insert_user_bookmarked_jobs(objects: { jobId: $jobId, userId: $userId }) {
            affected_rows
        }
    }
`;

const REMOVE_BOOKMARK = gql`
    mutation RemoveJobBookmark($userId: String!, $jobId: String!) {
        delete_user_bookmarked_jobs(where: { jobId: { _eq: $jobId }, userId: { _eq: $userId } }) {
            affected_rows
        }
    }
`;

const JobPageHeaderComponent: React.FC<JobPageHeaderProps> = (props) => {
    const { actions, jobId, activeTab, jobs } = props;
    const {
        user: { id: userId }
    } = useSession();

    const { setSnackbar } = useSnackbar();
    const bookMarkQuery = useQuery<{ job: { id: string } }, { jobId: string; userId: string }>(JOB_BOOKMARK_QUERY, {
        variables: { jobId, userId }
    });
    const [removeBookMark, { loading: removingBookMark }] = useMutation<{}, { jobId: string; userId: string }>(
        REMOVE_BOOKMARK,
        {
            variables: { jobId, userId }
        }
    );
    const [createBookMark, { loading: creatingBookMark }] = useMutation<{}, { jobId: string; userId: string }>(
        CREATE_BOOKMARK,
        {
            variables: { jobId, userId }
        }
    );
    const [redirectTo, setRedirectTo] = React.useState<JobPageTab>(null);

    React.useEffect(() => {
        if (redirectTo && activeTab === redirectTo) {
            setRedirectTo(null);
        }
    }, [redirectTo, activeTab]);

    React.useEffect(() => {
        if (!jobs || !jobs.get(jobId)) {
            props.fetchJobInfo(jobId);
        }
    }, [jobId]);

    const getTabPath = (tabName: JobPageTab) => {
        const pathSuffix = {
            Candidates: 'board',
            Metrics: 'metrics',
            Notes: 'notes',
            Searches: 'searches',
            Settings: 'edit'
        };
        return `/job/${jobId}/${pathSuffix[tabName]}`;
    };

    const handleTabActive = (tabName: JobPageTab) => () => {
        if (activeTab !== tabName) {
            setRedirectTo(tabName);
        }
    };

    const handleBookmarkClick = async () => {
        if (!creatingBookMark && !removingBookMark && !bookMarkQuery.loading) {
            setSnackbar('Updating job bookmark');
            if (bookMarkQuery.data?.job?.id) {
                await removeBookMark();
            } else {
                await createBookMark();
            }
            bookMarkQuery.refetch();
            setSnackbar('Job bookmark updated');
        }
    };

    const job = jobs.get(jobId);
    if (redirectTo && redirectTo !== activeTab) {
        return <Redirect to={getTabPath(redirectTo)} push={true} />;
    }

    const tabNames: JobPageTab[] = ['Candidates', 'Metrics', 'Notes', 'Searches', 'Settings'];

    const tabs = tabNames.map((tab) => (
        <Tab
            label={tab}
            style={{ height: '32px' }}
            buttonStyle={{ height: '32px', fontSize: '12px' }}
            onActive={handleTabActive(tab)}
            value={tab}
            key={tab}
        />
    ));

    let title: JSX.Element = null;
    if (job) {
        const pausedIcon = <AvPauseCircleOutline color={white} />;
        const archiveIcon = <ContentArchive color={white} />;
        const statusIcon =
            job.status === JobStatus.Archived ? archiveIcon : job.status === JobStatus.Paused ? pausedIcon : null;
        const iconComp = <div className="job-title-icon">{statusIcon}</div>;

        const bookMarkIcon = bookMarkQuery.loading ? null : creatingBookMark || removingBookMark ? (
            <div className="job-title-bookmark disabled">
                <ToggleStar color={white} />
            </div>
        ) : bookMarkQuery.data?.job?.id ? (
            <Tooltip title="Job Bookmarked">
                <div className="job-title-bookmark active" onClick={handleBookmarkClick}>
                    <ToggleStar color={white} />
                </div>
            </Tooltip>
        ) : (
            <Tooltip title="Add Job to bookmarks">
                <div className="job-title-bookmark" onClick={handleBookmarkClick}>
                    <ToggleStarBorder color={white} />
                </div>
            </Tooltip>
        );

        title = (
            <div className="vcenter">
                <JobInfoTooltip job={job}>
                    <span>
                        <ClientJobTitle clientId={job.clientId} jobId={job.id} />
                    </span>
                </JobInfoTooltip>
                {iconComp}
                {bookMarkIcon}
            </div>
        );
    }

    const assignee = job?.assignee ? <User id={job.assignee} /> : null;

    return (
        <Header title={title} actions={actions}>
            <div className="job-page-header-secondary">
                <div className="job-page-header-assignee-label">{assignee}</div>
                <div className="job-page-header-tabs">
                    <Tabs
                        tabItemContainerStyle={{ backgroundColor: 'transparent' }}
                        value={activeTab}
                        inkBarStyle={{ backgroundColor: tabBackgroundColor }}
                    >
                        {tabs}
                    </Tabs>
                </div>
            </div>
        </Header>
    );
};

const mapStateToProps = (state: State) => ({
    jobs: state.jobs
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchJobInfo
};

export const JobPageHeader = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(JobPageHeaderComponent);
