import { css } from '@emotion/core';
import { Button, CircularProgress } from '@material-ui/core';
import { Map } from 'immutable';
import * as React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { connect } from 'react-redux';

import { HrefFilePayload } from 'shared/types/file-payload';

import { addJobOnePager } from '../actions';
import { config } from '../config';
import { Pdf } from '../core-ui/pdf';
import { readFile } from '../lib/read-file-payload';
import { RequestErrors, State } from '../state';

const styles = css`
    .card-action-button {
        border-radius: 2px;
    }
`;

const spinnerSize = 20;
const onePagerHeightRatio = 1.76;
const onePagerHeightPadding = 64;

interface OwnProps {
    onePager: {
        key: string;
        size: number;
    };
    jobId: string;
}

interface ConnectedProps {
    pendingRequests: Map<string, RequestErrors>;
}

interface ConnectedDispatch {
    addJobOnePager: (id: string, payload: HrefFilePayload) => void;
}

interface JobOnePagerState {
    onePagerWidth: number;
}

type JobOnePagerProps = OwnProps & ConnectedProps & ConnectedDispatch;

class JobOnePagerComponent extends React.Component<JobOnePagerProps, JobOnePagerState> {
    constructor(props: JobOnePagerProps) {
        super(props);
        const windowWidth = window.innerWidth;
        const width = windowWidth / 2; // tslint:disable-line:no-magic-numbers
        const defaultPadding = 64;
        this.state = { onePagerWidth: width - defaultPadding };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    componentWillMount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    updateDimensions() {
        const windowWidth = window.innerWidth;
        const width = windowWidth / 2; // tslint:disable-line:no-magic-numbers
        const defaultPadding = 64;
        this.setState({ onePagerWidth: width - defaultPadding });
    }

    isSaving = () => {
        const { pendingRequests, jobId } = this.props;
        return (
            pendingRequests.has(`job-onepager-upload-${jobId}`) &&
            pendingRequests.get(`job-onepager-upload-${jobId}`).isEmpty()
        );
    };

    handleDrop = (files: File[]) => {
        if (!this.isSaving()) {
            readFile(files[0]).then((result) => {
                this.props.addJobOnePager(this.props.jobId, result);
            });
        }
    };

    render() {
        const { onePager } = this.props;
        const { onePagerWidth } = this.state;
        const onePagerHeight = onePagerWidth / onePagerHeightRatio + onePagerHeightPadding;
        const saving = this.isSaving();

        const onePagerLink = onePager
            ? `${config.AssetHost}/files/` + onePager.key.split('/').map(encodeURIComponent).join('/')
            : null;
        const fileDisplay =
            onePager && !saving ? (
                <div style={{ width: onePagerWidth, height: onePagerHeight }}>
                    <Pdf url={onePagerLink} className="job-one-pager-pdf" />
                </div>
            ) : saving ? null : (
                <div>Please upload a onepager for this job.</div>
            );

        const getDropzoneSection = (
            getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
            getInputProps: <T extends DropzoneInputProps>(props?: T) => T
        ) => {
            return (
                <section className="card-action-button">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <span>Upload</span>
                    </div>
                </section>
            );
        };

        const uploadButton = saving ? (
            <>
                <CircularProgress size={spinnerSize} />
            </>
        ) : (
            <Dropzone onDrop={this.handleDrop}>
                {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
            </Dropzone>
        );
        return (
            <div css={styles}>
                {fileDisplay}
                <div className="one-pager-footer">
                    <a href={onePagerLink} target="_blank" download={true}>
                        <Button color="secondary">Download</Button>
                    </a>
                    {uploadButton}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    pendingRequests: state.pendingRequests
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    addJobOnePager
};

export const JobOnePager = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(JobOnePagerComponent);
