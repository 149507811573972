import { Map, OrderedMap } from 'immutable';
import * as _ from 'lodash';
import { Dialog, FlatButton, Menu, MenuItem, Popover, PopoverProps } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { ContactChannel } from 'shared/models/contact';
import { EmailTemplateView } from 'shared/models/email-templates';
import { IntroEmailsAccount } from 'shared/models/job';
import { clientFirstRoundStage } from 'shared/models/job-stages';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { EmailAddress } from 'shared/types/email-compose';
import { FilePayload } from 'shared/types/file-payload';

import { Editor } from 'react-ce';
import { EmailAccountView } from 'shared/models/email-account';
import { getAllEmailAccountInfo, getEmailTemplates, moveCandidateToStage, showModalAlert } from '../actions';
import { getAccountOptions } from '../common/email/account-options';
import { EmailKind } from '../common/email/header-validator';
import { getEmailForOutreach } from '../common/person';
import {
    composeEmailWithValidation,
    EmailValidationData,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount,
    toggleComposeWindow
} from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { getLastCommunicationWithCandidate } from '../lib/last-communication-with-candidate';
import { Candidate, Client, Communication, EmailAccount, Job, List, ListState, PersonDetails, State } from '../state';

interface OwnProps extends PopoverProps {
    jobId: string;
    personId: string;
}

interface ConnectedProps {
    emailContentBlacklistedDomains: string;
    candidates: Map<string, OrderedMap<string, Candidate>>;
    communications: Map<string, Communication[]>;
    clients: List<Client>;
    emailAccounts: Map<string, EmailAccount>;
    emailTemplates: EmailTemplateView[];
    jobs: OrderedMap<string, Job>;
    personsDetails: List<PersonDetails>;
    listsState: Map<string, ListState>;
    user: UserData;
    users: Map<string, UserData>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    getEmailTemplates: (group: string) => void;
    getAllEmailAccountInfo: () => void;
    composeEmailWithValidation: (
        data: ComposeEmailWindowData,
        emailKind: EmailKind,
        validationData: EmailValidationData
    ) => void;
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
    replyToEmailWithValidation: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        archiveOnReply?: boolean,
        composeWindowData?: Partial<ComposeEmailWindowData>,
        initialBody?: string
    ) => void;
    replyToEmailWithValidationWithDifferentAccount: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        newAccount: EmailAddress,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        composeWindowData?: Partial<ComposeEmailWindowData>,
        initialBody?: string
    ) => void;
    showModalAlert: (description: string | JSX.Element, title: string) => void;
}

type IntroToClientPopoverComponentProps = OwnProps & ConnectedProps & ConnectedDispatch;

interface IntroToClientPopoverState {
    introDescriptionDialogOpen: boolean;
}

const templateGroup = 'introduction';
const introTemplate = 'Intro Client - Candidate';

class IntroToClientPopoverComponent extends React.Component<
    IntroToClientPopoverComponentProps,
    IntroToClientPopoverState
> {
    constructor(props: IntroToClientPopoverComponentProps) {
        super(props);
        this.state = {
            introDescriptionDialogOpen: false
        };
        this.ensureDataExists(props);
    }

    ensureDataExists = (props: IntroToClientPopoverComponentProps) => {
        const { emailTemplates } = props;
        if (!emailTemplates) {
            this.props.getEmailTemplates(templateGroup);
        }
        if (props.emailAccounts.isEmpty()) {
            props.getAllEmailAccountInfo();
        }
    };

    getTemplateContext = () => {
        const { personId, jobId, personsDetails, clients, jobs } = this.props;
        const personDetails = personsDetails.list.get(personId);
        const { person, profile, profileUrls } = personDetails;
        const job = jobs.get(jobId);
        const client = clients.list.get(job.clientId);
        const personContacts = personDetails.contacts;
        return { person, job, client, profile, profileUrls, personContacts };
    };

    getAccountSenderName = (email: string) => {
        const { emailAccounts } = this.props;
        const account = emailAccounts.get(email);
        return account.name;
    };

    getCandidateAddress = (message?: Communication) => {
        const { personId, personsDetails } = this.props;
        const { person, contacts } = personsDetails.list.get(personId);
        const name = person.name.full;
        if (message) {
            const participants = message.headers.to.concat(message.headers.cc).concat([message.headers.from]);
            const candidateAddress = participants.find(
                (p) => contacts.findIndex((c) => c.channel === ContactChannel.Email && c.value === p.address) !== -1
            );
            if (candidateAddress) {
                return { name, ...candidateAddress };
            }
        }
        const address = contacts.find((c) => c.channel === ContactChannel.Email && !c.invalid);
        return { name, address: address.value };
    };

    getLastEmail = () => {
        const { candidates, jobs, emailAccounts, communications, users, jobId, personId } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        const job = jobs.get(jobId);
        const personCommunications = communications.get(personId);
        if (!personCommunications || !job || emailAccounts.isEmpty()) {
            return {};
        }
        const lastEmail = getLastCommunicationWithCandidate(personCommunications, emailAccounts, candidate);
        let account: EmailAccountView;
        if (lastEmail) {
            const lastEmailAccount = emailAccounts.get(lastEmail.account);
            if (lastEmailAccount.syncStatus === 'enabled') {
                account = lastEmailAccount;
            }
        }
        if (!account) {
            const assignedUser = users.find((u) => u.id === candidate.assignee);
            const accountOptions = getAccountOptions(emailAccounts, assignedUser, undefined);
            account = emailAccounts.get(accountOptions?.[0]?.address);
        }
        return { lastEmail, account };
    };

    handleCreatePreIntroEmail = () => {
        // find last email we exchanged with candidate for this job and send a "reply" to that
        const {
            personId,
            jobId,
            candidates,
            emailTemplates,
            emailAccounts,
            jobs,
            clients,
            personsDetails,
            users,
            user
        } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        const job = jobs.get(jobId);

        const defaultPreIntroTemplateKind = job.submissionSetting.introClientEmailDisabled
            ? 'Pre - intro email to candidate without client intro'
            : 'Pre - intro email to candidate';

        const preIntroTemplate =
            job.templates.preIntroduction ||
            emailTemplates.find(
                (template) => template.group === templateGroup && template.kind === defaultPreIntroTemplateKind
            );
        const { lastEmail, account } = this.getLastEmail();
        const introEmailWindowId = `intro-email-${personId}-${jobId}`;

        const { contacts, person } = personsDetails.list.get(personId);
        const client = clients.list.get(job.clientId);

        const attachments: FilePayload[] = job.onePager
            ? [
                  {
                      filename: job.onePager.key.split('/').reverse()[0],
                      key: `media/files/${job.onePager.key}`,
                      size: job.onePager.size,
                      type: 's3Key'
                  }
              ]
            : [];

        const onSentAction = job.submissionSetting.introClientEmailDisabled
            ? moveCandidateToStage(candidate, clientFirstRoundStage)
            : toggleComposeWindow(introEmailWindowId, false);
        const onDeleteAction = job.submissionSetting.introClientEmailDisabled
            ? null
            : toggleComposeWindow(introEmailWindowId, false);

        if (!account) {
            this.props.showModalAlert(
                <span>
                    Email account not found to send pre-intro email for job {client.name} - {job.title}
                </span>,
                'Email account not found'
            );
        } else {
            const senderName = account.name;
            const initialBody = interpolateEmailTemplate(preIntroTemplate.body, {
                ...this.getTemplateContext(),
                senderAccount: account,
                senderName,
                user
            });
            const accountOptions = getAccountOptions(emailAccounts, user, jobId, {
                address: account.email,
                name: senderName.full
            });
            if (lastEmail) {
                if (lastEmail.account === account.email) {
                    this.props.replyToEmailWithValidation(
                        accountOptions,
                        this.props.emailContentBlacklistedDomains,
                        lastEmail,
                        true,
                        'candidate',
                        {
                            client,
                            emailAccounts: emailAccounts.valueSeq().toArray(),
                            job,
                            personContacts: contacts,
                            users: users.valueSeq().toArray()
                        },
                        true,
                        {
                            attachments,
                            canEditToRecipients: true,
                            isClientComm: false,
                            jobId: this.props.jobId,
                            onDeleteAction,
                            onSentAction,
                            personId: this.props.personId
                        },
                        initialBody
                    );
                } else {
                    // reply to last email with new account
                    this.props.replyToEmailWithValidationWithDifferentAccount(
                        accountOptions,
                        this.props.emailContentBlacklistedDomains,
                        lastEmail,
                        { address: account.email, name: account.name.full },
                        true,
                        'candidate',
                        {
                            client,
                            emailAccounts: emailAccounts.valueSeq().toArray(),
                            job,
                            personContacts: contacts,
                            users: users.valueSeq().toArray()
                        },
                        {
                            attachments,
                            canEditToRecipients: true,
                            isClientComm: false,
                            jobId: this.props.jobId,
                            onDeleteAction,
                            onSentAction,
                            personId: this.props.personId
                        },
                        initialBody
                    );
                }
            } else {
                // compose new email for this candidate
                const subject = interpolateEmailTemplate(preIntroTemplate.subject, {
                    ...this.getTemplateContext(),
                    senderName
                });
                const address = getEmailForOutreach(contacts || [], person.id, job.contactTypes);
                const candidateAddress = { address, name: person.name.full };

                this.props.composeEmailWithValidation(
                    {
                        account: { address: account.email, name: account.name.full },
                        accountOptions,
                        attachments,
                        body: initialBody,
                        canEditToRecipients: true,
                        draftSavedAt: null,
                        headers: {
                            bcc: preIntroTemplate.bcc,
                            cc: preIntroTemplate.cc,
                            subject,
                            to: [candidateAddress]
                        },
                        isClientComm: false,
                        jobId,
                        onDeleteAction: toggleComposeWindow(introEmailWindowId, false),
                        onSentAction: toggleComposeWindow(introEmailWindowId, false),
                        personId,
                        threadId: null,
                        windowId: `pre-intro-email-${personId}-${jobId}`
                    },
                    'candidate',
                    {
                        client,
                        emailAccounts: emailAccounts.valueSeq().toArray(),
                        job,
                        personContacts: contacts,
                        users: users.valueSeq().toArray()
                    }
                );
            }
            if (!job.submissionSetting.introClientEmailDisabled) {
                this.handleCreateIntroEmail(account.name, this.getCandidateAddress(lastEmail), introEmailWindowId);
            }
        }
    };

    handleCreateIntroEmail = (
        senderName: { first: string; last: string; full: string },
        candidateAddress: EmailAddress,
        windowId: string
    ) => {
        const {
            emailAccounts,
            emailTemplates,
            jobs,
            jobId,
            personId,
            candidates,
            clients,
            users,
            personsDetails
        } = this.props;
        const job = jobs.get(jobId);
        const introTemplateData =
            job.templates.introduction ||
            emailTemplates.find((template) => template.group === templateGroup && template.kind === introTemplate);
        const client = clients.list.get(job.clientId);
        const body = interpolateEmailTemplate(introTemplateData.body, {
            ...this.getTemplateContext(),
            senderName
        });
        const subject = interpolateEmailTemplate(introTemplateData.subject, {
            ...this.getTemplateContext(),
            senderName
        });
        const accountData = emailAccounts.get(IntroEmailsAccount);
        const account = {
            address: accountData.email,
            name: accountData.name.full
        };
        const candidate = candidates.get(jobId).get(personId);
        const introContact = client.hiringManagers.find((h) => h.email === job.introEmailContact);
        const introCc = job.introEmailCc
            .map((c) => client.hiringManagers.find((h) => h.email === c))
            .map((h) => ({ name: h.name.full, address: h.email }));
        const cc = (introTemplateData.cc || []).concat(introCc);

        this.props.composeEmailWithValidation(
            {
                account,
                accountOptions: [account],
                attachments: [],
                body,
                canEditToRecipients: true,
                disabled: true,
                draftSavedAt: null,
                headers: {
                    bcc: introTemplateData.bcc,
                    cc,
                    subject,
                    to: [{ address: introContact.email, name: introContact.name.full }, candidateAddress]
                },
                isClientComm: true,
                jobId,
                onSentAction: moveCandidateToStage(candidate, clientFirstRoundStage),
                personId,
                threadId: null,
                windowId
            },
            'intro',
            {
                client,
                emailAccounts: emailAccounts.valueSeq().toArray(),
                job,
                personContacts: personsDetails.list.get(personId).contacts,
                users: users.valueSeq().toArray()
            }
        );
    };

    handleSkipAndMoveForward = () => {
        this.props.onRequestClose(null);
        const { candidates, jobId, personId } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        this.props.moveCandidateToStage(candidate, clientFirstRoundStage);
    };

    handleConfirmIntroDescription = () => {
        this.setState({ introDescriptionDialogOpen: false });
        this.handleCreatePreIntroEmail();
    };

    handleSelectSendIntroEmails = () => {
        const { jobId, jobs } = this.props;
        const job = jobs.get(jobId);
        this.props.onRequestClose(null);
        if (job.submissionSetting.introductionDirections) {
            this.setState({ introDescriptionDialogOpen: true });
        } else {
            this.handleCreatePreIntroEmail();
        }
    };

    render() {
        const {
            emailTemplates,
            clients,
            jobId,
            jobs,
            emailAccounts,
            listsState,
            personId,
            candidates,
            user,
            userPermissions
        } = this.props;
        const job = jobs.get(jobId);
        const client = job ? clients.list.get(job.clientId) : null;
        if (
            !job ||
            !client ||
            !emailTemplates ||
            !emailTemplates.find((e) => e.group === templateGroup) ||
            emailAccounts.isEmpty() ||
            listsState.get('users') !== 'initialized'
        ) {
            return null;
        }

        const introDialogActions = [<FlatButton key="ok" label="OK" onClick={this.handleConfirmIntroDescription} />];
        const introDialog = job.submissionSetting.introductionDirections ? (
            <Dialog open={this.state.introDescriptionDialogOpen} actions={introDialogActions}>
                <Editor
                    value={job.submissionSetting.introductionDirections}
                    className="introduction-directions"
                    label="Introduction Directions"
                    disabled={true}
                />
            </Dialog>
        ) : null;

        const candidate = candidates.get(jobId).get(personId);
        const disabled =
            !hasRole(userPermissions, 'site_owner') &&
            candidate.assignee !== user.id &&
            job.accountManagerId !== user.id;
        return (
            <React.Fragment>
                <Popover
                    open={this.props.open}
                    anchorEl={this.props.anchorEl}
                    onRequestClose={this.props.onRequestClose}
                >
                    <Menu desktop={true}>
                        <MenuItem
                            key="send-intro-emails"
                            primaryText="Send Introduction Emails"
                            onClick={this.handleSelectSendIntroEmails}
                            disabled={disabled}
                        />
                        <MenuItem
                            key="move-forward-without-sending"
                            primaryText="Skip and move forward"
                            onClick={this.handleSkipAndMoveForward}
                            disabled={disabled}
                        />
                    </Menu>
                </Popover>
                {introDialog}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    candidates: state.candidates,
    clients: state.clients,
    communications: state.communications,
    emailAccounts: state.emailAccounts,
    emailContentBlacklistedDomains: state.appConstants.constants.emailContentBlacklistedDomains,
    emailTemplates: state.emailTemplates.get(templateGroup),
    jobs: state.jobs,
    listsState: state.listsState,
    personsDetails: state.personsDetails,
    user: state.session.user,
    userPermissions: state.session.userPermissions,
    users: state.users
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    composeEmailWithValidation,
    getAllEmailAccountInfo,
    getEmailTemplates,
    moveCandidateToStage,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount,
    showModalAlert
};

export const IntroCandidateClientPopover = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(IntroToClientPopoverComponent);
