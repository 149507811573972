import { css } from '@emotion/core';
import { MenuItem, Select } from '@material-ui/core';
import { Map } from 'immutable';
import * as React from 'react';
import { getPreviousMonths, getPreviousWeeks } from '../common/timestamp';

const styles = css`
    background: white;
    margin: 0 0 0 15px;

    .MuiOutlinedInput-input {
        padding-top: 14px;
        padding-bottom: 14px;
        background: white;
    }
`;

const lowActivityJobPeriods = ['oneWeek', 'twoWeeks', 'threeWeeks', 'oneMonth', 'twoMonths', 'threeMonths'];

export type LowActivityJobPeriodFilter =
    | 'oneWeek'
    | 'twoWeeks'
    | 'threeWeeks'
    | 'oneMonth'
    | 'twoMonths'
    | 'threeMonths';

const lowActivityJobPeriodFilterLabels: Map<LowActivityJobPeriodFilter, string> = Map({
    oneMonth: '> One Month Old',
    oneWeek: '> One Week Old',
    threeMonths: '> Three Months Old',
    threeWeeks: '> Three Weeks Old',
    twoMonths: '> Two Months Old',
    twoWeeks: '> Two Weeks Old'
}) as Map<LowActivityJobPeriodFilter, string>;

// tslint:disable:no-magic-numbers
export const lowActivityJobPeriodForFilter: Map<LowActivityJobPeriodFilter, number> = Map({
    oneMonth: getPreviousMonths(1),
    oneWeek: getPreviousWeeks(1),
    threeMonths: getPreviousMonths(3),
    threeWeeks: getPreviousWeeks(3),
    twoMonths: getPreviousMonths(2),
    twoWeeks: getPreviousWeeks(2)
}) as Map<LowActivityJobPeriodFilter, number>;
// tslint:enable:no-magic-numbers

interface LowActivityJobsPeriodFiltersProps {
    selectedFilter: LowActivityJobPeriodFilter;
    onSelectFilter: (filter: LowActivityJobPeriodFilter) => void;
}

export const LowActivityJobsPeriodFilters: React.FC<LowActivityJobsPeriodFiltersProps> = (props) => {
    const { selectedFilter } = props;

    const handleChange = (event: React.ChangeEvent<{ value: LowActivityJobPeriodFilter }>) => {
        props.onSelectFilter(event.target.value);
    };

    const menuItems = (lowActivityJobPeriods as LowActivityJobPeriodFilter[]).map((filter) => (
        <MenuItem key={filter} value={filter}>
            {lowActivityJobPeriodFilterLabels.get(filter)}
        </MenuItem>
    ));
    return (
        <Select css={styles} value={selectedFilter} onChange={handleChange} variant="outlined">
            {menuItems}
        </Select>
    );
};
