import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';

import { Job } from '../graphql/queries/person';
import { JobDetails } from './job-details';

const dialogStyles = css`
    .dialog-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            margin-right: 25px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

export const JobDetailsDialog: React.FC<{ job: Job; open: boolean; onClose: () => void }> = ({
    job,
    open,
    onClose
}) => {
    return open ? (
        <Dialog open={true} onClose={onClose} maxWidth="md" css={dialogStyles}>
            <DialogTitle>
                <div className="dialog-title">
                    <Typography variant="h4" component="div">
                        {job.client.name} - {job.title}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <JobDetails job={job} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    ) : null;
};
