import { gql } from '@apollo/client';

export interface ClientCandidates {
    id: string;
    name: string;
    hiringManagers: [
        {
            email: string;
            name: {
                first: string;
                full: string;
            };
        }
    ];
    jobs: [
        {
            id: string;
            title: string;
            hiringManagerEmails: string[];
            hiringManagerCc: string[];
            accountManager: {
                id: string;
                email: string;
                name: string;
            };
            candidates: [
                {
                    stage: string;
                    candidateClientSubmit: Array<{
                        submittedToClientAt: number;
                    }>;
                    person: {
                        id: string;
                        name: string;
                        profile_urls: Array<{ url: string }>;
                    };
                    accountManager: {
                        id: string;
                        email: string;
                        name: string;
                    };
                }
            ];
        }
    ];
}

export const CLIENT_CANDIDATES = gql`
    query ClientCandidates($id: String!, $userId: String!) {
        clients(where: { id: { _eq: $id } }) {
            id
            name
            hiringManagers
            jobs(where: { status: { _eq: 1 }, accountManagerId: { _eq: $userId } }, order_by: { createdAt: asc }) {
                id
                title
                hiringManagerEmails
                hiringManagerCc
                accountManager: userByAccountmanagerid {
                    id
                    email
                    name(path: "full")
                }
                candidates(
                    where: {
                        disqualified: { _eq: false }
                        stage: {
                            _in: [
                                "awaiting_client_feedback"
                                "client_first_round"
                                "client_middle_round"
                                "client_second_round"
                            ]
                        }
                    }
                ) {
                    stage
                    candidateClientSubmit {
                        submittedToClientAt
                    }
                    person {
                        id
                        name(path: "full")
                        profile_urls(limit: 1, where: { url: { _ilike: "%linkedin.com/in/%" } }) {
                            url
                        }
                    }
                    accountManager: userByAccountmanagerid {
                        id
                        email
                        name(path: "full")
                    }
                }
            }
        }
    }
`;
