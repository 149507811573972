import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import { Shuffle } from '@material-ui/icons';
import * as React from 'react';

import { borderColor, borderRadius } from '../common/css-variables';
import { CROSSPITCHABLE_JOBS, CrosspitchableJob } from '../graphql/queries/person';
import { JobTitleWithDetails } from './job-title-with-details';

const styles = (theme: Theme) => css`
    margin-bottom: 20px;
    border-radius: ${borderRadius};
    border: thin solid ${borderColor};
    background: white;

    .cp-header {
        padding: 10px 15px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        border-bottom: thin solid ${borderColor};
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiSvgIcon-root {
            font-size: 1rem;
        }

        &.highlight {
            background: ${theme.palette.success.light};
        }
    }

    .cp-jobs {
        padding: 10px 15px 15px;
    }
`;

export const CrosspitchableJobs: React.FC<{
    personId: string;
    clientId: string;
    highlight: boolean;
}> = ({ personId, clientId, highlight }) => {
    const { data } = useQuery<{ jobs: CrosspitchableJob[] }>(CROSSPITCHABLE_JOBS, {
        variables: { personId, clientId }
    });

    const theme = useTheme();
    if (!data) {
        return null;
    } else if (data && data.jobs.length > 0) {
        const rows = data.jobs.map((j) => <JobTitleWithDetails job={j.job} key={j.job.id} />);
        return (
            <div css={styles(theme)}>
                <div className={`cp-header ${highlight ? 'highlight' : ''}`}>
                    <span>Crosspitch Opportunities</span>
                    <span>
                        <Shuffle />
                    </span>
                </div>
                <div className="cp-jobs">{rows}</div>
            </div>
        );
    }
    return null;
};
