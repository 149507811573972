import { MenuItem, TextField } from '@material-ui/core';
import { Map } from 'immutable';
import * as React from 'react';

import { Editor } from 'react-ce';
import { JobSubmissionSetting, JobSubmissionType } from 'shared/models/job';

interface JobSubmissionSettingCardProps {
    submissionSetting: JobSubmissionSetting;
    isEditable: boolean;
    errors: Map<string, string>;
    onChange: (submissionSetting: JobSubmissionSetting) => void;
}

export const JobSubmissionSettingCard: React.FC<JobSubmissionSettingCardProps> = (props) => {
    const { submissionSetting, isEditable, errors } = props;

    const handleTypeChange = (event: React.ChangeEvent<{ value: any }>) => {
        const password = event.target.value.includes(JobSubmissionType.ATS) ? null : submissionSetting.password;
        const username = event.target.value.includes(JobSubmissionType.ATS) ? null : submissionSetting.username;
        const newSubmissionSetting = { ...submissionSetting, type: event.target.value, password, username };
        props.onChange(newSubmissionSetting);
    };

    const handleTextFieldChange = (field: 'username' | 'password' | 'atsLink') => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newValue = event.target.value.trim() ? event.target.value : null;
        const newSubmissionSetting = { ...submissionSetting, [field]: newValue };
        props.onChange(newSubmissionSetting);
    };

    const handleDescriptionChange = (value: string) => {
        const newSubmissionSetting: JobSubmissionSetting = { ...submissionSetting, description: value };
        props.onChange(newSubmissionSetting);
    };

    const typeError = errors.get('submission-setting-type');
    const passwordError = errors.get('submission-setting-password');
    const usernameError = errors.get('submission-setting-username');
    const atsLinkError = errors.get('submission-setting-atsLink');

    const loginInfo = submissionSetting.type.includes(JobSubmissionType.ATS) ? (
        <React.Fragment>
            <TextField
                fullWidth={true}
                disabled={!isEditable}
                name="atsLink"
                label="ATS Link"
                placeholder="Specify the ATS Link"
                onChange={handleTextFieldChange('atsLink')}
                value={submissionSetting.atsLink || ''}
                error={Boolean(atsLinkError)}
                helperText={atsLinkError}
            />
            <TextField
                fullWidth={true}
                disabled={!isEditable}
                name="username"
                label="ATS Username"
                placeholder="Add username"
                onChange={handleTextFieldChange('username')}
                value={submissionSetting.username || ''}
                error={Boolean(usernameError)}
                helperText={usernameError}
            />
            <TextField
                fullWidth={true}
                disabled={!isEditable}
                name="password"
                label="ATS Password"
                placeholder="Add password"
                onChange={handleTextFieldChange('password')}
                value={submissionSetting.password || ''}
                error={Boolean(passwordError)}
                helperText={passwordError}
            />
        </React.Fragment>
    ) : null;

    return (
        <div className="job-submission-setting">
            <Editor
                value={submissionSetting.description}
                onChange={handleDescriptionChange}
                label="Submission Steps Description"
                className="submission-steps-description"
                disabled={!isEditable}
            />
            <TextField
                value={submissionSetting.type}
                select={true}
                onChange={handleTypeChange}
                SelectProps={{ multiple: true }}
                disabled={!isEditable}
                fullWidth={true}
                helperText={typeError}
                error={Boolean(typeError)}
                label="Submission Method"
            >
                <MenuItem
                    selected={submissionSetting.type.includes(JobSubmissionType.Email)}
                    value={JobSubmissionType.Email}
                >
                    Email
                </MenuItem>
                <MenuItem
                    selected={submissionSetting.type.includes(JobSubmissionType.ATS)}
                    value={JobSubmissionType.ATS}
                >
                    ATS
                </MenuItem>
            </TextField>
            {loginInfo}
        </div>
    );
};
