import { css } from '@emotion/core';
import { ChevronRight, Work } from '@material-ui/icons';
import * as React from 'react';

import { secondaryTextColor } from '../common/css-variables';
import { Job } from '../graphql/queries/person';
import { JobDetailsDialog } from './job-details-dialog';

const styles = css`
    padding: 5px 0 10px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;

    &:last-child {
        padding-bottom: 0;
    }

    .cp-job-title {
        display: flex;
        align-items: center;
        color: ${secondaryTextColor};

        .icon {
            display: flex;
            align-items: center;
        }

        .work {
            margin-top: -3px;

            .MuiSvgIcon-root {
                font-size: 20px;
                margin-right: 10px;
            }
        }
    }
`;

export const JobTitleWithDetails: React.FC<{ job: Job }> = ({ job }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleJobClick = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <div key={job.id} onClick={handleJobClick} css={styles}>
                <div className="cp-job-title">
                    <span className="icon work">
                        <Work />
                    </span>
                    {job.client.name}
                    <span className="icon chevron">
                        <ChevronRight />
                    </span>
                    {job.title}
                </div>
            </div>
            <JobDetailsDialog open={dialogOpen} onClose={handleCloseDialog} job={job} />
        </>
    );
};
