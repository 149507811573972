import { Map, OrderedMap, OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { Menu, MenuItem, Popover, PopoverProps } from 'material-ui';
import * as React from 'react';
import { connect } from 'react-redux';

import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { EmailTemplateView } from 'shared/models/email-templates';
import { JobSubmissionType, SubmissionAccount } from 'shared/models/job';
import {
    amRejected,
    amRequestedInfo,
    amSubmitApproved,
    awaitingAMApproval,
    awaitingClientStage,
    rocketScreenCompleteStage,
    waitingForDocsStage
} from 'shared/models/job-stages';
import { NoteAttachment, NoteView } from 'shared/models/note';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';
import { CrunchbaseData } from 'shared/types/crunchbase';

import { FilePayload } from 'shared/types/file-payload';

import {
    fetchEntityNotes,
    fetchUsers,
    getAllEmailAccountInfo,
    getEmailTemplates,
    moveCandidateToStage
} from '../actions';
import { deleteDraft, getDraft } from '../common/draft-storage';
import { EmailKind } from '../common/email/header-validator';
import { getSubmissionProfileData } from '../common/profile';
import { composeEmailWithValidation, EmailValidationData } from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { Candidate, Client, EmailAccount, Job, List, ListState, NoteDraftState, PersonDetails, State } from '../state';
import { NoteDialog } from './note-dialog';
import { SubmissionATSDialog } from './submission-ats-dialog';
import { SubmissionGPTDialog } from './submission-gpt-dialog';

interface OwnProps extends PopoverProps {
    client: Client;
    jobId: string;
    personId: string;
    onRequestClose: () => void;
}

interface ConnectedProps {
    candidates: Map<string, OrderedMap<string, Candidate>>;
    crunchbase: Map<string, CrunchbaseData>;
    emailAccounts: Map<string, EmailAccount>;
    emailTemplates: EmailTemplateView[];
    jobs: OrderedMap<string, Job>;
    personsDetails: List<PersonDetails>;
    users: Map<string, UserData>;
    sessionUser: UserData;
    notes: Map<string, OrderedSet<NoteView>>;
    listsState: Map<string, ListState>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    getEmailTemplates: (group: string) => void;
    getAllEmailAccountInfo: () => void;
    composeEmailWithValidation: (
        data: ComposeEmailWindowData,
        emailKind: EmailKind,
        validationData: EmailValidationData
    ) => void;
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
    fetchEntityNotes: (notable: string) => void;
    fetchUsers: () => void;
}

type SubmitToClientPopoverProps = OwnProps & ConnectedProps & ConnectedDispatch;

interface SubmitToClientPopoverState {
    noteDialogOpen: 'submit' | 'reject';
    noteDialogInitialDraft: NoteDraftState;
    submissionNoteId: string;
    atsDialogOpen: boolean;
    submissionGenerationDialogOpen: boolean;
}

class SubmitToClientPopoverComponent extends React.Component<SubmitToClientPopoverProps, SubmitToClientPopoverState> {
    constructor(props: SubmitToClientPopoverProps) {
        super(props);
        this.state = {
            atsDialogOpen: false,
            noteDialogInitialDraft: null,
            noteDialogOpen: null,
            submissionGenerationDialogOpen: false,
            submissionNoteId: undefined
        };
        this.ensureDataExists(props);
    }

    ensureDataExists = (props: SubmitToClientPopoverProps) => {
        const { emailTemplates, notes, personId, listsState, emailAccounts } = props;
        if (!emailTemplates) {
            this.props.getEmailTemplates('submission');
        }
        if (emailAccounts.isEmpty() || !emailAccounts.has(SubmissionAccount)) {
            props.getAllEmailAccountInfo();
        }
        const notable = `persons-${personId}`;
        if (!notes.get(notable)) {
            props.fetchEntityNotes(notable);
        }
        if (!listsState.get('users')) {
            props.fetchUsers();
        }
    };

    getSubmissionAccount = () => {
        const { emailAccounts } = this.props;
        return emailAccounts.get(SubmissionAccount);
    };

    getExistingSubmissionNote = () => {
        const { personId, jobId, notes } = this.props;
        const existingNote = notes
            .get(`persons-${personId}`)
            .find(
                (n) =>
                    n.context.isSubmissionNote === true &&
                    n.context.jobId === jobId &&
                    !n.context.isSubmissionNoteChangeRequest &&
                    !n.context.isSubmissionNoteReply &&
                    !n.context.isATSSubmissionNote &&
                    n.parentId === null &&
                    !n.deleted
            );

        return existingNote;
    };

    getGeneratedSubmissionBody = (generatedText: string) => {
        const { client, jobId, jobs, personId, personsDetails, emailTemplates, crunchbase } = this.props;
        const personDetails = personsDetails.list.get(personId);
        const submissionAccount = this.getSubmissionAccount();
        const job = jobs.get(jobId);
        const templateKind = job.jobType === 'S' ? 'Client Submission - Sourcer' : 'Client Submission - Default';
        const defaultTemplate = emailTemplates.find((t) => t.group === 'submission' && t.kind === templateKind);
        const submissionTemplate = job.templates.submission ?? defaultTemplate;
        const templateData: any = {
            client,
            job,
            person: personDetails.person,
            personContacts: personDetails.contacts,
            profile: getSubmissionProfileData(personDetails.profile, crunchbase),
            profileUrls: personDetails.profileUrls,
            senderAccount: submissionAccount
        };
        const bodyTemplate = submissionTemplate.body.replace(
            '{generated submission content | submission details}',
            generatedText.replace(new RegExp(personDetails.person.name.full, 'i'), '{{candidateNameWithLink}}')
        );
        const body = interpolateEmailTemplate(bodyTemplate, templateData);
        return body;
    };

    getSubmissionContent = () => {
        const { client, jobId, jobs, personId, personsDetails, emailTemplates, crunchbase } = this.props;

        const job = jobs.get(jobId);
        const submissionAccount = this.getSubmissionAccount();
        const account = {
            address: submissionAccount.email,
            name: submissionAccount.name.full
        };
        const personDetails = personsDetails.list.get(personId);
        const templateKind = job.jobType === 'S' ? 'Client Submission - Sourcer' : 'Client Submission - Default';
        const defaultTemplate = emailTemplates.find(
            (template) => template.group === 'submission' && template.kind === templateKind
        );
        const submissionTemplate = job.templates.submission ?? defaultTemplate;

        const hiringManagers = job.hiringManagerEmails.map((hiringManagerEmail) =>
            client.hiringManagers.find((h) => h.email === hiringManagerEmail)
        );
        const hiringManagerAddresses = hiringManagers.map((hiringManager) => ({
            address: hiringManager.email,
            name: hiringManager.name.full
        }));
        const hiringManagersCc = job.hiringManagerCc.map((hiringManagerEmail) =>
            client.hiringManagers.find((h) => h.email === hiringManagerEmail)
        );
        const hiringManagerCcAddresses = hiringManagersCc.map((hiringManager) => ({
            address: hiringManager.email,
            name: hiringManager.name.full
        }));

        const templateData: any = {
            client,
            job,
            person: personDetails.person,
            personContacts: personDetails.contacts,
            profile: getSubmissionProfileData(personDetails.profile, crunchbase),
            profileUrls: personDetails.profileUrls,
            senderAccount: submissionAccount
        };
        const subject = interpolateEmailTemplate(submissionTemplate.subject, templateData);
        const body = interpolateEmailTemplate(submissionTemplate.body, templateData);
        return {
            account,
            bcc: submissionTemplate.bcc,
            body,
            cc: hiringManagerCcAddresses.concat(submissionTemplate.cc),
            subject,
            to: hiringManagerAddresses
        };
    };

    handleCreateSubmission = () => {
        this.props.onRequestClose();
        const { candidates, jobId, personId, client, emailAccounts, jobs, personsDetails, users } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        const job = jobs.get(jobId);
        const details = personsDetails.list.get(personId);
        const windowId = `submit-${personId}-${jobId}`;
        const { account, subject, body, cc, bcc, to } = this.getSubmissionContent();

        const existingNote = this.getExistingSubmissionNote();
        const content = existingNote ? existingNote.content : body;
        const attachments: FilePayload[] = existingNote
            ? existingNote.attachments.map((a) => ({ ...a, type: 's3Key' as 's3Key' }))
            : [];

        this.props.composeEmailWithValidation(
            {
                account,
                accountOptions: [account],
                attachments,
                body: content,
                canEditToRecipients: true,
                draftSavedAt: null,
                headers: {
                    bcc,
                    cc,
                    subject,
                    to
                },
                isClientComm: true,
                jobId,
                onSentAction: moveCandidateToStage(candidate, awaitingClientStage),
                personId,
                scheduledMessageRestricted: true,
                threadId: null,
                windowId
            },
            'client',
            {
                client,
                emailAccounts: emailAccounts.valueSeq().toArray(),
                job,
                personContacts: details.contacts,
                users: users.valueSeq().toArray()
            }
        );
    };

    handleSkipAndMoveForward = () => {
        this.props.onRequestClose();
        const {
            candidates,
            client,
            jobs,
            emailTemplates,
            jobId,
            personId,
            personsDetails,
            emailAccounts,
            users
        } = this.props;
        const details = personsDetails.list.get(personId);
        const candidate = candidates.get(jobId).get(personId);
        const job = jobs.get(jobId);
        const template = emailTemplates.find((e) => e.kind === 'Skip and Move Forward' && e.group === 'submission');
        const submissionAccount = this.getSubmissionAccount();

        const templateData = {
            client,
            job,
            person: details.person,
            profile: details.profile,
            profileUrls: details.profileUrls,
            senderAccount: submissionAccount
        };

        const subject = interpolateEmailTemplate(template.subject, templateData);
        const body = interpolateEmailTemplate(template.body, templateData);
        const account = {
            address: submissionAccount.email,
            name: submissionAccount.name.full
        };
        const windowId = `skip-${personId}-${jobId}`;

        this.props.composeEmailWithValidation(
            {
                account,
                accountOptions: [account],
                attachments: [],
                body,
                canEditToRecipients: true,
                draftSavedAt: null,
                headers: {
                    bcc: template.bcc,
                    cc: template.cc,
                    subject,
                    to: [{ address: 'clientcomms@getrocket.com', name: 'Client Comms' }]
                },
                isClientComm: true,
                jobId,
                onSentAction: moveCandidateToStage(candidate, awaitingClientStage),
                personId,
                threadId: null,
                windowId
            },
            'intro',
            {
                client,
                emailAccounts: emailAccounts.valueSeq().toArray(),
                job,
                personContacts: details.contacts,
                users: users.valueSeq().toArray()
            }
        );
    };

    handleMarkWaitingForDocs = () => {
        this.props.onRequestClose();
        const { candidates, jobId, personId } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        this.props.moveCandidateToStage(candidate, waitingForDocsStage);
    };

    noteDraftKey = (key: 'submit' | 'reject') => {
        const { personId, jobId } = this.props;
        return `submission-note-${key}-${personId}-${jobId}`;
    };

    handleRejectSubmit = () => {
        const existingNote = this.getExistingSubmissionNote();
        const submissionNoteId = existingNote.id;
        this.setState({
            noteDialogInitialDraft: {
                addingAttachment: false,
                content: '',
                context: { isSubmissionNoteChangeRequest: true },
                initialAttachments: [],
                modifiedAt: Date.now(),
                saving: false
            },
            noteDialogOpen: 'reject',
            submissionNoteId
        });
        this.props.onRequestClose();
    };

    handleRejectionNoteSave = () => {
        const { candidates, personId, jobId } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        this.props.moveCandidateToStage(candidate, amRejected);
        deleteDraft(this.noteDraftKey('reject'));
        this.setState({ noteDialogOpen: null });
    };

    handleApproveSubmit = () => {
        this.props.onRequestClose();
        const { candidates, jobId, personId } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        this.props.moveCandidateToStage(candidate, amSubmitApproved);
    };

    handleShowSubmitToAMDialog = (generatedContent?: string) => {
        const { jobId } = this.props;
        const existingNote = this.getExistingSubmissionNote();
        const submissionNoteId = existingNote ? existingNote.id : undefined;
        getDraft<NoteDraftState>(this.noteDraftKey('submit')).then((existingDraft) => {
            let noteInitialContent: string;
            const noteInitialAttachments: NoteAttachment[] = existingNote ? existingNote.attachments : [];
            if (existingNote && existingDraft) {
                noteInitialContent =
                    existingNote.modifiedAt > existingDraft.modifiedAt ? existingNote.content : existingDraft.content;
            } else {
                const newContent = generatedContent
                    ? this.getGeneratedSubmissionBody(generatedContent)
                    : this.getSubmissionContent().body;
                noteInitialContent = existingDraft
                    ? existingDraft.content
                    : existingNote
                    ? existingNote.content
                    : newContent;
            }
            this.setState({
                noteDialogInitialDraft: {
                    addingAttachment: false,
                    content: noteInitialContent,
                    context: { jobId, isSubmissionNote: true },
                    initialAttachments: noteInitialAttachments,
                    modifiedAt: Date.now(),
                    saving: false
                },
                noteDialogOpen: 'submit',
                submissionNoteId
            });
        });
    };

    handleSubmitToAccountManager = () => {
        this.props.onRequestClose();
        this.setState({ submissionGenerationDialogOpen: true });
    };

    handleSubmissionGenerated = (generated: string) => {
        this.setState({ submissionGenerationDialogOpen: false });
        this.handleShowSubmitToAMDialog(generated);
    };

    handleSubmissionGenerationSkipped = () => {
        this.setState({ submissionGenerationDialogOpen: false });
        this.handleShowSubmitToAMDialog();
    };

    handleNoteDiscard = () => {
        deleteDraft(this.noteDraftKey(this.state.noteDialogOpen));
        this.setState({ noteDialogOpen: null });
    };

    handleSubmissionNoteSave = () => {
        const { candidates, jobId, personId } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        this.setState({ noteDialogOpen: null });
        deleteDraft(this.noteDraftKey('submit'));
        this.props.moveCandidateToStage(candidate, awaitingAMApproval);
    };

    closeATSDialog = () => {
        this.setState({ atsDialogOpen: false });
    };

    handleSubmitToHiringManager = () => {
        const { jobs, jobId } = this.props;
        const job = jobs.get(jobId);
        const {
            submissionSetting: { type }
        } = job;
        if (type.includes(JobSubmissionType.ATS)) {
            this.setState({ atsDialogOpen: true });
        } else {
            this.handleCreateSubmission();
        }
    };

    handleATSSubmission = () => {
        const { candidates, jobId, personId } = this.props;
        const candidate = candidates.get(jobId).get(personId);
        this.props.moveCandidateToStage(candidate, awaitingClientStage);
    };

    render() {
        const {
            emailTemplates,
            candidates,
            client,
            jobId,
            jobs,
            personId,
            sessionUser,
            notes,
            emailAccounts,
            listsState,
            userPermissions
        } = this.props;
        const { atsDialogOpen, noteDialogOpen } = this.state;
        const candidate = candidates.get(jobId).get(personId);
        const job = jobs.get(jobId);
        if (
            !job ||
            !client ||
            !emailTemplates ||
            !emailTemplates.find((e) => e.group === 'submission') ||
            !notes.get(`persons-${personId}`) ||
            emailAccounts.isEmpty() ||
            listsState.get('users') !== 'initialized'
        ) {
            return null;
        }
        let hiringManagerOptions;
        const disabled =
            !hasRole(userPermissions, 'site_owner') &&
            candidate.assignee !== sessionUser.id &&
            job.accountManagerId !== sessionUser.id;
        if (job.accountManagerId && candidate.stage === awaitingAMApproval) {
            const canMoveForward =
                !job.accountManagerId ||
                job.accountManagerId === sessionUser.id ||
                hasRole(userPermissions, 'submit_approver');
            hiringManagerOptions = [
                <MenuItem
                    key="approve-submit"
                    primaryText="Approve to Submit"
                    onClick={this.handleApproveSubmit}
                    disabled={!canMoveForward}
                />,
                <MenuItem
                    key="reject-submit"
                    primaryText="Reject Candidate"
                    onClick={this.handleRejectSubmit}
                    disabled={!canMoveForward}
                />
            ];
        } else if (
            job.accountManagerId &&
            (candidate.stage === rocketScreenCompleteStage ||
                candidate.stage === amRequestedInfo ||
                candidate.stage === amRejected ||
                candidate.stage === waitingForDocsStage)
        ) {
            hiringManagerOptions = (
                <MenuItem
                    key="submit-to-account-manager"
                    primaryText="Submit to Account Manager"
                    onClick={this.handleSubmitToAccountManager}
                    disabled={disabled}
                />
            );
        } else {
            if (job.hiringManagerEmails.length === 0) {
                hiringManagerOptions = [
                    <MenuItem key="add-hiring-managers" disabled={true} primaryText="No hiring managers!" />
                ];
            } else {
                hiringManagerOptions = [
                    <MenuItem
                        key="submit-to-hiring-manager"
                        primaryText={`Submit to hiring manager${job.hiringManagerEmails.length > 1 ? 's' : ''}`}
                        onClick={this.handleSubmitToHiringManager}
                        disabled={disabled}
                    />
                ];
            }
        }
        const moveToWaitingForDocsOption =
            candidate.stage === rocketScreenCompleteStage ? (
                <MenuItem
                    key="move-to-waiting-for-docs"
                    primaryText="Move to Waiting for Documents"
                    onClick={this.handleMarkWaitingForDocs}
                />
            ) : null;
        let dialog;
        if (noteDialogOpen) {
            let handleSaveNote: () => void;
            let title: string;
            let noteId: string;
            let placeholder: string;
            if (noteDialogOpen === 'reject') {
                handleSaveNote = this.handleRejectionNoteSave;
                title = 'Rejection of Candidate';
                placeholder = 'Add rejection reason';
            } else if (noteDialogOpen === 'submit') {
                handleSaveNote = this.handleSubmissionNoteSave;
                title = 'Submission to Account Manager';
                noteId = this.state.submissionNoteId;
            }
            dialog = (
                <NoteDialog
                    initialDraft={this.state.noteDialogInitialDraft}
                    personId={personId}
                    jobId={jobId}
                    noteId={noteId}
                    onCancel={this.handleNoteDiscard}
                    onSave={handleSaveNote}
                    noteDraftKey={this.noteDraftKey(noteDialogOpen)}
                    title={title}
                    placeholder={placeholder}
                />
            );
        } else if (atsDialogOpen) {
            dialog = (
                <SubmissionATSDialog
                    personId={personId}
                    jobId={jobId}
                    onRequestClose={this.closeATSDialog}
                    onATSSubmission={this.handleATSSubmission}
                    requestCreateSubmission={this.handleCreateSubmission}
                />
            );
        }
        const skipAndMoveOption =
            !job.accountManagerId || candidate.stage === amSubmitApproved ? (
                <MenuItem
                    key="move-forward-without-sending"
                    primaryText="Skip and move forward"
                    onClick={this.handleSkipAndMoveForward}
                />
            ) : null;
        const submissionGenerateDialog = this.state.submissionGenerationDialogOpen ? (
            <SubmissionGPTDialog
                personId={personId}
                job={job}
                userId={candidate.assignee}
                open={this.state.submissionGenerationDialogOpen}
                onConfirm={this.handleSubmissionGenerated}
                onSkip={this.handleSubmissionGenerationSkipped}
            />
        ) : null;
        return (
            <React.Fragment>
                {submissionGenerateDialog}
                <Popover
                    open={this.props.open}
                    anchorEl={this.props.anchorEl}
                    onRequestClose={this.props.onRequestClose}
                >
                    <Menu desktop={true} onItemClick={this.props.onRequestClose}>
                        {hiringManagerOptions}
                        {moveToWaitingForDocsOption}
                        {skipAndMoveOption}
                    </Menu>
                </Popover>
                {dialog}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    candidates: state.candidates,
    crunchbase: state.crunchbase,
    emailAccounts: state.emailAccounts,
    emailTemplates: state.emailTemplates.get('submission'),
    jobs: state.jobs,
    listsState: state.listsState,
    notes: state.notes,
    personsDetails: state.personsDetails,
    sessionUser: state.session.user,
    userPermissions: state.session.userPermissions,
    users: state.users
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    composeEmailWithValidation,
    fetchEntityNotes,
    fetchUsers,
    getAllEmailAccountInfo,
    getEmailTemplates,
    moveCandidateToStage
};

export const SubmitToClientPopover = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(SubmitToClientPopoverComponent);
