import { Map } from 'immutable';

import * as actions from '../actions';
import * as emailActions from '../email-inbox/actions';
import { RequestErrors } from '../state';

const initialState: Map<string, RequestErrors> = Map();

export function pendingRequests(state = initialState, action: actions.Action): Map<string, RequestErrors> {
    switch (action.type) {
        case actions.RequestJobInfo:
            return state.set(`job-${action.jobId}`, Map());
        case actions.ReceiveJobInfo:
            return state.remove(`job-${action.payload.job.id}`);
        case actions.RequestNewJob:
            return state.set('job-create', Map());
        case actions.ReceiveNewJob: {
            return state.remove('job-create');
        }
        case actions.RequestUserInfo:
            return state.set(`user-fetch-${action.payload.id}`, Map());
        case actions.ReceiveUserInfo:
            return state.remove(`user-fetch-${action.payload.user.id}`);
        case actions.RequestJobUpdate:
            return state.set(`job-update-${action.payload.id}`, Map());
        case actions.ReceiveJobUpdate:
            return state.remove(`job-update-${action.payload.id}`);
        case actions.RequestEmailCounts:
            return state.set(`get-email-counts-${action.payload.email}`, Map());
        case actions.ReceiveEmailCounts:
            return state.remove(`get-email-counts-${action.payload.email}`);
        case actions.RequestAllEmailAccountInfo:
            return state.set(`get-all-email-accounts`, Map());
        case actions.ReceiveAllEmailAccountInfo:
            return state.remove(`get-all-email-accounts`);
        case actions.RequestPersonDetails:
            return state.set(`person-details-request-${action.payload.personId}`, Map());
        case actions.ReceivePersonDetails:
            return state.remove(`person-details-request-${action.payload.personId}`);
        case actions.RequestPersonWebsiteAdd:
            return state.set(`website-update-request-${action.id}`, Map());
        case actions.ReceivePersonWebsiteAdd:
            return action.payload.errors
                ? state.set(`website-update-request-${action.payload.person.id}`, Map(action.payload.errors))
                : state.remove(`website-update-request-${action.payload.person.id}`);
        case actions.RequestPersonUpdate:
            return state.set(`person-update-request-${action.id}`, Map());
        case actions.ReceivePersonUpdate:
            return state.remove(`person-update-request-${action.payload.id}`);
        case actions.RequestPersonFilesUpload:
            return state.set(`person-files-upload-${action.payload.personId}`, Map());
        case actions.ReceivePersonFilesUploadResult:
            return action.payload.errors
                ? state.set(`person-files-upload-${action.payload.personId}`, Map(action.payload.errors))
                : state.remove(`person-files-upload-${action.payload.personId}`);
        case actions.RequestJobOnePager:
            return state.set(`job-onepager-upload-${action.payload.jobId}`, Map());
        case actions.ReceiveJobOnePagerResult:
            return action.payload.errors
                ? state.set(`job-onepager-upload-${action.payload.jobId}`, Map(action.payload.errors))
                : state.remove(`job-onepager-upload-${action.payload.jobId}`);
        case actions.ReceiveUsersList:
            return state.remove('users-list');
        case actions.RequestUsersList:
            return state.set('users-list', Map());
        case actions.RequestContactDelete:
            return state.set(
                `contact-delete-request-${action.payload.personId}-${action.payload.channel}-${action.payload.value}`,
                Map()
            );
        case actions.ReceiveContactDelete:
            return state.remove(
                `contact-delete-request-${action.payload.personId}-${action.payload.channel}-${action.payload.value}`
            );
        case actions.RequestContactAdd:
        case actions.RequestContactUpdate:
            return state.set(`contact-update-request-${action.payload.personId}-${action.payload.channel}`, Map());
        case actions.ReceiveContactsUpdate:
            return action.payload.errors
                ? state.set(
                      `contact-update-request-${action.payload.personId}-${action.payload.channel}`,
                      Map(action.payload.errors)
                  )
                : state.remove(`contact-update-request-${action.payload.personId}-${action.payload.channel}`);
        case actions.RequestClientWeeklyUpdateData:
            return state.set(`weekly-update-request-${action.payload.clientId}`, Map());
        case actions.ReceiveClientWeeklyUpdateData:
            return state.remove(`weekly-update-request-${action.payload.data.client.id}`);
        case actions.RequestUploadClientPersonBlacklist:
            return state.set(`upload-client-person-blacklist-${action.payload.clientId}`, Map());
        case actions.ReceiveUploadClientPersonBlacklist:
            return state.remove(`upload-client-person-blacklist-${action.payload.client.id}`);

        case actions.RequestClientFiles:
            return state.set(`client-files-upload-${action.payload.clientId}`, Map());
        case actions.ReceiveClientFilesResult:
            return action.payload.errors
                ? state.set(`client-files-upload-${action.payload.clientId}`, Map(action.payload.errors))
                : state.remove(`client-files-upload-${action.payload.clientId}`);

        case actions.RequestRemoveClientFile:
            return state.set(`remove-client-file-${action.payload.clientId}-${action.payload.key}`, Map());
        case actions.ReceiveRemoveClientFileResult:
            return state.remove(`remove-client-file-${action.payload.clientId}-${action.payload.key}`);

        case actions.RequestPersonJobLabels:
            return state.set(`person-job-labels-${action.personId}-${action.jobId}`, Map());
        case actions.RequestCreatePersonJobLabel:
            return state.set(`person-job-labels-create-${action.personId}-${action.jobId}`, Map());
        case actions.ReceivePersonJobLabels:
            return state
                .remove(`person-job-labels-${action.payload.personId}-${action.payload.jobId}`)
                .remove(`person-job-labels-create-${action.payload.personId}-${action.payload.jobId}`);
        case actions.RequestPersonJobScore:
            return state.set(`person-job-score-${action.personId}-${action.jobId}`, Map());
        case actions.ReceivePersonJobScore:
            return state.remove(`person-job-score-${action.payload.personId}-${action.payload.jobId}`);
        case actions.RequestPersonSearch:
            return state.set(`person-search`, Map());
        case actions.ReceivePersonSearchResult:
            return state.remove(`person-search`);
        case actions.MoveCandidateToStage:
            const candidate = action.payload.candidate;
            return state.set(`candidate-update-${candidate.personId}-${candidate.jobId}`, Map());
        case actions.RequestAddCandidateToJob:
            return state.set(
                `candidate-add-${action.payload.personId}-${action.payload.jobId}-${action.payload.source}`,
                Map()
            );
        case actions.ReceiveCandidateAddedToJob:
            return state.remove(
                `candidate-add-${action.payload.candidate.personId}-` +
                    `${action.payload.candidate.jobId}-${action.payload.source}`
            );
        case actions.ReceiveCandidateUpdate: {
            const { personId, jobId } = action.payload.candidate;
            return state
                .remove(`candidate-update-${personId}-${jobId}`)
                .remove(`candidate-interview-${personId}-${jobId}`)
                .remove(`candidates-last-recruiter-action-${personId}-${jobId}`);
        }
        case actions.RequestCandidate:
            return state.set(`candidate-job-${action.payload.jobId}-person-${action.payload.personId}`, Map());
        case actions.ReceiveCandidate:
            return state.remove(
                `candidate-job-${action.payload.candidate.jobId}-person-${action.payload.candidate.personId}`
            );
        case actions.RequestPersonContacts:
            return state.set(`person-contacts-${action.payload.personId}`, Map());
        case actions.ReceivePersonContacts:
            return state.remove(`person-contacts-${action.payload.personId}`);
        case actions.RequestSaveEmailTemplate:
            return state.set('save-email-template', Map());
        case actions.ReceiveUpdatedEmailTemplate:
            return state.remove('save-email-template');
        case actions.RequestTemplateDelete:
            return state.set('delete-email-template', Map());
        case actions.ReceiveTemplateDeleteConfirmation:
            return state.remove('delete-email-template');
        case actions.RequestJobMetrics:
            return state.set('job-metrics', Map());
        case actions.ReceiveJobMetrics:
            return state.remove('job-metrics');
        case actions.RequestJobSearches:
            return state.set(`job-searches-${action.payload.jobId}`, Map());
        case actions.ReceiveJobSearches:
            return state.remove(`job-searches-${action.payload.jobId}`);
        case actions.RequestJobSearchStats:
            return state.set(`job-searches-stats-${action.payload.jobId}`, Map());
        case actions.ReceiveJobSearchStats:
            return state.remove(`job-searches-stats-${action.payload.jobId}`);
        case actions.RequestSearchResults:
            return state.set('fetching-search-results', Map());
        case actions.ReceiveSearchResults:
            return state.remove('fetching-search-results');
        case actions.RequestSearchUpdate:
            return state.set(`search-updates-${action.payload.id}`, Map());
        case actions.ReceiveUpdatedSearch:
            return state.remove(`search-updates-${action.payload.search.id}`);
        case actions.RequestSearchProfiles:
            return state.set(`search-profiles-${action.payload.searchId}`, Map());
        case actions.ReceiveSearchProfiles:
            return state.remove(`search-profiles-${action.payload.searchId}`);
        case actions.RequestSearchCreate:
            return state.set('search-create', Map());
        case actions.ReceiveNewSearch:
            return state.remove('search-create');
        case actions.RequestSearchPurge:
            return state.set(`search-purge-${action.payload.id}`, Map());
        case actions.ReceiveSearchPurge:
            return state.remove(`search-purge-${action.payload.id}`);
        case actions.RequestEmailAccountCreate:
            return state.set(`create-email-account`, Map());
        case actions.ReceiveEmailAccountCreated:
            return state.remove(`create-email-account`);
        case actions.RequestJobEmails:
            return state.set(`get-job-emails-${action.payload.jobId}`, Map());
        case actions.ReceiveJobEmails:
            return state.remove(`get-job-emails-${action.payload.jobId}`);
        case actions.RequestEmailTemplates:
            return state.set(`get-email-templates-${action.payload.group}`, Map());
        case actions.ReceiveEmailTemplates:
            return state.remove(`get-email-templates-${action.payload.group}`);
        case actions.RequestPurgeCandidates:
            return state.set(`job-purge-${action.payload.id}`, Map());
        case actions.ReceivePurgeCandidatesResponse:
            return state.remove(`job-purge-${action.payload.id}`);
        case actions.RequestFileDownload:
            return state.set(`download-file-${action.payload.url}`, Map());
        case actions.ReceiveFileDownload:
            return state.remove(`download-file-${action.payload.url}`);
        case actions.ReceiveFlaggedEmails:
            return state.remove(`flagged-emails-${action.payload.account}`);
        case actions.RequestFlaggedEmails:
            return state.set(`flagged-emails-${action.payload.account}`, Map());
        case actions.ReceivePersonCommunications:
            return state.remove(`person-communications-${action.payload.id}`);
        case actions.RequestPersonCommunications:
            return state.set(`person-communications-${action.payload.id}`, Map());
        case actions.ReceiveCommunicationMatcher:
        case actions.ReceiveCommunicationMatchDataUpdate:
            return state.remove(
                `update-match-data-${action.payload.communications[0].account}-${action.payload.communications[0].threadId}`
            );
        case actions.RequestCommunicationMatcher:
        case actions.RequestCommunicationMatchDataUpdate:
            return state.set(
                `update-match-data-${action.payload.communication.account}-${action.payload.communication.threadId}`,
                Map()
            );
        case actions.RequestAccountManagerData:
            return state.set(`fetch-account-manager-data-${action.payload.id}`, Map());
        case actions.ReceiveAccountManagerData:
            return state.remove(`fetch-account-manager-data-${action.payload.id}`);
        case actions.RequestJobSearchForSourcing:
            return state.set(`job-search-for-sourcing-${action.payload.jobId}`, Map());
        case actions.ReceiveJobSearchForSourcing:
            return state.remove(`job-search-for-sourcing-${action.payload.jobId}`);
        case actions.RequestAddSearchResultToJob:
            return state.set(
                `adding-search-result-${action.payload.jobId}-${action.payload.result.searchId}-${action.payload.result.personId}`,
                Map()
            );
        case actions.ReceiveAddSearchResultToJob:
            return state.remove(
                `adding-search-result-${action.payload.jobId}-${action.payload.result.searchId}-${action.payload.result.personId}`
            );
        case actions.RequestSearchResultPersonAndCandidate:
            return state.set(
                `fetch-search-result-person-candidate-${action.payload.result.searchId}-${action.payload.result.personId}`,
                Map()
            );
        case actions.ReceiveSearchResultPersonAndCandidate:
            return state.remove(
                `fetch-search-result-person-candidate-${action.payload.result.searchId}-${action.payload.result.personId}`
            );
        case actions.RequestCandidateDisqualify:
            return state.set(
                `candidate-update-${action.payload.candidate.personId}-${action.payload.candidate.jobId}`,
                Map()
            );
        case actions.RequestJobCandidatesSummary:
            return state.set(`fetch-candidates-summary-${action.payload.jobId}`, Map());
        case actions.ReceiveJobCandidatesSummary:
            return state.remove(`fetch-candidates-summary-${action.payload.jobId}`);
        case actions.RequestJobAssigneeData:
            return state.set(`fetch-job-assignee-data-${action.payload.id}`, Map());
        case actions.ReceiveJobAssigneeData:
            return state.remove(`fetch-job-assignee-data-${action.payload.id}`);
        case actions.RequestUpdateJobPrimaryEmail: {
            let newState = state.set(`job-${action.payload.id}-primary-email-${action.payload.primaryEmail}`, Map());
            newState = newState.set(`job-${action.payload.id}-primary-email`, Map());
            return newState;
        }
        case actions.ReceiveUpdateJobPrimaryEmail: {
            let newState = state.remove(
                `job-${action.payload.job.id}-primary-email-${action.payload.job.primaryEmail}`
            );
            newState = newState.remove(`job-${action.payload.job.id}-primary-email`);
            return newState;
        }
        case actions.RequestCrossAddCandidate:
            return state.set(`cross-add-candidate-${action.payload.personId}`, Map());
        case actions.ReceiveCrossAddCandidate:
            return state.remove(`cross-add-candidate-${action.payload.candidates[0].personId}`);
        case actions.RequestPersonSetBlacklisted:
            return state.set(`person-update-blacklisted-${action.payload.personId}`, Map());
        case actions.ReceivePersonSetBlacklisted:
            return state.remove(`person-update-blacklisted-${action.payload.person.id}`);
        case actions.RequestCandidateAssigneeData:
            return state.set(`fetch-candidate-assignee-data-${action.payload.id}`, Map());
        case actions.ReceiveCandidateAssigneeData:
            return state.remove(`fetch-candidate-assignee-data-${action.payload.id}`);
        case actions.RequestPersonCrunchbaseData:
            return state.set(`fetch-person-crunchbase-data-${action.payload.personId}`, Map());
        case actions.ReceivePersonCrunchbaseData:
            return state.remove(`fetch-person-crunchbase-data-${action.payload.personId}`);
        case emailActions.RequestAccountsList:
            return state.set('fetch-email-inbox-accounts', Map());
        case emailActions.ReceiveAccountsList:
            return state.remove('fetch-email-inbox-accounts');
        case actions.RequestUserUpdate:
            return state.set(`user-update-${action.payload.userId}`, Map());
        case actions.ReceiveUserUpdate:
            return state.remove(`user-update-${action.payload.user.id}`);
        case actions.RequestCrossAddMultiple:
            return state.set(`cross-add-candidates`, Map());
        case actions.ReceiveCrossAddMultiple:
            return state.remove(`cross-add-candidates`);
        case actions.RequestForwardFlaggedEmail:
            return state.set(`forward-flagged-email-${action.payload.account}-${action.payload.messageId}`, Map());
        case actions.ReceiveForwardFlaggedEmail:
            return state.remove(
                `forward-flagged-email-${action.payload.communications[0].account}-${action.payload.communications[0].messageId}`
            );
        case actions.RequestUpdatePersonFilename:
            return state.set(`update-filename-${action.payload.personId}-${action.payload.path}`, Map());
        case actions.ReceiveUpdatePersonFilename:
            return state.remove(`update-filename-${action.payload.personId}-${action.payload.path}`);
        case actions.RequestCancelScheduledMessage:
            return state.set(`scheduled-messages-update-${action.payload.id}`, Map());
        case actions.ReceiveCancelScheduledMessage:
            return state.remove(`scheduled-messages-update-${action.payload.id}`);
        case actions.RequestRescheduleMessage:
            return state.set(`scheduled-messages-update-${action.payload.scheduledMessage.id}`, Map());
        case actions.ReceiveRescheduleMessage:
            return state.remove(`scheduled-messages-update-${action.payload.oldMessageId}`);
        case actions.RequestUserBlacklistPerson:
            return state.set(`user-blacklist-person-${action.payload.personId}`, Map());
        case actions.ReceiveUserBlacklistPerson:
            return state.remove(`user-blacklist-person-${action.payload.personId}`);
        case actions.RequestPauseJobSearches:
            return state.set(`job-${action.payload.jobId}-pause-all-searches`, Map());
        case actions.ReceivePauseJobSearches:
            return state.remove(`job-${action.payload.jobId}-pause-all-searches`);
        case actions.RequestReassignCandidateAssignee:
            return state.set(`candidate-reassign-assignee-${action.payload.personId}-${action.payload.jobId}`, Map());
        case actions.ReceiveReassignCandidateAssignee:
            return state.remove(
                `candidate-reassign-assignee-${action.payload.candidate.personId}-${action.payload.candidate.jobId}`
            );
        case actions.RequestNotes:
            return state.set(`notes-${action.payload.notable}`, Map());
        case actions.ReceiveNotes:
            return state.remove(`notes-${action.payload.notable}`);
        case actions.RequestNewNote:
            return state.set(`note-create-${action.payload.note.notable}`, Map());
        case actions.ReceiveNewNote:
        case actions.ReceiveNoteError:
            return state.remove(`note-create-${action.payload.note.notable}`);
        case actions.RequestSavePreset:
            return state.set(`save-preset`, Map());
        case actions.ReceivedSavePreset:
            return state.remove(`save-preset`);
        case actions.RequestAddProspectsToCampaign:
            return state.set(`prospects-add-${action.payload.campaignId}`, Map());
        case actions.ReceiveProspectsAddedToCampaign:
            return state.remove(`prospects-add-${action.payload.campaignId}`);
        case actions.RequestAddSourcerMembersToSourcer:
            return state.set(`sourcer-members-add-${action.payload.sourcerId}`, Map());
        case actions.ReceiveSourcerMembersAddedToSourcer:
            return state.remove(`sourcer-members-add-${action.payload.sourcerId}`);
        case actions.ExtensionRequestJobs:
            return state.set(`extension-jobs-request`, Map());
        case actions.ExtensionReceiveJobs:
            return state.remove(`extension-jobs-request`);
        case actions.RequestProfileLinkUpdate:
            return state.set(`website-update-request-${action.payload.url}`, Map());
        case actions.ReceiveProfileLinkUpdate:
            return state.remove(`website-update-request-${action.payload.url}`);
        default:
            return state;
    }
}
