import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { isEqual, pick } from 'lodash';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { hasRole } from 'shared/models/user';

import { useSubscription } from '@apollo/client';
import {
    fetchJobInfo,
    getEmailTemplates,
    getJobEmails,
    updateClient,
    updateJob,
    updateJobPrimaryEmail
} from '../actions';
import { JobForm } from '../components/job-form';
import { JobPageHeader } from '../components/job-page-header';
import { JOB_LAST_MODIFICATION_AT } from '../graphql/queries/job';
import { useModal } from '../hooks/use-modal';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { Client, Job } from '../state';

const fields: Array<keyof Job> = [
    'accountManagerId',
    'address',
    'assignee',
    'clientId',
    'contactTypes',
    'dailyEmailLimit',
    'dailyPerUserEmailLimit',
    'details',
    'discipline',
    'headcount',
    'hiringManagerCc',
    'hiringManagerEmails',
    'introEmailCc',
    'introEmailContact',
    'jobType',
    'location',
    'maxIdleDays',
    'mlAutoOutreachLimit',
    'mlMaxResultsAutoOutreach',
    'outreachAIAdditionalContext',
    'phoneCallRequired',
    'recruiterPermissions',
    'removeUnrespondedCandidates',
    'scoringAIAdditionalContext',
    'status',
    'submissionGenAIAdditionalContext',
    'submissionSetting',
    'templates',
    'title',
    'visaStatusRequired'
];

export const JobEdit: React.FC<{ id: string }> = ({ id }) => {
    const { data: modificationData } = useSubscription<{ job: { modifiedAt: number } }, { jobId: string }>(
        JOB_LAST_MODIFICATION_AT,
        { variables: { jobId: id } }
    );
    const [initialJob, setInitialJob] = React.useState<Partial<Job> | null>(null);
    const [hasChanged, setHasChanged] = React.useState(false);
    const { user, userPermissions } = useSession();
    const { getConfirmation, setAlert } = useModal();
    const dispatch = useReduxDispatch();
    const jobs = useReduxState((state) => state.jobs);
    const clients = useReduxState((state) => state.clients);
    const pendingRequests = useReduxState((state) => state.pendingRequests);
    const emailTemplates = useReduxState((state) => state.emailTemplates);
    const jobEmails = useReduxState((state) => state.jobEmails);
    const job = jobs?.get(id);
    const requestKey = `job-update-${id}`;
    const isCreating = pendingRequests.has(requestKey) && pendingRequests.get(requestKey).isEmpty();

    React.useEffect(() => {
        if (
            modificationData?.job?.modifiedAt &&
            job &&
            job.modifiedAt !== modificationData.job.modifiedAt &&
            !isCreating
        ) {
            setInitialJob(job);
            dispatch(fetchJobInfo(id));
        }
    }, [modificationData, job, isCreating]);

    React.useEffect(() => {
        if (initialJob && !isEqual(pick(job, fields), pick(initialJob, fields))) {
            setHasChanged(true);
        } else {
            setInitialJob(null);
        }
    }, [job]);

    React.useEffect(() => {
        if (!jobs || !jobs.get(id)) {
            dispatch(fetchJobInfo(id));
        }
        if (!jobEmails.get(id)) {
            dispatch(getJobEmails(id));
        }
        const templateGroups = ['phonescreen', 'introduction', 'submission'];
        for (const group of templateGroups) {
            if (!emailTemplates.get(group)) {
                dispatch(getEmailTemplates(group));
            }
        }
    }, [id]);

    const handleSave = (params: Partial<Job>) => {
        dispatch(updateJob(id, params));
    };

    const handleUpdateClient = (clientId: string, updates: Partial<Client>) => {
        dispatch(updateClient(clientId, updates));
    };

    const handleUpdateJobPrimaryEmail = (jobId: string, primaryEmail: string) => {
        dispatch(updateJobPrimaryEmail(jobId, primaryEmail));
    };

    const showModalAlert = (description: string | JSX.Element, title: string, nonCancelable?: boolean) => {
        setAlert(title, description, nonCancelable);
    };

    const handleReload = () => {
        window.location.reload();
    };

    const forceReloadDialog = hasChanged ? (
        <Dialog open={true}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Changes Detected
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" component="div">
                    The job has been modified elsewhere. Please reload the page to see the latest changes.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReload}>Reload</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    if (
        jobs &&
        jobs.get(id) &&
        clients.list.get(jobs.get(id).clientId) &&
        jobEmails.get(id) &&
        emailTemplates.get('phonescreen') &&
        emailTemplates.get('introduction') &&
        emailTemplates.get('submission')
    ) {
        const client = clients.list.get(job.clientId);
        const emailsForJob = jobEmails.get(id);

        const isEditable =
            hasRole(userPermissions, 'settings_editor') || user.id === job.accountManagerId || user.id === job.assignee;

        return (
            <DocumentTitle title={`Editing Job - ${client.name} - ${job.title}`}>
                <div id="container">
                    <JobPageHeader jobId={id} actions={[]} activeTab="Settings" />
                    <div id="content" className="flex-fill">
                        <JobForm
                            clients={clients}
                            updateClient={handleUpdateClient}
                            formTitle={isEditable ? 'Edit Job' : 'Job Settings'}
                            isCreating={isCreating}
                            onSave={handleSave}
                            jobEmails={emailsForJob}
                            pendingRequests={pendingRequests}
                            newJob={false}
                            isEditable={isEditable}
                            updateJobPrimaryEmail={handleUpdateJobPrimaryEmail}
                            {...job}
                            getConfirmation={getConfirmation}
                            user={user}
                            showModalAlert={showModalAlert}
                            emailTemplates={emailTemplates}
                            userPermissions={userPermissions}
                        />
                    </div>
                    {forceReloadDialog}
                </div>
            </DocumentTitle>
        );
    } else {
        return null;
    }
};
