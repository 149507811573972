import { Divider, MenuItem, MenuList, Popover } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';

import { Client, HiringManagerData } from '../state';
import { HiringManagerForm } from './hiring-manager-form';

interface ClientContactSelectorProps {
    isEditable: boolean;
    client: Client;
    onSelect: (email: string) => void;
    selected: string | string[];
    updateClient: (clientId: string, updates: Partial<Client>) => void;
}

interface ClientContactSelectorState {
    formOpen: boolean;
    popoverOpen: boolean;
    popoverAnchor: Element;
}

export class ClientContactSelector extends React.Component<ClientContactSelectorProps, ClientContactSelectorState> {
    private textField: any;

    constructor(props: ClientContactSelectorProps) {
        super(props);
        this.state = {
            formOpen: false,
            popoverAnchor: null,
            popoverOpen: false
        };
        this.textField = React.createRef();
    }

    handleCloseForm = () => {
        this.setState({ formOpen: false });
    };

    handleOpenForm = () => {
        this.setState({ formOpen: true, popoverOpen: false });
    };

    handleRequestMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.isEditable) {
            this.setState({ popoverOpen: true, popoverAnchor: event.currentTarget });
        }
    };

    handleRequestMenuClose = () => {
        this.setState({ popoverOpen: false });
    };

    handleAddManagerToClient = (hiringManagerData: HiringManagerData) => {
        const { id, hiringManagers } = this.props.client;
        this.props.updateClient(id, {
            hiringManagers: hiringManagers.concat([hiringManagerData])
        });
        this.setState({ formOpen: false });
    };

    handleSelect = (email: string) => () => {
        this.props.onSelect(email);
    };

    selectedText = () => {
        const {
            client: { hiringManagers },
            selected
        } = this.props;
        if (!selected || (Array.isArray(selected) && selected.length === 0)) {
            return 'Select';
        } else {
            const managers = hiringManagers.filter((h) => selected.indexOf(h.email) !== -1);
            if (Array.isArray(selected) && selected.length > 1) {
                return managers.map((m) => m.name.first).join(', ');
            } else {
                return managers[0].name.full;
            }
        }
    };

    render() {
        const { client, selected, isEditable } = this.props;
        const { formOpen, popoverOpen, popoverAnchor } = this.state;
        const clientHiringManagers: HiringManagerData[] = _.orderBy(client.hiringManagers, 'name.full');
        const items = clientHiringManagers
            .map((manager) => {
                const isSelected = Array.isArray(selected)
                    ? _.includes(selected, manager.email)
                    : selected === manager.email;
                return (
                    <MenuItem
                        key={manager.email}
                        value={manager.email}
                        selected={isSelected}
                        onClick={this.handleSelect(manager.email)}
                    >
                        {manager.name.full}
                    </MenuItem>
                );
            })
            .concat([
                <Divider key="divider" />,
                <MenuItem key="create-new-manager" value="create-new-manager" onClick={this.handleOpenForm}>
                    Add new contact...
                </MenuItem>
            ]);

        const hiringManagerForm = formOpen ? (
            <HiringManagerForm
                clientDomains={client.domains}
                title="Add New Client Contact"
                onCancel={this.handleCloseForm}
                onSave={this.handleAddManagerToClient}
            />
        ) : null;

        const popupWidth = this.textField && this.textField.current ? this.textField.current.clientWidth : 0;
        return (
            <div className="multi-select">
                <div
                    className={`multi-select-text ${!isEditable ? 'disabled' : ''}`}
                    onClick={this.handleRequestMenuOpen}
                    ref={this.textField}
                >
                    {this.selectedText()}
                    <div className="multi-select-dropdown-arrow">
                        <i className="material-icons">arrow_drop_down</i>
                    </div>
                </div>
                <Popover
                    open={popoverOpen}
                    anchorEl={popoverAnchor}
                    onClose={this.handleRequestMenuClose}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    className="multi-select-popup"
                >
                    <MenuList style={{ width: popupWidth }}>{items}</MenuList>
                </Popover>
                {hiringManagerForm}
            </div>
        );
    }
}
