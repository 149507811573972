import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Theme,
    useTheme
} from '@material-ui/core';
import { escapeRegExp } from 'lodash';
import { orderBy } from 'lodash';
import * as React from 'react';

import {
    amSubmitApproved,
    awaitingClientStage,
    clientFirstRoundStage,
    offerStage,
    responseReceivedStage,
    totalFunnelCount
} from 'shared/models/job-stages';

import { SearchTextField } from '../common/search-text-field';
import { timeFrom } from '../common/timestamp';
import { Spinner } from '../core-ui/spinner';
import { Job, JOBS } from '../graphql/queries/jobs-low-activity-table';
import { useLocalStorage } from '../hooks/use-local-storage';
import { JobFilterTypes, JobType, jobTypesForFilter } from '../state';
import { JobTitle } from './home/job-title';
import { LowActivityJobFilter, LowActivityJobsFilters } from './jobs-low-activity-table-activity-filters';
import {
    LowActivityJobPeriodFilter,
    lowActivityJobPeriodForFilter,
    LowActivityJobsPeriodFilters
} from './jobs-low-activity-table-period-filters';
import { JobsTypeFilter } from './jobs-type-filter';

// tslint:disable:no-magic-numbers
const styles = (theme: Theme) => css`
    background: #f4f6f8;
    flex: 1 1 auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .filters {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-end;
        padding: 0;
        margin-bottom: 20px;
    }

    .MuiPaper-root {
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: space-between;

        .MuiTableContainer-root {
            flex: 1 1 auto;

            &::-webkit-scrollbar:horizontal {
                border-top: thin solid ${theme.palette.divider};
            }

            &::-webkit-scrollbar:vertical {
                border-left: thin solid ${theme.palette.divider};
            }

            .MuiTableRow-root:last-child {
                td.MuiTableCell-root.MuiTableCell-body {
                    border-bottom: none;
                }
            }
        }
    }

    .pagination {
        flex: 0 0 auto;
        border-top: thin solid ${theme.palette.divider};
    }
`;

const columns = [
    'Client',
    'Job',
    'Account Manager',
    'Assignee',
    'Created Date',
    '# Emails Sent (14d)',
    '# Calls Completed (14d)',
    '# Submits (14d)',
    '# Active Rocket Stages',
    '# Awaiting Feedback',
    '# Active Client Stages'
];

const stageCount = (job: Job, stage: string) => {
    return job.stageFunnelCounts[stage] ? totalFunnelCount(job.stageFunnelCounts[stage].qualified) : 0;
};

const betweenStagesCount = (job: Job, pre: string, post: string, jobStages: Array<{ id: string; name: string }>) => {
    const preIndex = jobStages.find((s) => s.name === pre).id;
    const postIndex = jobStages.find((s) => s.name === post).id;
    const betweenStages = jobStages.filter((s) => s.id > preIndex && s.id < postIndex);

    let count = 0;
    for (const stage of betweenStages) {
        count += stageCount(job, stage.name);
    }
    return count;
};

const sortFuncs = (col: string, sortOrder: 'asc' | 'desc', jobStages?: Array<{ id: string; name: string }>) => {
    switch (col) {
        case 'Client':
            return { functions: [(j: Job) => j.client.name.toLocaleLowerCase()], order: [sortOrder] };
        case 'Job':
            return { functions: [(j: Job) => j.title.toLocaleLowerCase()], order: [sortOrder] };
        case 'Account Manager':
            return {
                functions: [(j: Job) => j.accountManager?.name.toLocaleLowerCase()],
                order: [sortOrder]
            };
        case 'Assignee':
            return {
                functions: [(j: Job) => j.assignee?.name.toLocaleLowerCase()],
                order: [sortOrder]
            };

        case 'Created Date':
            return { functions: [(j: Job) => -j.createdAt], order: [sortOrder] };

        case '# Emails Sent (14d)':
            return { functions: [(j: Job) => j.metrics?.emailsSent], order: [sortOrder] };

        case '# Calls Completed (14d)':
            return {
                functions: [(j: Job) => j.metrics?.movedToRocketScreenComplete],
                order: [sortOrder]
            };

        case '# Submits (14d)':
            return { functions: [(j: Job) => j.metrics?.submits], order: [sortOrder] };

        case '# Active Rocket Stages':
            return {
                functions: [(j: Job) => betweenStagesCount(j, responseReceivedStage, awaitingClientStage, jobStages)],
                order: [sortOrder]
            };
        case '# Awaiting Feedback':
            return {
                functions: [(j: Job) => betweenStagesCount(j, amSubmitApproved, clientFirstRoundStage, jobStages)],
                order: [sortOrder]
            };
        case '# Active Client Stages':
            return {
                functions: [(j: Job) => betweenStagesCount(j, awaitingClientStage, offerStage, jobStages)],
                order: [sortOrder]
            };
        default:
            return { functions: [], order: [] };
    }
};

const rowsPerPage = 20;

const JobRow: React.FC<{ job: Job; jobStages: Array<{ id: string; name: string }> }> = ({ job, jobStages }) => {
    const activeRocketStages = betweenStagesCount(job, responseReceivedStage, awaitingClientStage, jobStages);
    const awaitingClientFeedback = betweenStagesCount(job, amSubmitApproved, clientFirstRoundStage, jobStages);
    const activeClientStages = betweenStagesCount(job, awaitingClientStage, offerStage, jobStages);
    return (
        <TableRow>
            <TableCell>{job.client.name}</TableCell>
            <TableCell>
                <JobTitle job={job} />
            </TableCell>
            <TableCell>{job.accountManager?.name} </TableCell>
            <TableCell>{job.assignee?.name}</TableCell>
            <TableCell>{timeFrom(job.createdAt)}</TableCell>
            <TableCell>{job.metrics?.emailsSent}</TableCell>
            <TableCell>{job.metrics?.movedToRocketScreenComplete}</TableCell>
            <TableCell>{job.metrics?.submits}</TableCell>
            <TableCell>{activeRocketStages}</TableCell>
            <TableCell>{awaitingClientFeedback}</TableCell>
            <TableCell>{activeClientStages}</TableCell>
        </TableRow>
    );
};

export const LowActivityJobsTable: React.FC<{}> = () => {
    const theme = useTheme();

    const [sortCol, setSortCol] = useLocalStorage<string>('jobs-low-activity-sort-column', 'Client');
    const [sortAsc, setSortAsc] = useLocalStorage<boolean>('jobs-low-activity-sort-asc', true);
    const [typeFilter, setTypeFilter] = useLocalStorage<JobFilterTypes>(
        'no-activity-jobs-table-job-types',
        'placement-fees'
    );
    const [periodFilter, setPeriodFilter] = useLocalStorage<LowActivityJobPeriodFilter>(
        'jobs-low-activity-period-filter',
        'threeWeeks'
    );
    const [activityFilter, setActivityFilter] = useLocalStorage<LowActivityJobFilter>(
        'jobs-low-activity-activity-filter',
        'noActivity14d'
    );
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState('');

    const { data } = useQuery<
        { jobs: Job[]; jobStages: Array<{ id: string; name: string }> },
        { createdAt: number; types: JobType[] }
    >(JOBS, {
        variables: {
            createdAt: lowActivityJobPeriodForFilter.get(periodFilter),
            types: jobTypesForFilter.get(typeFilter)
        }
    });

    const handleSortChange = (column: string) => () => {
        const newSortAsc = sortCol === column ? !sortAsc : true;
        setSortCol(column);
        setSortAsc(newSortAsc);
    };

    const handleChangePage = (_1: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => {
        /* no-op */
    };

    const handleTypeFilter = (filter: JobFilterTypes) => {
        setTypeFilter(filter);
        setPage(0);
    };

    const handleSelectPeriodFilter = (filter: LowActivityJobPeriodFilter) => {
        setPeriodFilter(filter);
        setPage(0);
    };

    const handleSelectActivityFilter = (filter: LowActivityJobFilter) => {
        setActivityFilter(filter);
        setPage(0);
    };

    const handleSearchChange = (newSearch: string) => {
        setSearch(newSearch);
        setPage(0);
    };

    if (!data) {
        return <Spinner />;
    }

    const { jobs, jobStages } = data;

    const getSortDirection = (ascending: boolean) => (ascending ? 'asc' : 'desc');
    const headerColumns = columns.map((col) => {
        const columnHeader = (
            <TableSortLabel
                active={sortCol === col}
                direction={getSortDirection(sortAsc)}
                onClick={handleSortChange(col)}
            >
                {col}
            </TableSortLabel>
        );
        return <TableCell key={col}>{columnHeader}</TableCell>;
    });

    const searchRegex = new RegExp(escapeRegExp(search), 'i');
    const filteredJobs = jobs.filter((j) => {
        let activityCondition = true;

        switch (activityFilter) {
            case 'noOutreach14d':
                activityCondition = j.metrics?.emailsSent === 0;
                break;
            case 'noCalls14d':
                activityCondition = j.metrics?.movedToRocketScreenComplete === 0;
                break;
            case 'noSubmits14d':
                activityCondition = j.metrics?.submits === 0;
                break;

            case 'noActivity14d':
                activityCondition =
                    j.metrics?.emailsSent === 0 &&
                    j.metrics?.movedToRocketScreenComplete === 0 &&
                    (activityCondition = j.metrics?.submits === 0);
                break;

            case 'allReasons':
                activityCondition =
                    j.metrics?.emailsSent === 0 ||
                    j.metrics?.movedToRocketScreenComplete === 0 ||
                    (activityCondition = j.metrics?.submits === 0);
                break;
            default:
                break;
        }

        return (
            activityCondition &&
            (!search ||
                j.accountManager?.name.match(searchRegex) ||
                j.title.match(searchRegex) ||
                j.client.name.match(searchRegex) ||
                j.assignee?.name.match(searchRegex))
        );
    });
    const { functions, order } = sortFuncs(sortCol, getSortDirection(sortAsc), jobStages);
    const rows = orderBy(filteredJobs, functions, order)
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((job) => <JobRow key={job.id} job={job} jobStages={jobStages} />);

    const pagination =
        rowsPerPage && filteredJobs.length > rowsPerPage ? (
            <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={filteredJobs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className="pagination"
            />
        ) : null;

    const header = <TableRow>{headerColumns}</TableRow>;

    return (
        <div css={styles(theme)}>
            <div className="filters">
                <SearchTextField variant="outlined" value={search} onValueChange={handleSearchChange} />
                <JobsTypeFilter selectedFilter={typeFilter} onSelectFilter={handleTypeFilter} />
                <LowActivityJobsPeriodFilters selectedFilter={periodFilter} onSelectFilter={handleSelectPeriodFilter} />
                <LowActivityJobsFilters selectedFilter={activityFilter} onSelectFilter={handleSelectActivityFilter} />
            </div>
            <Paper>
                <TableContainer>
                    <Table stickyHeader={true}>
                        <TableHead>{header}</TableHead>
                        <TableBody>{rows}</TableBody>
                    </Table>
                </TableContainer>
                {pagination}
            </Paper>
        </div>
    );
};
