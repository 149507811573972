import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import { diversityOptToString, visaOptToString } from 'shared/models/job';
import { Job } from '../state';

const styles = (theme: Theme) => css`
    border: thin solid ${theme.palette.divider};
    border-radius: 2px;
    background: white;
    margin-bottom: 20px;
    padding: 15px 18px;
    position: relative;

    .edit-button {
        position: absolute;
        bottom: 5px;
        right: 5px;
        opacity: 0;
        transition: opacity 0.2ms;
    }
    &:hover {
        .edit-button {
            opacity: 1;
        }
    }
    .info-label {
        text-transform: uppercase;
        font-size: 10px;
        color: ${theme.palette.primary.light};
        margin-bottom: 4px;
        padding: 0 2px;
    }
    .info-value {
        font-size: 14px;
        margin-bottom: 12px;
        border-bottom: thin solid ${theme.palette.divider};
        padding: 0 2px 10px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
`;

export const JobSubmissionInfoPanel: React.FC<{
    job: Job;
}> = ({ job }) => {
    const theme = useTheme();
    const visa = job.details.visa?.map(visaOptToString).join(', ');
    const diversity = diversityOptToString(job?.details?.diversity);

    const experience = [job?.details?.experience?.minYears, job?.details?.experience?.maxYears]
        .filter((v) => v !== undefined)
        .join(' - ');

    return (
        <div css={styles(theme)}>
            <div className="info-label">One Line Description </div>
            <div className="info-value">{job?.details?.onelineDescription}</div>
            <div className="info-label">Skills</div>
            <div className="info-value">{job?.details?.skills}</div>
            <div className="info-label">Experience</div>
            <div className="info-value">{experience ? `${experience} Years` : ''}</div>
            <div className="info-label">Compensation</div>
            <div className="info-value">{job?.details?.compensation}</div>
            <div className="info-label">Visa</div>
            <div className="info-value">{visa}</div>
            <div className="info-label">Location</div>
            <div className="info-value">{job?.location}</div>
            <div className="info-label">Diversity</div>
            <div className="info-value">{diversity}</div>
        </div>
    );
};
