import { css } from '@emotion/core';
import { Button, CircularProgress, Dialog, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Map } from 'immutable';
import * as _ from 'lodash';
import { ContentAdd } from 'material-ui/svg-icons';
import * as React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { connect } from 'react-redux';

import { standardizeUrl } from 'profile-parser';
import { Editor } from 'react-ce';
import { Permissions } from 'shared/models/permission';
import { hasRole, UserData } from 'shared/models/user';

import { createNewClient, fetchClientById, updateClient, uploadClientPersonBlacklist } from '../actions';
import { standardizeBlacklistEntry } from '../common/blacklist-utils';
import { isValidEmailDomain } from '../common/email/utils';
import { fullDate } from '../common/timestamp';
import { Loading } from '../core-ui/loading';
import { DayPicker } from '../core-ui/react-day-picker';
import { Client, HiringManagerData, List, RequestErrors, State } from '../state';
import { ClientContracts } from './client-contracts';
import { ClientHiringManagers } from './client-hiring-managers';
import { UserSelector } from './user-selector';

const styles = css`
    .MuiFormControl-root {
        margin-top: 20px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .MuiSwitch-root {
        position: relative;
        right: 12px;
    }

    .client-form-section {
        .MuiFormControl-root {
            margin-top: 0;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a.blacklist-edit-link {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .actions {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;

        .submit {
            margin-left: 12px;
        }
    }
`;

const spinnerSize = 20;
const spinnerThickness = 2;

function isNonBlankString(value: string) {
    return value && !value.match(/^\s*$/g);
}

interface ClientFormState {
    name: string;
    accountManagerId: string;
    linkedinUrl: string;
    globalBlacklistForcedTill: number;
    links: string[];
    blacklistedLinks: Array<{ link: string; current: boolean; past: boolean }>;
    hiringManagers: HiringManagerData[];
    autoFocusTextField: boolean;
    outreachDomain: string;
    domains: string[];
    errorText: {
        name: string;
        domains: Array<{ index: number; message: string }>;
        hiringManagers: Array<{ index: number; message: string }>;
        outreachDomain: string;
    };
    description: string;
    founders: string;
    funding: string;
    hq: string;
    salesPitch: string;
    teamSize: string;
    foundedDate: string;
    contracts: Array<{
        deleted: boolean;
        key: string;
        size: number;
    }>;
    blacklistDateDialogOpen: boolean;
}

interface ConnectedProps {
    isCreating: boolean;
    isFetching: boolean;
    pendingRequests: Map<string, RequestErrors>;
    errors: RequestErrors;
    clients: List<Client>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    createNewClient: (params: Partial<Client>) => void;
    fetchClientById: (clientId: string) => void;
    updateClient: (clientId: string, updates: Partial<Client>) => void;
    uploadClientPersonBlacklist: (clientId: string, personBlacklistFiles: string[]) => void;
}

interface OwnProps {
    clientId: string;
    onClose?: () => void;
}

type ClientFormProps = OwnProps & ConnectedProps & ConnectedDispatch;

class ClientFormComponent extends React.Component<ClientFormProps, ClientFormState> {
    constructor(props: ClientFormProps) {
        super(props);
        this.state = this.getInitialState(props);
    }

    componentDidMount() {
        this.ensureDataExists(this.props);
    }

    ensureDataExists(props: ClientFormProps) {
        if (props.clientId && (!props.clients.initialized || !props.clients.list.get(props.clientId))) {
            props.fetchClientById(props.clientId);
        }
    }

    getInitialState = (props: ClientFormProps): ClientFormState => {
        const { clientId, clients } = props;
        const client = clientId ? clients.list.get(clientId) : null;
        const defaults: Partial<Client> = {
            blacklistedLinks: [],
            contracts: [],
            description: '',
            domains: [''],
            foundedDate: '',
            founders: '',
            funding: '',
            globalBlacklistForcedTill: undefined,
            hiringManagers: [],
            hq: '',
            linkedinUrl: '',
            links: [],
            name: '',
            outreachDomain: 'getrocket.ai',
            salesPitch: '',
            teamSize: ''
        };
        const data = Object.assign({}, defaults, client) as Client;
        const links = _.uniq(_.filter([...data.links, data.linkedinUrl]).map(standardizeBlacklistEntry));
        data.domains = data.domains.length > 0 ? data.domains : [''];
        const blacklistedLinks =
            data.blacklistedLinks.length === 0 ? [{ link: '', current: true, past: true }] : data.blacklistedLinks;
        return Object.assign({}, data, {
            autoFocusTextField: false,
            blacklistDateDialogOpen: false,
            blacklistedLinks,
            errorText: {
                domains: [],
                hiringManagers: [],
                linkedinUrl: null,
                name: null,
                outreachDomain: null
            },
            links
        });
    };

    componentDidUpdate(prevProps: ClientFormProps, prevState: ClientFormState) {
        if (prevProps.clientId !== this.props.clientId) {
            this.ensureDataExists(this.props);
        }
        if (prevProps.isFetching && !this.props.isFetching && !this.props.errors) {
            this.setState(this.getInitialState(this.props)); // reset to initial state
        }
        if (
            prevState.blacklistedLinks.length === this.state.blacklistedLinks.length - 1 ||
            prevState.links.length === this.state.links.length - 1
        ) {
            this.setState({ autoFocusTextField: true });
        } else if (
            prevState.autoFocusTextField &&
            (prevState.blacklistedLinks.length === this.state.blacklistedLinks.length ||
                prevState.links.length === this.state.links.length)
        ) {
            this.setState({ autoFocusTextField: false });
        }

        if (prevProps.isCreating && !this.props.isCreating) {
            if (this.props.onClose) this.props.onClose();
        }
    }

    getClientData = (): Partial<Client> => {
        const {
            name,
            accountManagerId,
            linkedinUrl,
            globalBlacklistForcedTill,
            hiringManagers,
            links,
            blacklistedLinks,
            outreachDomain,
            domains,
            description,
            founders,
            funding,
            hq,
            salesPitch,
            teamSize,
            foundedDate,
            contracts
        } = this.state;
        return {
            accountManagerId,
            blacklistedLinks: _.uniqWith(
                blacklistedLinks
                    .filter((l) => isNonBlankString(l.link))
                    .map((l) => Object.assign({}, l, { link: standardizeBlacklistEntry(l.link) })),
                this.uniqBlacklistLinks
            ),
            contracts,
            description,
            domains: domains.map((d) => d.trim()).filter(isNonBlankString),
            foundedDate,
            founders,
            funding,
            globalBlacklistForcedTill,
            hiringManagers,
            hq,
            linkedinUrl,
            links: _.uniq([linkedinUrl].concat(links).filter(isNonBlankString).map(standardizeBlacklistEntry)),
            name: name.trim(),
            outreachDomain: outreachDomain.trim(),
            salesPitch,
            teamSize
        };
    };

    userFilter = (user: UserData) => {
        return user.status === 'active';
    };

    uniqBlacklistLinks = (
        l1: { link: string; current: boolean; past: boolean },
        l2: { link: string; current: boolean; past: boolean }
    ) => {
        return l1.link === l2.link && l1.current === l2.current && l1.past === l2.past;
    };

    handleSubmit = () => {
        if (this.hasValidClientData()) {
            const data = this.getClientData();
            if (this.props.clientId) {
                this.props.updateClient(this.props.clientId, data);
            } else {
                this.props.createNewClient(data);
            }
        }
    };

    handleAddBlacklistLink = () => {
        const blacklistedLinks = _.uniqWith(
            [{ link: '', current: true, past: true }].concat(
                this.state.blacklistedLinks
                    .filter((l) => isNonBlankString(l.link))
                    .map((l) => ({ ...l, link: standardizeBlacklistEntry(l.link) }))
            ),
            this.uniqBlacklistLinks
        );
        this.setState({ blacklistedLinks });
    };

    handleAddClientLink = () => {
        const links = _.uniq(
            _.filter([...this.state.links, this.state.linkedinUrl]).map(standardizeBlacklistEntry)
        ).concat(['']);
        this.setState({ links });
    };

    handleAddClientDomain = () => {
        const domains = _.uniq([''].concat(this.state.domains.filter(isNonBlankString)));
        this.setState({ domains });
    };

    handleFieldChange = (field: keyof ClientFormState) => (event: { target: { value: any } }) => {
        let value = event.target.value;
        if (field === 'linkedinUrl') {
            const strVal = value as string;
            value =
                'https://' +
                standardizeUrl(strVal?.trim())?.replace(/(?<!\/recruiter)\/company\//, '/recruiter/company/');
        }
        this.setState({ [field]: value } as any);
    };

    handleAMChange = (accountManagerId: string) => {
        this.setState({ accountManagerId });
    };

    handleEditorChange = <T extends keyof ClientFormState>(field: T) => (value: ClientFormState[T]) => {
        this.setState({ [field]: value } as any);
    };

    handleClientLinksChange = (index: number) => (event: { target: { value: string } }) => {
        const value = event.target.value;
        const links = this.state.links.slice(0);
        links[index] = value;
        this.setState({ links });
    };

    handleBlacklistedLinkChange = (index: number) => (event: { target: { value: string } }) => {
        const link = event.target.value;
        const blacklistedLinks = this.state.blacklistedLinks.slice(0);
        blacklistedLinks[index] = Object.assign({}, this.state.blacklistedLinks[index], { link });
        this.setState({ blacklistedLinks });
    };

    handleBlacklistedRadioChange = (index: number) => (event: { target: { value: string } }) => {
        const radioValue = event.target.value;
        const blacklistedLinks = this.state.blacklistedLinks.slice(0);
        const current = radioValue !== 'past';
        const past = radioValue !== 'current';
        blacklistedLinks[index] = Object.assign({}, this.state.blacklistedLinks[index], { current, past });
        this.setState({ blacklistedLinks });
    };

    handleClientDomainChange = (index: number) => (event: { target: { value: string } }) => {
        const value = event.target.value;
        const domains = this.state.domains.slice(0);
        domains[index] = value;
        this.setState({ domains });
    };

    readFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const data = reader.result as string;
                resolve(data);
            };
            reader.onabort = () => reject(new Error('file reading was aborted'));
            reader.onerror = () => reject(new Error('file reading has failed'));
            reader.readAsText(file);
        });
    };

    handleDrop = (files: File[]) => {
        Promise.all(files.map((f) => this.readFile(f))).then((result) => {
            this.props.uploadClientPersonBlacklist(this.props.clientId, result);
        });
    };

    handleKeyPress = (field: 'links' | 'blacklistedLinks' | 'domains', index: number) => (
        e: React.KeyboardEvent<{}>
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleStandardizeEntry(field, index)();
            if (field === 'links') {
                this.handleAddClientLink();
            } else if (field === 'blacklistedLinks') {
                this.handleAddBlacklistLink();
            } else {
                this.handleAddClientDomain();
            }
        }
    };

    handleKeyDown = (field: 'links' | 'blacklistedLinks' | 'domains', index: number) => (
        e: React.KeyboardEvent<{}>
    ) => {
        if (e.key === 'Backspace') {
            if (field === 'links') {
                if (this.state.links[index].length === 0 && this.state.links.length > 1) {
                    e.preventDefault();
                    const links = [...this.state.links.slice(0, index), ...this.state.links.slice(index + 1)];
                    this.setState({ links });
                }
            } else if (field === 'blacklistedLinks') {
                if (this.state.blacklistedLinks[index].link.length === 0 && this.state.blacklistedLinks.length > 1) {
                    e.preventDefault();
                    const blacklistedLinks = [
                        ...this.state.blacklistedLinks.slice(0, index),
                        ...this.state.blacklistedLinks.slice(index + 1)
                    ];
                    this.setState({ blacklistedLinks });
                }
            } else if (field === 'domains') {
                if (this.state.domains[index].length === 0 && this.state.domains.length > 1) {
                    e.preventDefault();
                    const domains = [...this.state.domains.slice(0, index), ...this.state.domains.slice(index + 1)];
                    this.setState({ domains });
                }
            }
        }
    };

    handleStandardizeEntry = (field: 'links' | 'blacklistedLinks' | 'domains', index: number) => () => {
        if (field === 'blacklistedLinks') {
            this.handleBlacklistedLinkChange(index)({
                target: { value: standardizeBlacklistEntry(this.state.blacklistedLinks[index].link) }
            });
        } else if (field === 'links') {
            this.handleClientLinksChange(index)({
                target: {
                    value: standardizeBlacklistEntry(this.state.links[index])
                }
            });
        } else if (field === 'domains') {
            this.handleClientDomainChange(index)({ target: { value: this.state.domains[index] } });
        }
    };

    handleHiringManagerChange = (hiringManager: HiringManagerData, index: number) => {
        if (!_.isNil(index)) {
            this.setState({
                hiringManagers: _.orderBy(
                    this.state.hiringManagers
                        .slice(0, index)
                        .concat([hiringManager])
                        .concat(this.state.hiringManagers.slice(index + 1)),
                    'email'
                )
            });
        } else {
            this.setState({
                hiringManagers: _.orderBy(this.state.hiringManagers.concat([hiringManager]), 'email')
            });
        }
    };

    handleRemoveHiringManager = (index: number) => {
        this.setState({
            hiringManagers: _.orderBy(
                this.state.hiringManagers.slice(0, index).concat(this.state.hiringManagers.slice(index + 1)),
                'email'
            )
        });
    };

    handleToggleBlacklistDateSelectDialog = () => {
        this.setState({ blacklistDateDialogOpen: !this.state.blacklistDateDialogOpen });
    };

    handleBlacklistedTillChange = (d: Date) => {
        this.setState({ globalBlacklistForcedTill: d.getTime(), blacklistDateDialogOpen: false });
    };

    hasValidClientData = () => {
        const clientData = this.getClientData();
        const { domains, name, outreachDomain, accountManagerId } = clientData;

        const errorText: {
            name: string;
            accountManager: string;
            outreachDomain: string;
            domains: Array<{ index: number; message: string }>;
            hiringManagers: Array<{ index: number; message: string }>;
        } = {
            accountManager: null,
            domains: [],
            hiringManagers: [],
            name: null,
            outreachDomain: null
        };

        if (name.trim().length === 0) {
            errorText.name = 'Name cannot be empty';
        }
        if (accountManagerId === null) {
            errorText.accountManager = 'Account manager must be set';
        }
        if (outreachDomain.trim().length === 0) {
            errorText.outreachDomain = 'Outreach domain cannot be empty';
        } else if (!isValidEmailDomain(outreachDomain)) {
            errorText.outreachDomain = 'Not a valid outreach domain';
        }

        for (let i = 0; i < domains.length; i += 1) {
            if (!isValidEmailDomain(domains[i])) {
                errorText.domains.push({ index: i, message: 'Not a valid domain' });
            }
        }

        this.setState({ errorText });
        return (
            !errorText.name &&
            !errorText.outreachDomain &&
            errorText.domains.length === 0 &&
            errorText.hiringManagers.length === 0
        );
    };

    render() {
        const { clientId, clients, isCreating, pendingRequests, userPermissions } = this.props;
        const {
            accountManagerId,
            autoFocusTextField,
            name,
            blacklistedLinks,
            blacklistDateDialogOpen,
            globalBlacklistForcedTill,
            hiringManagers,
            links,
            linkedinUrl
        } = this.state;

        if (!!clientId && !clients.initialized) {
            return null;
        }

        const isEditable = hasRole(userPermissions, 'client_creator');
        const isDisabled = isCreating || !isEditable;
        const submitAction = isEditable ? (
            <Button
                className="submit"
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                disabled={isCreating}
            >
                {isCreating ? 'Saving' : 'Save'}
            </Button>
        ) : null;

        const cancelAction =
            isEditable && this.props.onClose ? (
                <Button variant="contained" onClick={this.props.onClose} disabled={isCreating}>
                    Cancel
                </Button>
            ) : (
                <Button variant="contained">Cancel</Button>
            );

        const linksFields = links.map((link, index) => {
            const helperText =
                !isDisabled && index === links.length - 1 && link.length > 0 ? 'Press Enter to add another' : '';
            return (
                <TextField
                    key={index}
                    fullWidth={true}
                    value={link}
                    placeholder="Add a name or a link"
                    onChange={this.handleClientLinksChange(index)}
                    onBlur={this.handleStandardizeEntry('links', index)}
                    onKeyPress={this.handleKeyPress('links', index)}
                    onKeyDown={this.handleKeyDown('links', index)}
                    autoFocus={autoFocusTextField && index === 0}
                    helperText={helperText}
                />
            );
        });
        const clientLinksSection = (
            <div className="client-form-section">
                <div className="form-settings-label">Client name variations and links</div>
                {linksFields}
            </div>
        );
        let blacklistInfo;
        if (!globalBlacklistForcedTill) {
            blacklistInfo = (
                <a className="blacklist-edit-link" onClick={this.handleToggleBlacklistDateSelectDialog}>
                    ON
                </a>
            );
        } else if (globalBlacklistForcedTill > Date.now()) {
            blacklistInfo = (
                <a className="blacklist-edit-link" onClick={this.handleToggleBlacklistDateSelectDialog}>
                    {fullDate(globalBlacklistForcedTill)}
                </a>
            );
        } else {
            blacklistInfo = (
                <a className="blacklist-edit-link" onClick={this.handleToggleBlacklistDateSelectDialog}>
                    OFF
                </a>
            );
        }
        const clientGlobalBlacklistSection = (
            <div className="client-form-section">
                <div className="form-settings-label">Global Blacklist</div>
                <div>{blacklistInfo}</div>
            </div>
        );

        let blacklistDatePicker;
        if (blacklistDateDialogOpen) {
            const selected = globalBlacklistForcedTill > Date.now() ? new Date(globalBlacklistForcedTill) : new Date();
            blacklistDatePicker = (
                <Dialog open={true} onClose={this.handleToggleBlacklistDateSelectDialog}>
                    <DayPicker
                        mode="single"
                        selected={selected}
                        defaultMonth={selected}
                        onSelect={this.handleBlacklistedTillChange}
                    />
                </Dialog>
            );
        }

        const blacklistFields = blacklistedLinks.map((link, index) => {
            const helperText =
                !isDisabled && index === blacklistedLinks.length - 1 && link.link.length > 0
                    ? 'Press Enter to add another'
                    : '';
            return (
                <div className="client-blacklist-row" key={index}>
                    <div className="link">
                        <TextField
                            disabled={isDisabled}
                            fullWidth={true}
                            value={link.link}
                            placeholder="Add a name or a link"
                            onKeyPress={this.handleKeyPress('blacklistedLinks', index)}
                            onKeyDown={this.handleKeyDown('blacklistedLinks', index)}
                            onChange={this.handleBlacklistedLinkChange(index)}
                            onBlur={this.handleStandardizeEntry('blacklistedLinks', index)}
                            autoFocus={autoFocusTextField && index === 0}
                            helperText={helperText}
                        />
                    </div>
                    <RadioGroup
                        className="radio"
                        value={link.current ? (link.past ? 'all' : 'current') : 'past'}
                        onChange={this.handleBlacklistedRadioChange(index)}
                    >
                        <Radio color="primary" value="all" className="radio-button" disabled={isDisabled} />
                        <Radio color="primary" value="current" className="radio-button" disabled={isDisabled} />
                        <Radio color="primary" value="past" className="radio-button" disabled={isDisabled} />
                    </RadioGroup>
                </div>
            );
        });

        const blacklistSection = (
            <div className="client-form-section">
                <div className="client-blacklist-row header text-field form-settings-label">
                    <div className="link"> Client-specific blacklist</div>
                    <div className="radio">
                        <div className="radio-button">All</div>
                        <div className="radio-button">Current</div>
                        <div className="radio-button">Past</div>
                    </div>
                </div>
                <div className="delete-icon" />
                {blacklistFields}
            </div>
        );

        const getDropzoneSection = (
            getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
            getInputProps: <T extends DropzoneInputProps>(props?: T) => T
        ) => {
            return (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} disabled={isDisabled || isUploading} />
                        <ContentAdd />
                    </div>
                </section>
            );
        };

        const clientPersonBlacklistLength = clientId ? clients.list.get(clientId)?.personBlacklist.length : null;
        const isUploading = pendingRequests.has(`upload-client-person-blacklist-${clientId}`);
        const uploadPersonBlacklistButton = isUploading ? (
            <CircularProgress size={spinnerSize} thickness={spinnerThickness} />
        ) : (
            <Dropzone onDrop={this.handleDrop}>
                {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
            </Dropzone>
        );
        const personBlacklistSection = clientId ? (
            <div className="client-form-section">
                <div className="form-settings-label">Person Blacklist</div>
                <div className="client-blacklist-row">
                    <div>Currently {clientPersonBlacklistLength} persons blacklisted.</div>
                    {uploadPersonBlacklistButton}
                </div>
            </div>
        ) : null;

        const hiringManagerSection = (
            <div className="client-form-section">
                <div className="form-settings-label">Hiring Managers</div>
                <ClientHiringManagers
                    clientDomains={this.state.domains.map((d) => d.trim())}
                    hiringManagers={hiringManagers}
                    onHiringManagerChange={this.handleHiringManagerChange}
                    onHiringManagerRemove={this.handleRemoveHiringManager}
                    isEditable={isEditable}
                    errors={this.state.errorText.hiringManagers}
                />
            </div>
        );

        const clientEmailDomainFields = this.state.domains.map((domain, index) => {
            const isLastField = !isDisabled && index === this.state.domains.length - 1 && domain.length > 0;
            const error = this.state.errorText.domains.find((e) => e.index === index);
            const errorText = error ? error.message : isLastField ? 'Press Enter to add another' : '';

            return (
                <TextField
                    key={index}
                    fullWidth={true}
                    value={domain}
                    placeholder="Add a client domain"
                    onChange={this.handleClientDomainChange(index)}
                    onKeyPress={this.handleKeyPress('domains', index)}
                    onKeyDown={this.handleKeyDown('domains', index)}
                    autoFocus={autoFocusTextField && index === 0}
                    error={!!error}
                    helperText={errorText}
                    disabled={isDisabled}
                />
            );
        });
        const clientDomainsSection = (
            <div className="client-form-section">
                <div className="form-settings-label">Client Email Domains</div>
                {clientEmailDomainFields}
            </div>
        );

        const loadingIndicator = isCreating ? (
            <div className="client-form-loading">
                <Loading />
            </div>
        ) : null;

        return (
            <div css={styles}>
                {loadingIndicator}
                <TextField
                    disabled={isDisabled}
                    label="Name"
                    error={!!this.state.errorText.name}
                    InputLabelProps={{ shrink: true }}
                    helperText={this.state.errorText.name}
                    value={name}
                    fullWidth={true}
                    onChange={this.handleFieldChange('name')}
                />
                <UserSelector
                    onSelect={this.handleAMChange}
                    disabled={isCreating || !isEditable}
                    value={accountManagerId}
                    fullWidth={true}
                    label="Account Manager"
                    userFilter={this.userFilter}
                    includeNoneOpt={false}
                />
                <TextField
                    disabled={isDisabled}
                    label="LinkedIn Recruiter URL"
                    InputLabelProps={{ shrink: true }}
                    value={linkedinUrl || ''}
                    fullWidth={true}
                    onChange={this.handleFieldChange('linkedinUrl')}
                />
                {clientDomainsSection}
                {clientLinksSection}
                {clientGlobalBlacklistSection}
                {blacklistSection}
                {personBlacklistSection}
                {hiringManagerSection}
                <TextField
                    disabled={isDisabled}
                    fullWidth={true}
                    label="HQ Location"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.hq}
                    onChange={this.handleFieldChange('hq')}
                />
                <TextField
                    disabled={isDisabled}
                    fullWidth={true}
                    label="Founders"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.founders}
                    onChange={this.handleFieldChange('founders')}
                />
                <TextField
                    disabled={isDisabled}
                    fullWidth={true}
                    label="Funding"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.funding}
                    onChange={this.handleFieldChange('funding')}
                />
                <TextField
                    disabled={isDisabled}
                    fullWidth={true}
                    label="Team Size"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.teamSize}
                    onChange={this.handleFieldChange('teamSize')}
                />
                <TextField
                    disabled={isDisabled}
                    fullWidth={true}
                    label="Founded Date"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.foundedDate}
                    onChange={this.handleFieldChange('foundedDate')}
                />
                <Editor
                    value={this.state.description}
                    onChange={this.handleEditorChange('description')}
                    label="Company Description"
                    className="job-details-text-editor"
                    disabled={isDisabled}
                />
                <TextField
                    disabled={isDisabled}
                    fullWidth={true}
                    multiline={true}
                    label="Sales Pitch"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.salesPitch}
                    onChange={this.handleFieldChange('salesPitch')}
                />
                <br />
                <ClientContracts clientId={clientId} />

                <div className="actions">
                    {cancelAction}
                    {submitAction}
                </div>

                {blacklistDatePicker}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    errors: state.clients.formErrors,
    isCreating: state.clients.isCreating,
    isFetching: state.clients.isFetching,
    pendingRequests: state.pendingRequests,
    userPermissions: state.session.userPermissions
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    createNewClient,
    fetchClientById,
    updateClient,
    uploadClientPersonBlacklist
};

export const ClientForm = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ClientFormComponent);
