import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Map, OrderedMap, OrderedSet } from 'immutable';
import { CircularProgress, RaisedButton } from 'material-ui';
import { AvSkipNext } from 'material-ui/svg-icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { checkSendAccess } from 'shared/common/email-access';
import { getMediaFileKey } from 'shared/common/file-storage';
import { interpolateEmailTemplate } from 'shared/common/interpolate-template';
import { getSchedulingLink } from 'shared/common/scheduling-link-utils';
import { EmailAccountView } from 'shared/models/email-account';
import { EmailTemplateView } from 'shared/models/email-templates';
import { hasFlag, JOB_FLAG_INTRO_EMAILS_SUPPORTED } from 'shared/models/job';
import {
    awaitingClientStage,
    beingScheduledStage,
    clientFirstRoundStage,
    JobStageData,
    responseReceivedStage,
    rocketScreenCompleteStage,
    rocketScreenScheduledStage
} from 'shared/models/job-stages';
import { NoteView } from 'shared/models/note';
import { Permissions } from 'shared/models/permission';
import { maxProfileAgeResponseReceivedInDays } from 'shared/models/profile';
import { getSequenceVariables, SequenceVariables } from 'shared/models/sequence';
import { hasRole, UserData } from 'shared/models/user';

import { EmailAddress } from 'shared/types/email-compose';
import { FilePayload } from 'shared/types/file-payload';

import {
    fetchJobInfo,
    fetchJobsData,
    fetchPersonContacts,
    fetchUsers,
    getAllEmailAccountInfo,
    getEmailTemplates,
    getJobEmails,
    moveCandidateToStage,
    sendSequenceToPerson,
    showModalAlert
} from '../actions';
import { getAccountOptions } from '../common/email/account-options';
import { EmailKind } from '../common/email/header-validator';
import { getEmailForOutreach } from '../common/person';
import {
    composeEmailWithValidation,
    EmailValidationData,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount
} from '../email-compose/actions';
import { ComposeEmailWindowData } from '../email-compose/types';
import { getLastCommunicationWithCandidate } from '../lib/last-communication-with-candidate';
import { MandatoryWarning } from '../sfc/mandatory-warning';
import {
    AvailableNotes,
    Candidate,
    Client,
    Communication,
    Contact,
    EmailAccount,
    Job,
    List,
    ListState,
    Person,
    PersonDetails,
    RequestErrors,
    State
} from '../state';
import { IntroCandidateClientPopover } from './intro-candidate-client-popover';
import { SchedulingSendDialog } from './scheduling-send-dialog';
import { SchedulingSequenceMenuOption } from './scheduling-sequence-menu-option';
import { SubmitToClientPopover } from './submit-to-client-popover';

const voyagerId = 'l5kpzgr1'; // cspell:disable-line
const minNoteLength = 250;
const oneDayInMilliseconds = 86400000;

interface OwnProps {
    candidate: Candidate;
}

interface ConnectedProps {
    emailContentBlacklistedDomains: string;
    clients: List<Client>;
    communications: Map<string, Communication[]>;
    jobs: OrderedMap<string, Job>;
    jobEmails: Map<string, EmailAccount[]>;
    notes: Map<string, OrderedSet<NoteView>>;
    pendingRequests: Map<string, RequestErrors>;
    persons: List<Person>;
    emailAccounts: Map<string, EmailAccount>;
    jobStages: JobStageData[];
    contacts: Map<string, Contact[]>;
    users: Map<string, UserData>;
    listsState: Map<string, ListState>;
    emailTemplates: Map<string, EmailTemplateView[]>;
    sessionUser: UserData;
    details: List<PersonDetails>;
    userPermissions: Permissions;
}

interface ConnectedDispatch {
    getJobEmails: (jobId: string) => void;
    moveCandidateToStage: (candidate: Candidate, stage: string) => void;
    sendSequenceToPerson: (
        personId: string,
        jobId: string,
        account: string,
        sequenceId: string,
        variables: SequenceVariables,
        customStages: Array<{ subject: string; body: string }>
    ) => void;
    fetchJobInfo: (jobId: string) => void;
    getAllEmailAccountInfo: () => void;
    composeEmailWithValidation: (
        data: ComposeEmailWindowData,
        emailKind: EmailKind,
        validationData: EmailValidationData
    ) => void;
    replyToEmailWithValidation: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        archiveOnReply?: boolean,
        composeWindowData?: Partial<ComposeEmailWindowData>,
        initialBody?: string
    ) => void;
    replyToEmailWithValidationWithDifferentAccount: (
        accountOptions: EmailAddress[],
        emailContentBlacklistedDomains: string,
        message: Communication,
        newAccount: EmailAddress,
        replyAll: boolean,
        emailKind: EmailKind,
        validationData: EmailValidationData,
        composeWindowData?: Partial<ComposeEmailWindowData>
    ) => void;
    fetchPersonContacts: (personId: string) => void;
    getEmailTemplates: (group: string) => void;
    fetchUsers: () => void;
    fetchJobsData: () => void;
    showModalAlert: (description: string | JSX.Element, title: string) => void;
}

type MoveCandidateForwardButtonProps = OwnProps & ConnectedProps & ConnectedDispatch;

interface MoveCandidateForwardButtonState {
    schedulingSequencePreview: {
        initialCustomStages: Array<{ subject: string; body: string }>;
        open: boolean;
        account: string;
        variables: SequenceVariables;
    };
    optionsOpen: boolean;
    warningDialogOpen: boolean;
    warningDialogReason: AvailableNotes | 'visa-required';
    isInTimeout: boolean;
}

class MoveCandidateForwardButtonComponent extends React.Component<
    MoveCandidateForwardButtonProps,
    MoveCandidateForwardButtonState
> {
    private anchorRef: React.RefObject<HTMLDivElement>;

    constructor(props: MoveCandidateForwardButtonProps) {
        super(props);
        this.state = {
            isInTimeout: false,
            optionsOpen: false,
            schedulingSequencePreview: {
                account: undefined,
                initialCustomStages: [],
                open: false,
                variables: undefined
            },
            warningDialogOpen: false,
            warningDialogReason: null
        };
        this.anchorRef = React.createRef();
        this.ensureDataExists(props);
    }

    ensureDataExists = (props: MoveCandidateForwardButtonProps) => {
        const { candidate, jobs, jobEmails, emailAccounts, listsState, emailTemplates, contacts } = props;
        const { jobId, personId } = candidate;
        const job = jobs.get(jobId);
        if (!job) {
            props.fetchJobInfo(jobId);
        }
        if (!jobEmails.get(jobId)) {
            props.getJobEmails(jobId);
        }
        if (emailAccounts.isEmpty()) {
            props.getAllEmailAccountInfo();
        }

        if (!emailTemplates.get(rocketScreenCompleteStage)) {
            props.getEmailTemplates(rocketScreenCompleteStage);
        }
        if (listsState.get('users') !== 'initialized') {
            props.fetchUsers();
        }
        if (!contacts.get(personId)) {
            props.fetchPersonContacts(personId);
        }
        if (listsState.get('jobs') !== 'initialized') {
            props.fetchJobsData();
        }
    };

    componentDidUpdate() {
        this.ensureDataExists(this.props);
    }

    checkCanReplyToEmail() {
        const { candidate, sessionUser, details, jobStages, userPermissions } = this.props;
        const currentStage = jobStages.find((s) => s.name === candidate.stage);

        if (currentStage.nextStage === rocketScreenCompleteStage) {
            const { account, lastEmail } = this.getLastEmail();
            let jobs: Job[];
            let personCandidates: Candidate[];
            if (lastEmail) {
                const jobIds = lastEmail.jobIds || [];
                jobs = jobIds.map((j) => this.props.jobs.get(j));
                personCandidates = details.list
                    .get(candidate.personId)
                    .candidates.filter((c) => jobIds.includes(c.jobId));
            } else {
                const job = this.props.jobs.get(candidate.jobId);
                jobs = [job];
                personCandidates = [candidate];
            }
            if (account) {
                return checkSendAccess(sessionUser, userPermissions, account, {
                    jobs,
                    personsCandidates: personCandidates
                });
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    doesCandidateHaveNotes = (props: MoveCandidateForwardButtonProps) => {
        const { candidate, notes } = props;
        const notesList = notes.get(`persons-${candidate.personId}`);
        const longNotes = notesList
            ? notesList.filter(
                  (note) =>
                      note.createdBy !== voyagerId &&
                      (note.content.length >= minNoteLength || note.context.isPhonescreenTemplate)
              )
            : null;
        if (!longNotes || longNotes.isEmpty()) {
            return AvailableNotes.NoNotes;
        } else {
            const notesForJob = longNotes.filter((note) => note.context && note.context.jobId === candidate.jobId);
            if (notesForJob.isEmpty()) {
                return AvailableNotes.NotesForOtherJob;
            } else {
                return AvailableNotes.NotesForThisJob;
            }
        }
    };

    getLastEmail = () => {
        const { candidate, jobs, emailAccounts, communications, users } = this.props;
        const { jobId, personId } = candidate;
        const job = jobs.get(jobId);
        const personCommunications = communications.get(personId);
        if (!personCommunications || !job || emailAccounts.isEmpty()) {
            return {};
        }
        const lastEmail = getLastCommunicationWithCandidate(personCommunications, emailAccounts, candidate);
        let account: EmailAccountView;
        if (lastEmail) {
            const lastEmailAccount = emailAccounts.get(lastEmail.account);
            if (lastEmailAccount.syncStatus === 'enabled') {
                account = lastEmailAccount;
            } else if (lastEmailAccount.syncStatus === 'archived') {
                const assignedUser = users.find((u) => u.id === candidate.assignee);
                const accountOptions = getAccountOptions(emailAccounts, assignedUser, undefined);
                account = emailAccounts.get(accountOptions?.[0]?.address);
            } else {
                account = undefined;
            }
        }
        return { lastEmail, account };
    };

    handleProceed = (stage?: string) => (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();
        this.moveCandidate(stage);
        const timeout = 1000;
        this.setState({ isInTimeout: true, optionsOpen: false });
        setTimeout(() => {
            this.setState({ isInTimeout: false });
        }, timeout);
    };

    handleSendSequenceMenuClick = (alternateTimeSequence: boolean) => () => {
        const { lastEmail, account } = this.getLastEmail();
        if (!account) {
            this.setState({
                optionsOpen: false
            });
            this.props.showModalAlert(
                'No emails found for current job. Cannot send scheduling sequence.',
                'Cannot Send Sequence'
            );
        } else {
            const { persons, candidate, clients, jobs, users } = this.props;
            const assigneeUser = users.get(candidate.assignee);
            const client = clients.list.get(jobs.get(candidate.jobId).clientId);
            const customText = alternateTimeSequence
                ? `Unfortunately, I am not available at your proposed time but I'd love to find a time when we're free to connect.`
                : '';
            const subject = lastEmail.subject?.match(/^Re:/i) ? lastEmail.subject : `Re: ${lastEmail.subject ?? ''}`;
            const variables = getSequenceVariables({
                client,
                customText,
                job: {
                    id: candidate.jobId
                },
                person: {
                    id: candidate.personId,
                    name: persons.list.get(candidate.personId).name
                },
                phoneCallSchedulingLink: assigneeUser.calendarLink,
                senderAccount: account,
                subject,
                user: assigneeUser
            });
            const initialCustomStages = lastEmail.aiDraftedReply
                ? [
                      {
                          body: lastEmail.aiDraftedReply,
                          subject
                      }
                  ]
                : [];
            this.setState({
                optionsOpen: false,
                schedulingSequencePreview: { open: true, variables, account: account.email, initialCustomStages }
            });
        }
    };

    handleOpenCalendarClick = (schedulingLink: string) => () => {
        const width = 1280;
        const height = 800;
        const pixelOffset = 50;
        const top = window.screenY + pixelOffset;
        const left = window.screenX + pixelOffset;

        const { personId, jobId } = this.props.candidate;
        const client = this.props.clients.list.get(this.props.jobs.get(jobId).clientId);
        const { link: modifiedSchedulingLink } = getSchedulingLink(schedulingLink, personId, jobId, client.name);
        window.open(
            modifiedSchedulingLink,
            '_blank',
            `location=no, toolbar=no, menubar=no,\
             height=${height}, width=${width}, top=${top}, left=${left}`
        );
        this.setState({ optionsOpen: false });
    };

    handleOptionsOpen = () => {
        this.setState({ optionsOpen: true });
    };

    handleOptionsClose = () => {
        this.setState({ optionsOpen: false });
    };

    hideNoteWarningDialog = () => {
        this.setState({ warningDialogOpen: false });
    };

    hidePhoneCallScheduling = () => {
        this.setState({
            schedulingSequencePreview: {
                account: undefined,
                initialCustomStages: [],
                open: false,
                variables: undefined
            }
        });
    };

    handleSendSequence = (data: { sequenceId: string; customStages: Array<{ subject: string; body: string }> }) => {
        const { account, variables } = this.state.schedulingSequencePreview;
        const { sequenceId, customStages } = data;
        this.props.moveCandidateToStage(this.props.candidate, beingScheduledStage);
        this.props.sendSequenceToPerson(
            this.props.candidate.personId,
            this.props.candidate.jobId,
            account,
            sequenceId,
            variables,
            customStages
        );
        this.setState({
            schedulingSequencePreview: {
                account: undefined,
                initialCustomStages: [],
                open: false,
                variables: undefined
            }
        });
    };

    moveCandidate = (stage: string) => {
        const {
            candidate,
            jobs,
            users,
            emailAccounts,
            clients,
            persons,
            emailTemplates,
            contacts,
            sessionUser
        } = this.props;
        const { jobId, personId } = candidate;
        const job = jobs.get(jobId);
        const personContacts = contacts.get(personId);
        const person = persons.list.get(personId);
        if (stage === rocketScreenCompleteStage && candidate.stage === rocketScreenScheduledStage) {
            if (!hasRole(this.props.userPermissions, 'skip_candidate_reply')) {
                if (job.phoneCallRequired) {
                    const notesStatus = this.doesCandidateHaveNotes(this.props);
                    if (notesStatus !== AvailableNotes.NotesForThisJob) {
                        this.setState({ warningDialogOpen: true, warningDialogReason: notesStatus });
                        return;
                    }
                }
                if (job.visaStatusRequired && person.visaStatusSource !== 'user_input') {
                    this.setState({ warningDialogOpen: true, warningDialogReason: 'visa-required' });
                    return;
                }
            }
            this.props.moveCandidateToStage(candidate, stage);
            if (!this.checkCanReplyToEmail()) {
                return;
            }
            const templateName = 'Rocket Screen Followup';
            const template = emailTemplates.get(rocketScreenCompleteStage).find((t) => t.kind === templateName);
            const client = clients.list.get(job.clientId);
            const assigneeUser = users.get(candidate.assignee);
            const validationData: EmailValidationData = {
                client,
                emailAccounts: emailAccounts.valueSeq().toArray(),
                job,
                personContacts,
                users: users.valueSeq().toArray()
            };
            const emailKind: EmailKind = 'candidate';
            const attachments: FilePayload[] = job.onePager
                ? [
                      {
                          filename: job.onePager.key.split('/').reverse()[0],
                          key: getMediaFileKey(job.onePager.key),
                          size: job.onePager.size,
                          type: 's3Key'
                      }
                  ]
                : [];
            const commonComposeData = {
                attachments,
                canEditToRecipients: true,
                draftSavedAt: null as number,
                isClientComm: false,
                jobId,
                personId,
                windowId: `move-to-rsc-${personId}-${jobId}`
            };

            const { account: suggestedAccount, lastEmail } = this.getLastEmail();
            const defaultAccount =
                lastEmail && lastEmail.account === suggestedAccount.email
                    ? { address: lastEmail.account, name: lastEmail.accountInfo.name.full }
                    : suggestedAccount
                    ? { address: suggestedAccount.email, name: suggestedAccount.name.full }
                    : undefined;
            const accountOptions = getAccountOptions(emailAccounts, sessionUser, jobId, defaultAccount);
            if (lastEmail && lastEmail.account === defaultAccount.address) {
                const body = interpolateEmailTemplate(template.body, {
                    client,
                    job,
                    person,
                    senderAccount: emailAccounts.get(lastEmail.account),
                    senderName: assigneeUser.name,
                    user: assigneeUser
                });
                this.props.replyToEmailWithValidation(
                    accountOptions,
                    this.props.emailContentBlacklistedDomains,
                    lastEmail,
                    true,
                    emailKind,
                    validationData,
                    true,
                    commonComposeData,
                    body
                );
            } else if (lastEmail && lastEmail.account !== defaultAccount.address) {
                this.props.replyToEmailWithValidationWithDifferentAccount(
                    accountOptions,
                    this.props.emailContentBlacklistedDomains,
                    lastEmail,
                    defaultAccount,
                    true,
                    emailKind,
                    {
                        client,
                        emailAccounts: emailAccounts.valueSeq().toArray(),
                        job,
                        personContacts,
                        users: users.valueSeq().toArray()
                    },
                    {
                        canEditToRecipients: true,
                        isClientComm: lastEmail.isClientComm,
                        jobId: job ? job.id : undefined,
                        personId: person.id
                    }
                );
            } else {
                const account = job.primaryEmail
                    ? emailAccounts.get(job.primaryEmail)
                    : emailAccounts.get(accountOptions[0]?.address);
                const address = getEmailForOutreach(personContacts, personId, job.contactTypes);
                const to = address ? [{ name: person.name.full, address }] : [];
                const body = interpolateEmailTemplate(template.body, {
                    client,
                    job,
                    person,
                    senderAccount: account,
                    senderName: assigneeUser.name,
                    user: assigneeUser
                });
                this.props.composeEmailWithValidation(
                    {
                        ...commonComposeData,
                        account: { name: account.name.full, address: account.email },
                        accountOptions,
                        body,
                        headers: {
                            bcc: template.bcc,
                            cc: template.cc,
                            subject: template.subject,
                            to
                        },
                        threadId: null
                    },
                    emailKind,
                    validationData
                );
            }

            return;
        } else {
            this.props.moveCandidateToStage(candidate, stage);
        }
    };

    render() {
        const {
            candidate,
            clients,
            emailAccounts,
            emailTemplates,
            contacts,
            jobs,
            jobStages,
            listsState,
            pendingRequests,
            users,
            details
        } = this.props;
        const { schedulingSequencePreview } = this.state;
        const { personId, jobId } = candidate;
        const job = jobs.get(jobId);
        const { optionsOpen } = this.state;
        const currentStage = jobStages.find((stage) => stage.name === candidate.stage);
        const nextStage = jobStages.find((stage) => stage.name === currentStage.nextStage);
        const { lastEmail } = this.getLastEmail();

        let proceedOptions;
        let proceedForPhoneCallScheduling;

        const hasAllData =
            listsState.get('users') === 'initialized' &&
            listsState.get('jobs') === 'initialized' &&
            !emailAccounts.isEmpty() &&
            !!emailTemplates.get(rocketScreenCompleteStage) &&
            !!contacts.get(personId);

        if (candidate.stage === responseReceivedStage && job.phoneCallRequired) {
            const schedulingLink = users.find((u) => u.id === candidate.assignee).calendarLink;
            const mixmaxCalendarOption = schedulingLink ? (
                <MenuItem onClick={this.handleOpenCalendarClick(schedulingLink)}>Manually book time</MenuItem>
            ) : null;
            proceedForPhoneCallScheduling = schedulingSequencePreview.open ? (
                <SchedulingSendDialog
                    jobId={jobId}
                    initialCustomStages={schedulingSequencePreview.initialCustomStages}
                    variables={schedulingSequencePreview.variables}
                    onClose={this.hidePhoneCallScheduling}
                    onSend={this.handleSendSequence}
                />
            ) : null;
            proceedOptions = (
                <Menu
                    open={optionsOpen}
                    anchorEl={this.anchorRef.current}
                    onClose={this.handleOptionsClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <SchedulingSequenceMenuOption
                        candidate={candidate}
                        text="Send Scheduling Sequence"
                        onClick={this.handleSendSequenceMenuClick(false)}
                        hasLastEmail={!!lastEmail}
                    />
                    {mixmaxCalendarOption}
                    <MenuItem onClick={nextStage ? this.handleProceed(nextStage.name) : null} disabled={!nextStage}>
                        Skip and move forward
                    </MenuItem>
                </Menu>
            );
        } else if (nextStage && nextStage.name === awaitingClientStage) {
            proceedOptions = (
                <SubmitToClientPopover
                    open={optionsOpen}
                    anchorEl={this.anchorRef.current}
                    onRequestClose={this.handleOptionsClose}
                    client={clients.list.get(candidate.clientId)}
                    jobId={candidate.jobId}
                    personId={candidate.personId}
                />
            );
        } else if (nextStage && nextStage.name === clientFirstRoundStage) {
            if (hasFlag(job, JOB_FLAG_INTRO_EMAILS_SUPPORTED) && job.introEmailContact) {
                proceedOptions = (
                    <IntroCandidateClientPopover
                        open={optionsOpen}
                        anchorEl={this.anchorRef.current}
                        onRequestClose={this.handleOptionsClose}
                        jobId={candidate.jobId}
                        personId={candidate.personId}
                    />
                );
            }
        }

        const isWaitingForUpdate = pendingRequests.has(`candidate-update-${personId}-${jobId}`);
        const isButtonDisabled = this.state.isInTimeout || isWaitingForUpdate;

        const spinnerSize = 20;
        const spinnerThickness = 2;
        let button: JSX.Element;
        const buttonClassName = 'candidate-move-button';
        if (isButtonDisabled) {
            const spinner = <CircularProgress size={spinnerSize} thickness={spinnerThickness} />;
            button = <div className={`${buttonClassName} disabled`}>{spinner}</div>;
        } else {
            const proceedFunction = proceedOptions
                ? this.handleOptionsOpen
                : nextStage
                ? this.handleProceed(nextStage.name)
                : null;
            const rocketScreenComplete = jobStages.find((j) => j.name === rocketScreenCompleteStage);
            const canReplyToEmail = !lastEmail || this.checkCanReplyToEmail();
            let profileAgeRestricted = false;
            if (!hasRole(this.props.userPermissions, 'skip_profile_age_gate')) {
                if (candidate.stage === responseReceivedStage) {
                    const candidateAge = details.list.get(candidate.personId)?.profile?.viewedAt;
                    if (maxProfileAgeResponseReceivedInDays < (Date.now() - candidateAge) / oneDayInMilliseconds) {
                        profileAgeRestricted = true;
                    }
                }
            }
            const amRestricted = !job.accountManagerId && nextStage && nextStage.id > rocketScreenComplete.id;
            const disabled = !nextStage || !hasAllData || !canReplyToEmail || amRestricted || profileAgeRestricted;
            let tooltip = '';
            if (disabled) {
                if (profileAgeRestricted) {
                    tooltip = `Candidate profile older than ${maxProfileAgeResponseReceivedInDays} days`;
                } else if (!canReplyToEmail) {
                    tooltip = 'You do not have access to send an email to the candidate.';
                } else if (amRestricted) {
                    tooltip = 'Cannot move past Rocket Screen Complete without an account manager';
                }
            } else {
                if (nextStage && nextStage.name !== awaitingClientStage) {
                    tooltip = `Move forward to ${nextStage.label}`;
                }
            }
            const buttonWithoutTooltip = (
                <RaisedButton
                    icon={<AvSkipNext />}
                    className={buttonClassName}
                    primary={true}
                    disabled={disabled}
                    onClick={proceedFunction}
                />
            );

            if (candidate.stage === responseReceivedStage) {
                const candidateAge = details.list.get(candidate.personId)?.profile?.viewedAt;
                if (
                    maxProfileAgeResponseReceivedInDays < (Date.now() - candidateAge) / oneDayInMilliseconds &&
                    !hasRole(this.props.userPermissions, 'skip_profile_age_gate')
                ) {
                    tooltip = `
                        The profile for the candidate is older than ${maxProfileAgeResponseReceivedInDays} days.
                        Visit the profile in linkedin recruiter and then refresh this page
                    `;
                }
            }

            button = tooltip ? (
                <Tooltip title={tooltip}>
                    <span>{buttonWithoutTooltip}</span>
                </Tooltip>
            ) : (
                buttonWithoutTooltip
            );
        }

        return (
            <div className="candidate-move-button-container candidate-move-button-container-big" ref={this.anchorRef}>
                {button}
                {proceedOptions}
                <MandatoryWarning
                    candidate={candidate}
                    open={this.state.warningDialogOpen}
                    warningReason={this.state.warningDialogReason}
                    moveCandidateToStage={this.props.moveCandidateToStage}
                    requestClose={this.hideNoteWarningDialog}
                />
                {proceedForPhoneCallScheduling}
            </div>
        );
    }
}

const mapStateToProps = (state: State): ConnectedProps => ({
    clients: state.clients,
    communications: state.communications,
    contacts: state.contacts,
    details: state.personsDetails,
    emailAccounts: state.emailAccounts,
    emailContentBlacklistedDomains: state.appConstants.constants.emailContentBlacklistedDomains,
    emailTemplates: state.emailTemplates,
    jobEmails: state.jobEmails,
    jobStages: state.appConstants.constants.jobStages,
    jobs: state.jobs,
    listsState: state.listsState,
    notes: state.notes,
    pendingRequests: state.pendingRequests,
    persons: state.persons,
    sessionUser: state.session.user,
    userPermissions: state.session.userPermissions,
    users: state.users
});
const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    composeEmailWithValidation,
    fetchJobInfo,
    fetchJobsData,
    fetchPersonContacts,
    fetchUsers,
    getAllEmailAccountInfo,
    getEmailTemplates,
    getJobEmails,
    moveCandidateToStage,
    replyToEmailWithValidation,
    replyToEmailWithValidationWithDifferentAccount,
    sendSequenceToPerson,
    showModalAlert
};

export const MoveCandidateForwardButton = connect<ConnectedProps, ConnectedDispatch, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(MoveCandidateForwardButtonComponent);
