// cspell:ignore authtoken googleauthtoken
import { pickBy } from 'lodash';

import { ContactChannel, ContactData } from 'shared/models/contact';
import { EmailTemplateView } from 'shared/models/email-templates';
import { NoteView } from 'shared/models/note';
import { SearchResultsViewType } from 'shared/models/search';
import { SearchConfig } from 'shared/models/search';
import { SequenceVariables } from 'shared/models/sequence';
import { UserData } from 'shared/models/user';
import { HrefFilePayload } from 'shared/types/file-payload';

import { ModelParameters } from 'shared/common/llm';
import { removeLocalStorageKey } from './common/local-storage';
import { queryString, sleep } from './common/utils';
import { config } from './config';
import { store } from './configure-store';
import { Client, ContactType, Person, Search, SearchResult } from './state';
import { RawJsonProfile } from './types/li-json-profile';

export const ApiEndpoint = config.ApiEndpoint;

const deferredRequestPollIntervalMs = 1000;

const getAuthHeaders = (): any => {
    const session = store.getState().session;
    return session.authToken ? { authtoken: session.authToken } : {};
};

export const getHeaders = () => {
    const headers: { [key: string]: string } = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return Object.assign({}, headers, getAuthHeaders());
};

export const getDeferredRequestResult = (id: string) => {
    const endpoint = `${ApiEndpoint}/deferred-request/${id}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const parseResponse = (response: Response): Promise<{ data: any; success: boolean }> => {
    const unauthorized = 401;
    if (response.status === unauthorized) {
        alert('Session Expired. Click OK to sign in again');
        removeLocalStorageKey('session');
        window.location.reload();
    }
    return response
        .json()
        .then((json) => {
            // if request response is deferred, wait for polling interval and request the result
            // if request is still not completed, the response will be deferred again
            if (json.requestDeferred) {
                return sleep(deferredRequestPollIntervalMs).then(() =>
                    getDeferredRequestResult(json.deferredRequestId)
                );
            } else {
                return {
                    data: json,
                    success: response.ok
                };
            }
        })
        .catch(() => {
            return { data: null, success: false };
        });
};

export const authenticate = (googleauthtoken: string, authtoken: string) => {
    const endpoint = `${ApiEndpoint}/authenticate`;
    const headers = pickBy({ googleauthtoken, authtoken });
    return fetch(endpoint, { headers } as any).then(parseResponse);
};

export const fetchUserInfo = (id: string) => {
    const endpoint = `${ApiEndpoint}/user/${id}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchJobs = () => {
    const endpoint = `${ApiEndpoint}/jobs`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchExtensionJobs = () => {
    const endpoint = `${ApiEndpoint}/extension-jobs`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const getJobMetrics = (ids: string[]) => {
    const endpoint = `${ApiEndpoint}/jobs/get-job-metrics`;
    return fetch(endpoint, {
        body: JSON.stringify({ ids }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const createJob = (params: {}) => {
    const endpoint = `${ApiEndpoint}/job`;
    return fetch(endpoint, {
        body: JSON.stringify(params),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const uploadJobOnePager = (jobId: string, payload: HrefFilePayload) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/upload-one-pager`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const updateJob = (id: string, params: {}) => {
    const endpoint = `${ApiEndpoint}/job/${id}`;
    return fetch(endpoint, {
        body: JSON.stringify(params),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const fetchJobInfo = (id: string) => {
    const endpoint = `${ApiEndpoint}/job/${id}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchEmailTemplates = (group: string) => {
    const qs = queryString({ group });
    const endpoint = `${ApiEndpoint}/email-templates?${qs}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const updateEmailTemplate = (data: Partial<EmailTemplateView>) => {
    const endpoint = `${ApiEndpoint}/email-templates/${data.id}`;
    return fetch(endpoint, {
        body: JSON.stringify({ data }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const createEmailTemplate = (data: Partial<EmailTemplateView>) => {
    const endpoint = `${ApiEndpoint}/email-templates`;
    return fetch(endpoint, {
        body: JSON.stringify({ data }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const deleteTemplate = (id: number) => {
    const endpoint = `${ApiEndpoint}/email-templates/${id}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'delete'
    });
};

export const fetchJobDetails = (id: string) => {
    const endpoint = `${ApiEndpoint}/job/${id}?candidates=true`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchCandidate = (jobId: string, personId: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/candidate/${personId}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const getEmailCounts = (jobId: string, email: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/account-email-counts?email=${email}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const updateCandidateStage = (personId: string, jobId: string, stage: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/candidate/${personId}/stage`;
    return fetch(endpoint, {
        body: JSON.stringify({ stage }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const updateCandidateQualification = (personId: string, jobId: string, qualify: boolean, reason: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/candidate/${personId}/qualify`;
    return fetch(endpoint, {
        body: JSON.stringify({ reason, qualify }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const fetchClients = () => {
    const endpoint = `${ApiEndpoint}/clients`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const createClient = (params: {}) => {
    const endpoint = `${ApiEndpoint}/client`;
    return fetch(endpoint, {
        body: JSON.stringify(params),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const uploadClientFiles = (clientId: string, payload: HrefFilePayload[]) => {
    const endpoint = `${ApiEndpoint}/client/${clientId}/files`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const removeClientFile = (clientId: string, key: string) => {
    const endpoint = `${ApiEndpoint}/client/${clientId}/file`;
    return fetch(endpoint, {
        body: JSON.stringify({ key }),
        headers: getHeaders(),
        method: 'delete'
    }).then(parseResponse);
};

export const fetchPersonsList = (ids: string[]) => {
    const endpoint = `${ApiEndpoint}/get-persons`;
    return fetch(endpoint, {
        body: JSON.stringify({ ids }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchPerson = (id: string, jobId?: string) => {
    const jobQuery = jobId ? `?jobId=${jobId}` : '';
    const endpoint = `${ApiEndpoint}/person/${id}/details${jobQuery}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchPersonCrunchbaseData = (personId: string) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/crunchbase`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchPersonContacts = (personId: string) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/contacts`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const createPersonJobLabel = (personId: string, jobId: string, label: boolean) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/job/${jobId}/label`;
    return fetch(endpoint, {
        body: JSON.stringify({ label }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const createNote = (payload: Partial<NoteView>) => {
    const endpoint = `${ApiEndpoint}/notes`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchNotes = (notable: string) => {
    const endpoint = `${ApiEndpoint}/notes/${notable}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const deleteNote = (id: string) => {
    const endpoint = `${ApiEndpoint}/note/${id}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'delete'
    }); // no response
};

export const fetchUserActions = (target: string) => {
    const endpoint = `${ApiEndpoint}/actions/${target}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const addContact = (personId: string, value: string, channel: ContactChannel) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/add-contact`;
    return fetch(endpoint, {
        body: JSON.stringify({ value, channel }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const updateContact = (
    personId: string,
    value: string,
    channel: string,
    attribute: string,
    attrVal: boolean | ContactType
) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/update-contact`;
    return fetch(endpoint, {
        body: JSON.stringify({ value, channel, attribute, attrVal }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const deleteContact = (personId: string, channel: string, value: string) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/delete-contact`;
    return fetch(endpoint, {
        body: JSON.stringify({ personId, channel, value }),
        headers: getHeaders(),
        method: 'delete'
    }).then(parseResponse);
};

export const addContactFromMessage = (personId: string, account: string, messageId: string, value: string) => {
    const endpoint = `${ApiEndpoint}/communication/${messageId}/account/${account}/add-contact-from-message`;
    return fetch(endpoint, {
        body: JSON.stringify({ value, messageId, account, personId }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const uploadPersonFile = (personId: string, payload: HrefFilePayload[]) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/upload-file`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const updatePerson = (id: string, updates: Partial<Person>) => {
    const endpoint = `${ApiEndpoint}/person/${id}`;
    return fetch(endpoint, {
        body: JSON.stringify({ updates }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const doNotEmailPerson = (personId: string, payload: { note: string; clientId: string }) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/do-not-email-again`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const personNotInterestedAtThisTime = (
    personId: string,
    payload: {
        jobId: string;
        note: string;
        optOutUntil: number;
    }
) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/not-interested-at-this-time`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchUsers = () => {
    const endpoint = `${ApiEndpoint}/users`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchCandidatesForOutreach = (jobId: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/download-candidates`;
    return fetch(endpoint, { headers: getHeaders(), method: 'put' }).then(parseResponse);
};

export const fetchRevivalCandidates = (jobId: string, disqualReason?: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/revive-candidates${
        disqualReason ? `?disqualReason=${disqualReason}` : ''
    }`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const addEmailAccount = (userId: string, jobId: string, capacity: number) => {
    const endpoint = `${ApiEndpoint}/email-accounts`;
    return fetch(endpoint, {
        body: JSON.stringify({ userId, jobId, capacity }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchClientById = (clientId: string) => {
    const endpoint = `${ApiEndpoint}/client/${clientId}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const updateClient = (clientId: string, updates: Partial<Client>) => {
    const endpoint = `${ApiEndpoint}/client/${clientId}/update`;
    return fetch(endpoint, {
        body: JSON.stringify({ updates }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const uploadClientPersonBlacklist = (clientId: string, files: string[]) => {
    const endpoint = `${ApiEndpoint}/client/${clientId}/upload-person-blacklist`;
    return fetch(endpoint, {
        body: JSON.stringify({ files }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchWeeklyUpdateData = (clientId: string, startDate: number, endDate: number, all: boolean) => {
    const requestAll = all ? `&all=true` : '';
    const endpoint =
        `${ApiEndpoint}/client/${clientId}/weekly-update?` + `startDate=${startDate}&endDate=${endDate}${requestAll}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const addCandidateToJob = (
    personId: string,
    jobId: string,
    source: string,
    stage: string,
    inMailSent: boolean
) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/add-candidate/${personId}`;
    return fetch(endpoint, {
        body: JSON.stringify({ source, stage, inMailSent }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const searchForPerson = (searchString: string) => {
    const endpoint = `${ApiEndpoint}/person/search`;
    return fetch(endpoint, {
        body: JSON.stringify({ searchString }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const requestSendSequenceToPerson = (
    personId: string,
    jobId: string,
    account: string,
    sequenceId: string,
    variables: SequenceVariables,
    customStages: Array<{ subject: string; body: string }>
) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/candidate/${personId}/send-sequence-to-person`;
    return fetch(endpoint, {
        body: JSON.stringify({ account, sequenceId, variables, customStages }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const moveInGmail = (account: string, threadId: string, action: string) => {
    const endpoint = `${ApiEndpoint}/communication/move-in-gmail`;
    return fetch(endpoint, {
        body: JSON.stringify({
            account,
            action,
            threadId
        }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const refreshMessage = (messageId: string, account: string) => {
    const endpoint = `${ApiEndpoint}/communication/${messageId}/account/${account}`;
    return fetch(endpoint, {
        headers: getHeaders()
    }).then(parseResponse);
};

export const reassignClient = (threadId: string, account: string, newClientId: string) => {
    const endpoint = `${ApiEndpoint}/communication/${threadId}/reassign-client`;
    return fetch(endpoint, {
        body: JSON.stringify({ threadId, clientId: newClientId, account }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const parseProfile = (payload: {
    jobId: string;
    data: { url: string; json?: RawJsonProfile; html?: string; pendingLoadSections: number };
}) => {
    const endpoint = `${ApiEndpoint}/person/parse-profile`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getShortUrl = (longUrl: string) => {
    const endpoint = `${ApiEndpoint}/users/get-short-url`;
    return fetch(endpoint, {
        body: JSON.stringify({ longUrl }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const scoreProfile = (payload: any) => {
    const endpoint = `${ApiEndpoint}/person/score-profile`;
    return fetch(endpoint, {
        body: JSON.stringify(payload),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchAllEmailAccountInfo = () => {
    const endpoint = `${ApiEndpoint}/email-accounts`;
    return fetch(endpoint, {
        headers: getHeaders()
    }).then(parseResponse);
};

export const updateEmailAccount = (
    email: string,
    updates: { userId?: string; jobId?: string; syncStatus?: string }
) => {
    const endpoint = `${ApiEndpoint}/admin/update-email-account/${email}`;
    return fetch(endpoint, {
        body: JSON.stringify(updates),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const fetchScoringEvalData = () => {
    const endpoint = `${ApiEndpoint}/get-scoring-eval-data`;
    return fetch(endpoint, {
        headers: getHeaders()
    }); // do not parse
};

export const fetchJobSearchStats = (jobId: string) => {
    const endpoint = `${ApiEndpoint}/searches/job/${jobId}/stats`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const requestSearchProfilesRefresh = (id: string) => {
    const endpoint = `${ApiEndpoint}/search/${id}/refresh-profiles`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const fetchSearchResults = (
    search: Partial<Search>,
    resultsType: SearchResultsViewType,
    isCampaign: boolean,
    limit?: number
) => {
    const endpoint = `${ApiEndpoint}/search/get-results`;
    return fetch(endpoint, {
        body: JSON.stringify({ search, resultsType, limit, isCampaign }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchSearchResultsPreview = (
    search: Partial<Search>,
    resultsType: SearchResultsViewType,
    limit?: number
) => {
    const endpoint = `${ApiEndpoint}/search/preview-results`;
    return fetch(endpoint, {
        body: JSON.stringify({ search, resultsType, limit }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getPreviewCount = (search: Partial<Search>) => {
    const endpoint = `${ApiEndpoint}/search/preview-results`;
    return fetch(endpoint, {
        body: JSON.stringify({ search, count: true }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getSQLForConfig = (searchConfig: SearchConfig, jobId: string) => {
    const endpoint = `${ApiEndpoint}/search/get-sql-for-config`;
    return fetch(endpoint, {
        body: JSON.stringify({ config: searchConfig, jobId }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const deleteSearch = (id: string) => {
    const endpoint = `${ApiEndpoint}/search/${id}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'delete'
    }); // no response
};

export const initializeSearch = (search: Partial<Search>) => {
    const endpoint = `${ApiEndpoint}/search/initialize`;
    return fetch(endpoint, {
        body: JSON.stringify({ search }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const updateSearch = (id: string, updates: Partial<Search>) => {
    const endpoint = `${ApiEndpoint}/search/${id}`;
    return fetch(endpoint, {
        body: JSON.stringify({ updates }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const getSearchProfiles = (id: string, personIds: string[]) => {
    const endpoint = `${ApiEndpoint}/search/${id}/get-profiles`;
    return fetch(endpoint, {
        body: JSON.stringify({ personIds }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getSearchForSourcing = (jobId: string, sortRank: number, direction: 'previous' | 'next') => {
    const endpoint = `${ApiEndpoint}/search/job/${jobId}/source/${sortRank}?direction=${direction}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const addSearchResultToJob = (jobId: string, result: SearchResult, disqualified: boolean) => {
    const endpoint = `${ApiEndpoint}/search/add-candidate/${jobId}`;
    return fetch(endpoint, {
        body: JSON.stringify({ result, disqualified }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const fetchSearchResultPersonAndCandidate = (result: SearchResult, jobId: string) => {
    const endpoint = `${ApiEndpoint}/search-result/person-candidate/${result.personId}/job/${jobId}`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const getSimilaritySearchResults = (searchQuery: string, searchType: string, topn: number) => {
    const endpoint = `${ApiEndpoint}/search/similarity`;
    return fetch(endpoint, {
        body: JSON.stringify({ searchQuery, searchType, topn }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const purgeSearch = (id: string) => {
    const endpoint = `${ApiEndpoint}/search/${id}/purge`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'post'
    }); // no response
};

export const refreshSearch = (id: string) => {
    const endpoint = `${ApiEndpoint}/search/${id}/refresh`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const purgeJobCandidates = (id: string) => {
    const endpoint = `${ApiEndpoint}/job/${id}/purge`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'post'
    }); // no response
};

export const downloadFile = (url: string) => {
    const endpoint = `${ApiEndpoint}/get-file`;
    return fetch(endpoint, {
        body: JSON.stringify({ url }),
        headers: getHeaders(),
        method: 'post'
    }); // no parse - send raw data through
};

export const requestPresets = () => {
    const endpoint = `${ApiEndpoint}/search/presets`;
    return fetch(endpoint, {
        headers: getHeaders()
    }).then(parseResponse);
};

export const savePreset = (searchConfig: SearchConfig, name: string, group: string, groupLabel: string) => {
    const endpoint = `${ApiEndpoint}/search/save-preset`;
    return fetch(endpoint, {
        body: JSON.stringify({ config: searchConfig, name, group, groupLabel }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const sendTestEmail = (subject: string, body: string) => {
    const endpoint = `${ApiEndpoint}/email-templates/send-test-email`;
    return fetch(endpoint, {
        body: JSON.stringify({ body, subject }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getJobsSearchesStats = (refresh: boolean) => {
    const refreshQuery = refresh ? `?refresh=${refresh}` : '';
    const endpoint = `${ApiEndpoint}/search/stats${refreshQuery}`;
    return fetch(endpoint, {
        headers: getHeaders()
    }).then(parseResponse);
};

export const addWebsite = (personId: string, value: string) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/add-website`;
    return fetch(endpoint, {
        body: JSON.stringify({ value }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const getJobEmails = (jobId: string) => {
    const endpoint = `${ApiEndpoint}/email/job/${jobId}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const getFlaggedEmails = (account: string, offset: number, limit: number) => {
    const endpoint = `${ApiEndpoint}/communication/${account}/flagged?offset=${offset}&limit=${limit}`;
    return fetch(endpoint, { headers: getHeaders(), method: 'get' }).then(parseResponse);
};

export const syncAccountAndGetCommunications = (account: string, personId: string) => {
    const endpoint = `${ApiEndpoint}/email/${account}/person-comms-with-sync/${personId}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const getPersonCommunications = (id: string) => {
    const endpoint = `${ApiEndpoint}/person/${id}/communications`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const fetchFlaggedEmailAccounts = () => {
    const endpoint = `${ApiEndpoint}/email-accounts/flagged-accounts`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const requestForwardFlaggedEmail = (account: string, messageId: string, email: string) => {
    const endpoint = `${ApiEndpoint}/communication/${account}/forward-flagged-email/${messageId}`;
    return fetch(endpoint, {
        body: JSON.stringify({ email }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const reportIncorrectCandidate = (account: string, messageId: string) => {
    const endpoint = `${ApiEndpoint}/communication/${messageId}/account/${account}/report-incorrect-candidate`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const requestSetEmailMatch = (
    account: string,
    messageId: string,
    data: { ignored?: boolean; personIds?: string[]; jobIds?: string[]; error?: boolean }
) => {
    const endpoint = `${ApiEndpoint}/communication/${account}/set-match/${messageId}`;
    return fetch(endpoint, {
        body: JSON.stringify(data),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const requestCommunicationMatch = (account: string, threadId: string) => {
    const endpoint = `${ApiEndpoint}/communication/${account}/re-match/${threadId}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const fetchJobCandidatesSummary = (jobId: string, stages: string[]) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/candidate-summary`;
    return fetch(endpoint, {
        body: JSON.stringify({ stages }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const requestSubmissionNoteStatusUpdate = (note: NoteView) => {
    const endpoint = `${ApiEndpoint}/note/${note.id}/request-submission-note-status`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const updatePrimaryEmail = (jobId: string, primaryEmail: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/set-primary-email`;
    return fetch(endpoint, {
        body: JSON.stringify({ primaryEmail }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getGeneratedSubmission = (jobId: string, personId: string, userId: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/person/${personId}/user/${userId}/generate-submission`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const getGPTCompletion = (
    params: Partial<ModelParameters> & { messages: Array<{ role: 'system' | 'user' | 'assistant'; content: string }> }
) => {
    const endpoint = `${ApiEndpoint}/gpt/complete`;
    return fetch(endpoint, {
        body: JSON.stringify(params),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getAIEmailResponse = (personId: string, jobId: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/candidate/${personId}/ai-email-response`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const crossAddCandidate = (
    personId: string,
    jobIds: string[],
    sourceJobId: string,
    newStage: string,
    source: 'cross-submit' | 'cross-add' | 'candidate-search-cross-add' | 'person-add'
) => {
    const endpoint = `${ApiEndpoint}/job/cross-add`;
    return fetch(endpoint, {
        body: JSON.stringify({ sourceJobId, jobIds, personId, newStage, source }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const updatePersonBlacklisted = (id: string, blacklisted: boolean) => {
    const endpoint = `${ApiEndpoint}/person/${id}/blacklisted`;
    return fetch(endpoint, {
        body: JSON.stringify({ blacklisted }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const updateUser = (userId: string, updates: Partial<UserData>) => {
    const endpoint = `${ApiEndpoint}/user/${userId}`;
    return fetch(endpoint, {
        body: JSON.stringify({ updates }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const updatePersonFilename = (personId: string, path: string, filename: string) => {
    const endpoint = `${ApiEndpoint}/person/update-filename`;
    return fetch(endpoint, {
        body: JSON.stringify({ personId, path, filename }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getPersonScheduledMessages = (personId: string) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/scheduled-messages`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const rescheduleMessage = (
    account: string,
    scheduledMessageId: string,
    newScheduledAt: number,
    personId: string,
    jobId: string
) => {
    const endpoint = `${ApiEndpoint}/scheduled-messages/${account}/reschedule/${scheduledMessageId}`;
    return fetch(endpoint, {
        body: JSON.stringify({ scheduledAt: newScheduledAt, data: { personId, jobId } }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const cancelScheduledMessage = (
    account: string,
    scheduledMessageId: string,
    personId: string,
    jobId: string
) => {
    const endpoint = `${ApiEndpoint}/scheduled-messages/${account}/cancel/${scheduledMessageId}`;
    return fetch(endpoint, {
        body: JSON.stringify({ data: { personId, jobId } }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const impersonate = (email: string) => {
    const endpoint = `${ApiEndpoint}/impersonate/${email}`;
    return fetch(endpoint, {
        headers: getHeaders()
    }).then(parseResponse);
};

export const pauseJobSearches = (jobId: string) => {
    const endpoint = `${ApiEndpoint}/searches/job/${jobId}/pause`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const reassignCandidateAssignee = (personId: string, jobId: string, newAssigneeId: string) => {
    const endpoint = `${ApiEndpoint}/job/${jobId}/candidate/${personId}/reassign-assignee`;
    return fetch(endpoint, {
        body: JSON.stringify({ newAssigneeId }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const ignoreContactFromMessage = (account: string, messageId: string) => {
    const endpoint = `${ApiEndpoint}/communication/${account}/ignore-contact-from-message/${messageId}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const crossAddCandidates = (data: { jobId: string; personIds: string[] }) => {
    const endpoint = `${ApiEndpoint}/job/cross-add-multiple`;
    return fetch(endpoint, {
        body: JSON.stringify(data),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getRuntimeConstants = (keys: string[]) => {
    const endpoint = `${ApiEndpoint}/runtime-constants`;
    return fetch(endpoint, {
        body: JSON.stringify({ keys }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const getSourcingCampaign = (campaignId: string) => {
    const qs = queryString({ campaignId });
    const endpoint = `${ApiEndpoint}/sourcing/campaign?${qs}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const getSourcer = (sourcerId: string) => {
    const qs = queryString({ sourcerId });
    const endpoint = `${ApiEndpoint}/sourcing/sourcer?${qs}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};

export const fetchCampaignSearchStats = (jobId: string) => {
    const endpoint = `${ApiEndpoint}/searches/campaign/${jobId}/stats`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const addProspects = (data: { campaignId: string; limit: number }) => {
    const endpoint = `${ApiEndpoint}/sourcing/add-prospects`;
    return fetch(endpoint, {
        body: JSON.stringify(data),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const addSourcerMembers = (data: { sourcerId: string; limit: number }) => {
    const endpoint = `${ApiEndpoint}/sourcing/add-sourcer-members`;
    return fetch(endpoint, {
        body: JSON.stringify(data),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const fetchConflictProfiles = () => {
    const endpoint = `${ApiEndpoint}/conflict-profiles`;
    return fetch(endpoint, { headers: getHeaders() }).then(parseResponse);
};

export const enrichConflictProfile = (personId1: string, personId2: string) => {
    const endpoint = `${ApiEndpoint}/conflict-profiles/enrich`;
    const queryParams = new URLSearchParams({
        personId1,
        personId2
    });
    return fetch(`${endpoint}?${queryParams}`, { headers: getHeaders() }).then(parseResponse);
};

export const reassignProfileUrl = (url: string, toPersonId: string) => {
    const endpoint = `${ApiEndpoint}/conflict-profiles/reassign-url`;
    return fetch(endpoint, {
        body: JSON.stringify({ toPersonId, url }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const reassignContact = (contact: ContactData, toPersonId: string) => {
    const endpoint = `${ApiEndpoint}/conflict-profiles/reassign-contact`;
    return fetch(endpoint, {
        body: JSON.stringify({ toPersonId, contact }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const findConflictProfileByProfileIdsAndDelete = (personId1: string, personId2: string) => {
    const endpoint = `${ApiEndpoint}/conflict-profiles`;
    const queryParams = new URLSearchParams({
        personId1,
        personId2
    });
    return fetch(`${endpoint}?${queryParams}`, {
        headers: getHeaders(),
        method: 'delete'
    }).then(parseResponse);
};

export const mergeProfiles = (from: string, to: string) => {
    const endpoint = `${ApiEndpoint}/conflict-profiles/merge-profile`;
    return fetch(endpoint, {
        body: JSON.stringify({ from, to }),
        headers: getHeaders(),
        method: 'post'
    }).then(parseResponse);
};

export const updateProfileLink = (personId: string, url: string, invalid: boolean) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/profile-link`;
    return fetch(endpoint, {
        body: JSON.stringify({ invalid, url }),
        headers: getHeaders(),
        method: 'put'
    }).then(parseResponse);
};

export const deleteProfileLink = (personId: string, url: string) => {
    const endpoint = `${ApiEndpoint}/person/${personId}/profile-link`;
    return fetch(endpoint, {
        body: JSON.stringify({ url }),
        headers: getHeaders(),
        method: 'delete'
    }).then(parseResponse);
};

export const fetchCompanyTalentData = (companyUrl: string, discipline: string) => {
    const qs = queryString({ companyUrl, discipline });
    const endpoint = `${ApiEndpoint}/company/talent-data?${qs}`;
    return fetch(endpoint, {
        headers: getHeaders(),
        method: 'get'
    }).then(parseResponse);
};
