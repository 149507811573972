import { Map } from 'immutable';

import { emailFoundStage, emailNotFoundStage, emailRequestedStage, sourcedStage } from 'shared/models/job-stages';

import {
    Action,
    ExtensionProfileParsed,
    MoveCandidateToStage,
    ReceiveInitialOutreachForJob,
    ReceiveJobInfo,
    ReceiveJobOnePagerResult,
    ReceiveJobsList,
    ReceiveJobUpdate,
    ReceiveNewJob,
    ReceivePersonDetails,
    ReceivePurgeCandidatesResponse,
    ReceiveUpdateJobPrimaryEmail,
    RequestCandidateDisqualify
} from '../actions';
import { mergeArrayToMap } from '../common/lang/immutable-utils';
import { Job } from '../state';

const initialState: Map<string, Job> = Map();

export function jobs(state = initialState, action: Action): Map<string, Job> {
    switch (action.type) {
        case ExtensionProfileParsed:
        case ReceiveJobsList:
            return action.payload.jobs?.length > 0 ? mergeArrayToMap(state, action.payload.jobs) : state;
        case ReceivePersonDetails:
            return mergeArrayToMap(state, action.payload.jobs);
        case ReceiveJobInfo:
            return state.set(action.payload.job.id, action.payload.job);
        case ReceiveNewJob:
            if (!action.error) {
                const job = action.payload;
                return state.set(job.id, job);
            } else {
                return state;
            }
        case ReceiveJobOnePagerResult:
            return state.set(action.payload.job.id, action.payload.job);
        case ReceiveInitialOutreachForJob:
            if (action.payload.job) {
                return state.set(action.payload.job.id, action.payload.job);
            } else {
                return state;
            }
        case ReceiveJobUpdate:
            return state.set(action.payload.id, action.payload.job);
        case RequestCandidateDisqualify: {
            const { candidate, qualified } = action.payload;
            const job = state.get(candidate.jobId);
            const stageCounts = job.stageFunnelCounts[candidate.stage];
            const src = qualified ? 'disqualified' : 'qualified';
            const dest = qualified ? 'qualified' : 'disqualified';
            const funnel = candidate.source === 'titan' ? 'crosspitch' : 'main';
            const srcStageCount = stageCounts[src];
            const destStageCount = stageCounts[dest] || {};
            const updatedSrcStageCount = Object.assign({}, srcStageCount, { [funnel]: srcStageCount[funnel] - 1 });
            const updatedDestStageCount = Object.assign({}, destStageCount, {
                [funnel]: (destStageCount[funnel] || 0) + 1
            });
            const updatedStageCounts = Object.assign({}, stageCounts, {
                [src]: updatedSrcStageCount,
                [dest]: updatedDestStageCount
            });
            return state.set(
                job.id,
                Object.assign({}, job, {
                    stageFunnelCounts: Object.assign({}, job.stageFunnelCounts, {
                        [action.payload.candidate.stage]: updatedStageCounts
                    })
                })
            );
        }
        case MoveCandidateToStage: {
            const { candidate, stage } = action.payload;
            const job = state.get(candidate.jobId);
            const qualification = candidate.disqualified ? 'disqualified' : 'qualified';
            const funnel = candidate.source === 'titan' ? 'crosspitch' : 'main';
            const srcStageCounts = job.stageFunnelCounts[candidate.stage];
            if (!srcStageCounts) {
                return state;
            } else {
                const tgtStageCounts = Object.assign(
                    {},
                    { qualified: {}, disqualified: {} },
                    job.stageFunnelCounts[stage]
                );
                const stageFunnelCounts = Object.assign({}, job.stageFunnelCounts, {
                    [candidate.stage]: Object.assign({}, srcStageCounts, {
                        [qualification]: {
                            ...srcStageCounts[qualification],
                            [funnel]: srcStageCounts[qualification][funnel] - 1
                        }
                    }),
                    [stage]: Object.assign({}, tgtStageCounts, {
                        [qualification]: {
                            ...tgtStageCounts[qualification],
                            [funnel]: ((tgtStageCounts[qualification] || {})[funnel] || 0) + 1
                        }
                    })
                });
                return state.set(job.id, Object.assign({}, job, { stageFunnelCounts }));
            }
        }
        case ReceivePurgeCandidatesResponse: {
            const job = state.get(action.payload.id);
            const newStageFunnelCounts = Object.assign({}, job.stageFunnelCounts, {
                [emailFoundStage]: { qualified: {}, disqualified: {} },
                [emailNotFoundStage]: { qualified: {}, disqualified: {} },
                [emailRequestedStage]: { qualified: {}, disqualified: {} },
                [sourcedStage]: { qualified: {}, disqualified: {} }
            });
            const newJob = Object.assign({}, job, { stageFunnelCounts: newStageFunnelCounts });
            return state.set(action.payload.id, newJob);
        }
        case ReceiveUpdateJobPrimaryEmail:
            return state.set(action.payload.job.id, action.payload.job);
        default:
            return state;
    }
}
