import { gql } from '@apollo/client';
import { internalClientIds } from 'shared/models/client';
import { SequenceBaseData } from 'shared/models/sequence';
import { Job } from '../../state';

export interface SearchJob {
    id: string;
    title: string;
    client: {
        name: string;
    };
}

export interface JobFunnelTargetData {
    allocations: Array<{
        userId: string;
        acceptsGoal: number;
        jobId: string;
    }>;
    metrics: Array<{
        accepts: number;
    }>;
    targets: Array<{
        stage: string;
        goal: number;
    }>;
    users: Array<{
        id: string;
        name: string;
        targets: Array<{
            goal: number;
        }>;
        last14dFunnel: Array<{
            count: number;
        }>;
        allocations_aggregate: {
            aggregate: {
                sum: {
                    acceptsGoal: number;
                };
            };
        };
    }>;
}

export interface JobWithSequence extends Partial<Job> {
    sequences: {
        data: Array<Partial<SequenceBaseData>>;
    };
}

export interface BillingInfoData {
    job: {
        id: string;
        billingInfo: { prepayment: { invoiceSentAt: number; invoiceAmount: number } };
    };
}

export interface JobTitleData {
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
}

export interface JobTitleData {
    id: string;
    title: string;
    status: number;
    client: {
        id: string;
        name: string;
    };
}

export const SEARCH_JOB = gql`
    query SearchJob($text: String!) {
        jobs(
            where: { _or: [{ client: { name: { _ilike: $text } } }, { title: { _ilike: $text } }], status: { _neq: 3 } }
        ) {
            id
            title
            client {
                name
            }
        }
    }
`;

export const CREATE_JOB = gql`
    mutation CreateJob($job: jobs_insert_input!) {
        jobs: insert_jobs(objects: [$job]) {
            returning {
                id
            }
        }
    }
`;

export const BILLING_INFO = gql`
    query BillingInfoData($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            id
            billingInfo
        }
    }
`;

export const UPDATE_BILLING_INFO = gql`
    mutation UpdateJobBillingInfo($jobId: String!, $updates: jobs_set_input!) {
        update_jobs(where: { id: { _eq: $jobId } }, _set: $updates) {
            returning {
                id
                billingInfo
            }
        }
    }
`;

export const JOB_TITLE = gql`
    query JobTitle($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            id
            title
            client {
                id
                name
            }
        }
    }
`;

export const ALL_JOB_TITLES = gql`
    query AllJobTitles {
        jobs: jobs(
            where: { clientId: { _nin: ${JSON.stringify(internalClientIds)} } }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            status
            client {
                id
                name
            }
        }
    }
`;

export const JOB_FUNNEL_TARGETS_DATA = gql`
    query JobFunnelTargets($jobId: String!) {
        targets: job_14d_funnel_target(where: { jobId: { _eq: $jobId }, stage: { _eq: "client_first_round" } }) {
            stage
            goal
        }
        allocations: job_allocations(where: { jobId: { _eq: $jobId } }) {
            acceptsGoal
            userId
            jobId
        }
        metrics: job_metrics(where: { id: { _eq: $jobId } }) {
            accepts
        }
        users(
            where: {
                _or: [
                    { status: { _eq: "active" }, roles: { role: { _eq: recruiter } } }
                    { allocations: { job: { id: { _eq: $jobId } } } }
                ]
            }
            order_by: { name: asc }
        ) {
            id
            name(path: "full")
            targets(where: { stage: { _eq: "client_first_round" } }) {
                goal
            }
            last14dFunnel(where: { stage: { _eq: "client_first_round" } }) {
                count
            }
            allocations_aggregate(
                where: {
                    job: {
                        status: { _eq: 1 }
                        clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                        jobType: { _neq: "Staffing" }
                    }
                }
            ) {
                aggregate {
                    sum {
                        acceptsGoal
                    }
                }
            }
        }
    }
`;

export const UPDATE_JOB_FUNNEL_DATA = gql`
    mutation UpdateJobFunnelTargets(
        $jobId: String!
        $stage: String!
        $goal: Int!
        $allocations: [job_allocations_insert_input!]!
    ) {
        update_job_14d_funnel_target_by_pk(pk_columns: { jobId: $jobId, stage: $stage }, _set: { goal: $goal }) {
            goal
        }
        delete_job_allocations(where: { jobId: { _eq: $jobId } }) {
            affected_rows
        }
        insert_job_allocations(objects: $allocations) {
            affected_rows
        }
    }
`;

export const JOB_OUTREACHED_CANDIDATES_COUNT = gql`
    query JobOutreachedCandidatesCount($id: String!) {
        job: jobs_by_pk(id: $id) {
            id
            outreachedCandidatesCount
        }
    }
`;

export const JOB_LAST_MODIFICATION_AT = gql`
    subscription JobLastModificationAt($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            modifiedAt
        }
    }
`;
