import { gql } from '@apollo/client';
import { JobStatus } from 'shared/models/job';

import { FunnelCounts } from 'shared/models/job-stages';

import { internalClientIds } from 'shared/models/client';
import { JobType } from '../../state';

export interface Job {
    id: string;
    title: string;
    createdAt: number;
    assignee: {
        id: string;
        name: string;
    };
    client: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    jobType: JobType;
    status: JobStatus;
    stageFunnelCounts: {
        [stage: string]: { qualified: FunnelCounts; disqualified: FunnelCounts };
    };
    aggregateFunnelCounts: Array<{
        stageId: number;
        name: string;
        count: number;
    }>;
    metrics: {
        emailsSent: number;
    };
    firstEmailOut: {
        sentAt: number;
    };
}

// cspell:disable
export const JOBS = gql`
    query Jobs($beginDate: bigint!, $endDate: bigint!, $types: [String!]) {
        jobStages: job_stages(order_by: { id: asc }) {
            id
            name
        }
        jobs(
            where: {
                status: { _eq: 1 }
                _and: [{ createdAt: { _lte: $beginDate } }, { createdAt: { _gte: $endDate } }]
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                jobType: { _in: $types }
            }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            client {
                id
                name
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
            jobType
            status
            stageFunnelCounts
            aggregateFunnelCounts(
                where: {
                    name: {
                        _in: [
                            "outreach_sent"
                            "rocket_screen_scheduled"
                            "awaiting_client_feedback"
                            "client_first_round"
                        ]
                    }
                }
            ) {
                _id
                stageId
                name
                count
            }
            metrics: job_metrics {
                emailsSent
            }
            firstEmailOut {
                sentAt
            }
        }
    }
`;
