import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { pickBy } from 'lodash';
import * as React from 'react';

import { promptsOutreachGenSystem, promptsOutreachGenUser } from 'shared/common/strings';
import { SampleOutreachData, SequenceStage, SequenceType, SequenceView } from 'shared/models/sequence';

import { JobSequencesForm as JobSequencesFormComponent } from '../components/job-sequences-form';
import {
    CREATE_SEQUENCE,
    CREATE_SEQUENCE_GENERATION_PROMPT,
    GET_SAMPLE_LLM_GENERATED_EMAILS,
    GET_SEQUENCE_GENERATION_PROMPTS,
    Prompt,
    Sequence,
    SEQUENCES,
    UPDATE_PROMPT_STATUS,
    UPDATE_SEQUENCE,
    UPDATE_SEQUENCE_STATUS
} from '../graphql/queries/sequences';
import useConstants from '../hooks/use-constants';
import { useSnackbar } from '../hooks/use-snackbar';

export const JobSequencesForm: React.FC<{ role: string; jobId: string; isEditable: boolean }> = ({
    role,
    jobId,
    isEditable
}) => {
    const { setSnackbar } = useSnackbar();

    const { data, loading, refetch } = useQuery<{ sequences: Sequence[] }, { jobId: string }>(SEQUENCES, {
        variables: { jobId }
    });
    const { data: prompts, refetch: refetchPrompts } = useQuery<{ prompts: Prompt[] }, { jobId: string }>(
        GET_SEQUENCE_GENERATION_PROMPTS,
        { variables: { jobId } }
    );
    const [getSampleOutreach] = useLazyQuery<{ sampleOutreach: SampleOutreachData }, { prompt: Partial<Prompt> }>(
        GET_SAMPLE_LLM_GENERATED_EMAILS
    );
    const { getConstants } = useConstants();
    const constants = getConstants(['outreachGenDefaultModelParams', 'outreachGenModelOptions']);

    const [updateSequence] = useMutation<{
        id: string;
        sequence: {
            title: string;
            stages: SequenceStage[];
            jobId: string;
            type: SequenceType;
            archived: boolean;
        };
    }>(UPDATE_SEQUENCE);
    const [updateSequenceStatus] = useMutation<{
        id: string;
    }>(UPDATE_SEQUENCE_STATUS);

    const [createSequence] = useMutation<{
        sequence: {
            title: string;
            stages: SequenceStage[];
            jobId: string;
            type: SequenceType;
        };
    }>(CREATE_SEQUENCE);

    const [createPrompt] = useMutation<{
        prompt: {
            title: string;
            jobId: string;
            jobDescription: string;
        };
    }>(CREATE_SEQUENCE_GENERATION_PROMPT);
    const [updatePromptStatus] = useMutation<{}, { id: string; active: boolean }>(UPDATE_PROMPT_STATUS);

    const handleCreate = async (sequence: SequenceView) => {
        setSnackbar('Creating sequence');
        try {
            await createSequence({
                variables: {
                    sequence: {
                        jobId,
                        stages: sequence.stages,
                        title: sequence.title,
                        type: sequence.type
                    }
                }
            });
            setSnackbar('Sequence created');
        } catch (error) {
            if (error.message.includes('sequences_jobId_contentHash_key')) {
                setSnackbar('Failed to create sequence: sequence content same as existing sequence');
            } else {
                setSnackbar('Failed to create sequence');
            }
        }
        refetch();
    };

    const handleUpdate = async (sequence: SequenceView) => {
        setSnackbar('Updating sequence');
        try {
            await updateSequence({
                variables: {
                    id: sequence.id,
                    sequence: {
                        archived: false,
                        jobId,
                        stages: sequence.stages,
                        title: sequence.title,
                        type: sequence.type
                    }
                }
            });
            setSnackbar('Sequence updated');
        } catch (error) {
            setSnackbar('Failed to update sequence');
        }
        refetch();
    };

    const handleArchiveUpdate = async (sequenceId: string, archive: boolean) => {
        if (archive) {
            setSnackbar('Archiving sequence');
        } else {
            setSnackbar('Unarchiving sequence');
        }
        try {
            await updateSequenceStatus({
                variables: {
                    archive,
                    id: sequenceId
                }
            });
            if (archive) {
                setSnackbar('Archived sequence');
            } else {
                setSnackbar('Sequence unarchived');
            }
        } catch (error) {
            if (archive) {
                setSnackbar('Failed to archive sequence');
            } else {
                setSnackbar('Failed to unarchive sequence');
            }
        }
        refetch();
    };

    const handleCreatePrompt = async (prompt: Partial<Prompt>) => {
        setSnackbar('Creating sequence generation prompt');
        try {
            const { userId, __typename, ...rest } = prompt as any;
            await createPrompt({
                variables: {
                    prompt: { ...pickBy(rest), jobId }
                }
            });
            setSnackbar('Sequence generation prompt created');
        } catch (error) {
            setSnackbar('Failed to create sequence generation prompt');
        }
        refetchPrompts();
    };

    const handlePromptArchiveUpdate = async (promptId: string, archive: boolean) => {
        if (archive) {
            setSnackbar('Archiving prompt');
        } else {
            setSnackbar('Unarchiving prompt');
        }
        try {
            await updatePromptStatus({
                variables: {
                    active: !archive,
                    id: promptId
                }
            });
            if (archive) {
                setSnackbar('Archived prompt');
            } else {
                setSnackbar('Prompt unarchived');
            }
        } catch (error) {
            if (archive) {
                setSnackbar('Failed to archive prompt');
            } else {
                setSnackbar('Failed to unarchive prompt');
            }
        }
        refetchPrompts();
    };

    const handleFetchSampleOutreach = async (prompt: Partial<Prompt>) => {
        const { __typename, ...rest } = prompt as any;
        try {
            const result = await getSampleOutreach({ variables: { prompt: { ...pickBy(rest), jobId } } });
            return result.data?.sampleOutreach;
        } catch (error) {
            setSnackbar('Failed to fetch sample outreach');
            throw error;
        }
    };

    const promptDefaults = {
        modelParameters: constants?.outreachGenDefaultModelParams,
        systemPrompt: promptsOutreachGenSystem,
        userPromptTemplate: promptsOutreachGenUser
    };
    const outreachGenModelOptions: Array<Prompt['modelParameters']> = constants?.outreachGenModelOptions ?? [];

    if (!loading) {
        return (
            <JobSequencesFormComponent
                jobId={jobId}
                role={role}
                sequences={data.sequences}
                prompts={prompts?.prompts}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                promptDefaults={promptDefaults}
                outreachGenModelOptions={outreachGenModelOptions}
                onCreatePrompt={handleCreatePrompt}
                onArchiveUpdate={handleArchiveUpdate}
                onPromptArchiveUpdate={handlePromptArchiveUpdate}
                onFetchSampleOutreach={handleFetchSampleOutreach}
                isEditable={isEditable}
            />
        );
    } else {
        return null;
    }
};
