import React from 'react';

import { ProfileScore } from '../graphql/queries/profile-score';

export const AIProfileScoreExplanation: React.FC<{ score: ProfileScore }> = ({ score }) => {
    if (!score) {
        return null;
    }
    return (
        <div className="separated">
            <div style={{ fontWeight: 300, marginBottom: '15px', fontSize: '18px' }}>Score Explanation</div>
            <div style={{ fontWeight: 500, color: 'black' }}>{score?.scoreExplanation}</div>
        </div>
    );
};
