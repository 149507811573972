import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

import { fullDate } from '../common/timestamp';
import { DayPicker } from '../core-ui/react-day-picker';
import { BILLING_INFO, BillingInfoData, UPDATE_BILLING_INFO } from '../graphql/queries/job';

const styles = (theme: Theme) => css`
    margin-bottom: 35px;
    width: 50%;
    .MuiExpansionPanelDetails-root {
        flex-direction: column;
    }

    .MuiExpansionPanel-root {
        box-shadow: none;
        border: thin solid ${theme.palette.divider};
        border-radius: 2px;
        padding: 8px 16px;
    }

    .MuiSkeleton-root {
        height: 20px;
        width: 100%;
    }

    .MuiFormControl-root {
        margin-bottom: 10px;
    }

    .bi-form-buttons {
        margin-top: 20px;
        margin-bottom: 5px;
        text-align: right;
        button {
            margin-right: 5px;
        }
    }
`;

export const JobBillingInfo: React.FC<{ jobId: string; disabled: boolean }> = ({ jobId, disabled }) => {
    const theme = useTheme();

    const { data } = useQuery<BillingInfoData, { jobId: string }>(BILLING_INFO, {
        variables: { jobId }
    });

    const [updateBillingInfo, { loading }] = useMutation<
        {
            update_jobs: {
                returning: Array<{
                    id: string;
                    billingInfo: { prepayment: { invoiceSentAt: number; invoiceAmount: number } };
                }>;
            };
        },
        { jobId: string; updates: { billingInfo: { prepayment: { invoiceSentAt: number; invoiceAmount: number } } } }
    >(UPDATE_BILLING_INFO);

    const [invoiceAmount, setInvoiceAmount] = React.useState<number>(null);
    const [invoiceSentAt, setInvoiceSentAt] = React.useState<number>(null);

    const [dayDialogState, setDayDialogState] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (data && data?.job.billingInfo) {
            setInvoiceAmount(data?.job.billingInfo.prepayment.invoiceAmount);
            setInvoiceSentAt(data?.job.billingInfo.prepayment.invoiceSentAt);
        }
    }, [data]);

    const handleChangeInvoiceAmount = async (event: React.ChangeEvent<{ value: string }>) => {
        setInvoiceAmount(event.target.value ? parseFloat(event.target.value) : 0);
    };
    const handleDayPickerOpen = async () => {
        if (!disabled) setDayDialogState(true);
    };

    const handleInvoiceSentDateChange = async (date: Date) => {
        setInvoiceSentAt(date.valueOf());
        setDayDialogState(false);
    };

    const handleSubmit = async () => {
        await updateBillingInfo({
            variables: {
                jobId,
                updates: {
                    billingInfo: {
                        prepayment: {
                            invoiceAmount,
                            invoiceSentAt
                        }
                    }
                }
            }
        });
    };

    const handleCloseDayDialog = () => setDayDialogState(false);
    const dayDialog = dayDialogState ? (
        <Dialog open={true} onClose={handleCloseDayDialog} maxWidth="md">
            <DayPicker
                defaultMonth={invoiceSentAt ? new Date(invoiceSentAt) : new Date()}
                onDayClick={handleInvoiceSentDateChange}
            />
        </Dialog>
    ) : null;

    let content = <Skeleton variant="rect" />;
    if (data) {
        content = (
            <>
                <TextField
                    label="Pre-payment Invoice Amount"
                    value={invoiceAmount ?? ''}
                    onChange={handleChangeInvoiceAmount}
                    fullWidth={true}
                    disabled={disabled || loading}
                />

                <TextField
                    label="Pre-payment Invoice"
                    value={invoiceSentAt ? fullDate(invoiceSentAt) : ''}
                    fullWidth={true}
                    onClick={handleDayPickerOpen}
                    disabled={disabled || loading}
                />
                {dayDialog}

                <div className="bi-form-buttons">
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={disabled || loading}
                    >
                        {loading ? 'Saving' : 'Confirm'}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <div css={styles(theme)}>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <div>
                        <Typography variant="h5" component="div">
                            Billing Info
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="div">
                            View/Edit Billing Info
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>{content}</ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};
