import { useQuery } from '@apollo/client';
import * as React from 'react';

import { responseReceivedStage } from 'shared/models/job-stages';
import { NoteKind } from 'shared/models/note';
import { hasRole } from 'shared/models/user';

import { fetchClientsList, fetchPersonDetails } from '../actions';
import { AIProfileScore } from '../components/ai-profile-score';
import { AIProfileScoreExplanation } from '../components/ai-profile-score-explanation';
import { Spinner } from '../core-ui/spinner';
import { CANDIDATE_PROFILE_SCORE, ProfileScore } from '../graphql/queries/profile-score';
import { AdornmentGenerator, useProfileAdornments } from '../hooks/use-profile-adornment';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { State } from '../state';
import { PersonProfile } from './person';

interface CandidateDetailProps {
    id: string;
    jobId: string;
    tab?: string;
    menuButton?: JSX.Element;
    jobNotesOnly?: boolean;
    notesKind?: NoteKind;
}

export const CandidateDetails: React.FC<CandidateDetailProps> = ({
    id,
    jobId,
    tab,
    menuButton,
    jobNotesOnly,
    notesKind
}) => {
    const clients = useReduxState((state: State) => state.clients);
    const jobs = useReduxState((state: State) => state.jobs);
    const candidates = useReduxState((state: State) => state.candidates);
    const dispatch = useReduxDispatch();
    const { setAdornmentGenerator, getAdornmentGenerator } = useProfileAdornments();
    const [adornmentGeneratorSet, setAdornmentGeneratorSet] = React.useState(false);
    const { data: scoresData } = useQuery<{ scores: ProfileScore[] }, { personId: string; jobId: string }>(
        CANDIDATE_PROFILE_SCORE,
        {
            variables: { personId: id, jobId }
        }
    );
    const { userPermissions } = useSession();

    React.useEffect(() => {
        if (!candidates.get(jobId) || !candidates.get(jobId).get(id)) {
            dispatch(fetchPersonDetails(id, jobId));
        }
        if (!clients.list || !clients.initialized) {
            dispatch(fetchClientsList());
        }
    }, [id, jobId, candidates, clients, dispatch]);

    const getProfileAdornments: AdornmentGenerator = React.useCallback(
        (personId: string) => {
            if (!hasRole(userPermissions, 'prompts_editor')) {
                return {};
            }
            const score = scoresData?.scores?.[0];
            if (personId !== id || !score) {
                return {};
            }
            const candidate = candidates?.get?.(jobId)?.get?.(id);
            if (!candidate) {
                return {};
            }
            const scoreDetailedView = score ? (
                <div style={{ marginRight: '20px', position: 'relative' }}>
                    <AIProfileScore score={score} />
                </div>
            ) : null;
            const explanationDetailedView =
                candidate.stage === responseReceivedStage ? <AIProfileScoreExplanation score={score} /> : null;
            return {
                insertElements: [
                    { slot: 'explanationDetailedView', element: explanationDetailedView },
                    { slot: 'scoreDetailedView', element: scoreDetailedView }
                ]
            };
        },
        [jobId, id, scoresData, candidates, userPermissions]
    );

    React.useEffect(() => {
        if (adornmentGeneratorSet || !getAdornmentGenerator()) {
            setAdornmentGenerator(getProfileAdornments);
            setAdornmentGeneratorSet(true);
        }
    }, [getProfileAdornments]);

    React.useEffect(() => {
        return () => {
            if (adornmentGeneratorSet) {
                setAdornmentGenerator(null);
            }
        };
    }, []);

    if (
        !jobs.get(jobId) ||
        !candidates.get(jobId) ||
        !candidates.get(jobId).get(id) ||
        !clients ||
        !clients.initialized
    ) {
        return <Spinner />;
    } else {
        const candidate = candidates.get(jobId).get(id);
        const job = jobs.get(candidate.jobId);
        const client = clients.list.get(job.clientId);
        return (
            <PersonProfile
                id={candidate.personId}
                candidate={candidate}
                job={jobs.get(candidate.jobId)}
                client={client}
                tab={tab}
                jobNotesOnly={jobNotesOnly}
                notesKind={notesKind}
                menuButton={menuButton}
            />
        );
    }
};
