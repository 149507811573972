import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    Tooltip
} from '@material-ui/core';
import { Settings, ViewColumn } from '@material-ui/icons';
import { debounce } from 'lodash';
import React from 'react';

import { PageDialogLink } from '../../common/page-dialog-link';
import { SearchTextField } from '../../common/search-text-field';
import { JobCandidatesBoard } from '../../containers/job-candidates-board';
import { JobEdit } from '../../containers/job-edit';
import { SEARCH_JOB, SearchJob } from '../../graphql/queries/job';

const popoverStyles = css`
    min-width: 200px;

    .hint {
        padding: 10px 20px;
        text-align: center;
        opacity: 0.75;
    }

    .MuiListItem-secondaryAction {
        padding-right: 84px;
    }
`;

const loadingSpinnerSize = 20;
const minSearchTermLength = 3;
const debounceMs = 500;

export const JobSearchBox: React.FC<{}> = () => {
    const [fetchJobs, { data }] = useLazyQuery<{ jobs: SearchJob[] }, { text: string }>(SEARCH_JOB);
    const [text, setText] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [jobs, setJobs] = React.useState<SearchJob[]>(null);

    const debouncedSearch = React.useRef(
        debounce((searchText: string) => {
            if (searchText?.length >= minSearchTermLength) {
                const queryText = `%${searchText}%`;
                fetchJobs({ variables: { text: queryText } });
            }
        }, debounceMs)
    );

    React.useEffect(() => {
        if (data) {
            setJobs(data.jobs);
        }
    }, [data]);

    React.useEffect(() => {
        if (text?.trim()) {
            debouncedSearch.current(text);
        } else {
            setJobs(null);
        }
    }, [text]);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    let searchResults;
    if (anchorEl && (text?.trim().length ?? 0) >= minSearchTermLength) {
        let content;
        if (!jobs) {
            content = (
                <div className="hint">
                    <CircularProgress size={loadingSpinnerSize} />
                </div>
            );
        } else if (jobs.length === 0) {
            content = <div className="hint">No results</div>;
        } else {
            const items = jobs.map((j) => {
                return (
                    <ListItem key={j.id} dense={true}>
                        <ListItemText primary={`${j.client.name} - ${j.title}`} />
                        <ListItemSecondaryAction>
                            <PageDialogLink
                                Component={JobCandidatesBoard}
                                componentProps={{ jobId: j.id }}
                                url={`/job/${j.id}/board`}
                            >
                                <Tooltip title="View Candidates Pipeline">
                                    <a href={`/job/${j.id}/board`}>
                                        <IconButton size="small">
                                            <ViewColumn fontSize="small" />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            </PageDialogLink>
                            <PageDialogLink Component={JobEdit} componentProps={{ id: j.id }} url={`/job/${j.id}/edit`}>
                                <Tooltip title="Edit Job Settings">
                                    <a href={`/job/${j.id}/edit`}>
                                        <IconButton size="small">
                                            <Settings fontSize="small" />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            </PageDialogLink>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
            content = <List>{items}</List>;
        }
        const anchorOrigin = {
            horizontal: 'right' as 'right',
            vertical: 'bottom' as 'bottom'
        };
        const targetOrigin = {
            horizontal: 'right' as 'right',
            vertical: 'top' as 'top'
        };
        searchResults = (
            <Popover
                anchorEl={anchorEl}
                open={true}
                onClose={handleClose}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                anchorOrigin={anchorOrigin}
                transformOrigin={targetOrigin}
            >
                <div css={popoverStyles}>{content}</div>
            </Popover>
        );
    }

    return (
        <span onClick={handleClick}>
            <SearchTextField value={text} onValueChange={setText} />
            {searchResults}
        </span>
    );
};
