import { useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { logger } from '../common/logger';
import { CompanySimilarityScore, GET_COMPANY_SIMILARITY_SCORES } from '../graphql/queries/companies';

interface CompanySimilarityState {
    isLoading: boolean;
    similarityData?: CompanySimilarityScore;
}

interface CompanySimilarityContextType {
    getCompanyData: (url: string) => CompanySimilarityState;
}

const CompanySimilarityContext = createContext<CompanySimilarityContextType | undefined>(undefined);

const debouncePeriodMs = 200;

export const CompanySimilarityProvider: FC = ({ children }) => {
    const [data, setData] = useState<{ [url: string]: CompanySimilarityState }>({});
    const [urlsToFetch, setUrlsToFetch] = useState<Set<string>>(new Set());
    const [fetchingUrls, setFetchingUrls] = useState<Set<string>>(new Set());
    const [fetchData] = useLazyQuery<
        { companySimilarityScores: CompanySimilarityScore[] },
        { sourceUrls: string[]; additionalTargetCompanies?: Array<{ url: string; name: string }> }
    >(GET_COMPANY_SIMILARITY_SCORES);

    const debouncedFetchData = useMemo(
        () =>
            debounce(async (urls: Set<string>) => {
                const urlArray = Array.from(urls);
                try {
                    setUrlsToFetch((prev) => new Set([...Array.from(prev)].filter((url) => !urls.has(url))));
                    setFetchingUrls((prev) => new Set([...Array.from(prev), ...urlArray]));
                    const res = await fetchData({ variables: { sourceUrls: urlArray } });
                    setData((prev) => {
                        let updated = { ...prev };
                        for (const url of urlArray) {
                            const similarityData = res.data.companySimilarityScores.find((c) => c.sourceUrl === url);
                            updated = {
                                ...updated,
                                [url]: { isLoading: false, similarityData }
                            };
                        }
                        return updated;
                    });
                    setFetchingUrls((prev) => new Set([...Array.from(prev)].filter((url) => !urlArray.includes(url))));
                } catch (err) {
                    logger.warn('Error fetching company similarity data', err);
                    setUrlsToFetch((prev) => new Set([...Array.from(prev), ...urlArray]));
                    setFetchingUrls((prev) => new Set([...Array.from(prev)].filter((url) => !urlArray.includes(url))));
                }
            }, debouncePeriodMs),
        [fetchData]
    );

    useEffect(() => {
        if (urlsToFetch.size > 0) {
            debouncedFetchData(urlsToFetch);
        }
    }, [urlsToFetch, debouncedFetchData]);

    const getSimilarityData = useCallback(
        (url: string): CompanySimilarityState => {
            if (!url) return { isLoading: false };
            const loadingState: CompanySimilarityState = { isLoading: true };
            if (!data[url] && !fetchingUrls.has(url) && !urlsToFetch.has(url)) {
                setUrlsToFetch((prev) => new Set(prev).add(url));
            }
            return data[url] || loadingState;
        },
        [data, fetchingUrls, urlsToFetch]
    );

    const value = useMemo(() => ({ getCompanyData: getSimilarityData }), [getSimilarityData]);

    return <CompanySimilarityContext.Provider value={value}>{children}</CompanySimilarityContext.Provider>;
};

export const useCompanySimilarity = (url: string): CompanySimilarityState => {
    const context = useContext(CompanySimilarityContext);
    if (!context) {
        throw new Error('useCompanySimilarity must be used within a CompanySimilarityProvider');
    }
    return context.getCompanyData(url);
};
