// cspell:ignore mkvw wogqvlky deykm zropkb jvk
import { gql } from '@apollo/client';

import { JobDetails, JobStatus } from 'shared/models/job';
import { FunnelCounts } from 'shared/models/job-stages';

import { internalClientIds } from 'shared/models/client';
import { JobType } from '../../state';

export interface JobWithMetrics {
    id: string;
    title: string;
    createdAt: number;
    assignee: {
        id: string;
        name: string;
    };
    client: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    jobType: JobType;
    status: JobStatus;
    location: string;
    pendingEmails: number;
    oldestResponseRcvdCandidate: number;
    oldestAwaitingClientCandidate: number;
    dailyEmailLimit: number;
    emailCounters: {
        pendingEmailsCount: {
            qualified: number;
            disqualified: number;
        };
    };
    stageFunnelCounts: {
        [stage: string]: { qualified: FunnelCounts; disqualified: FunnelCounts };
    };
    metrics: {
        emailsSent: number;
        responseRate: number;
        movedPastResponseReceived: number;
        movedToRocketScreenScheduled: number;
        movedToRocketScreenComplete: number;
        submits: number;
        accepts: number;
    };
    targets: {
        awaiting_client_feedback: number;
        being_scheduled: number;
        client_first_round: number;
        outreach_sent: number;
        response_received: number;
        rocket_screen_complete: number;
    };
}

export interface JobWithDetails {
    id: string;
    title: string;
    createdAt: number;
    assignee: {
        id: string;
        name: string;
    };
    client: {
        id: string;
        name: string;
        hiredCandidates: {
            aggregate: { count: number };
        };
    };
    accountManager: {
        id: string;
        name: string;
    };
    jobType: JobType;
    status: JobStatus;
    location: string;
    details: JobDetails;
    discipline: string;
    headcount: number;
}

// cspell:disable
export const JOBS_WITH_METRICS = gql`
    query Jobs($status: [Int!]) {
        jobStages: job_stages(order_by: { id: asc }) {
            id
            name
        }
        jobs(
            where: {
                status: { _in: $status }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
            }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            client {
                id
                name
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
            jobType
            status
            location
            emailCounters
            dailyEmailLimit
            stageFunnelCounts
            oldestResponseRcvdCandidate
            oldestAwaitingClientCandidate
            metrics: job_metrics {
                emailsSent
                responseRate
                movedPastResponseReceived
                movedToRocketScreenScheduled
                movedToRocketScreenComplete
                submits
                accepts
            }
            targets: funnel_targets {
                awaiting_client_feedback
                being_scheduled
                client_first_round
                outreach_sent
                response_received
                rocket_screen_complete
            }
        }
    }
`;

export const JOBS_WITH_DETAILS = gql`
    query JobsWithDetails($status: [Int!], $types: [String!]) {
        jobs(
            where: {
                status: { _in: $status }
                jobType: { _in: $types }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
            }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            client {
                id
                name
                hiredCandidates: candidates_aggregate(
                    where: { stage: { _eq: "hired" }, disqualified: { _eq: false } }
                ) {
                    aggregate {
                        count
                    }
                }
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "first")
            }
            jobType
            status
            location
            details
            discipline
            headcount
        }
    }
`;
// cspell:enable

export const UPDATE_JOB_DETAILS = gql`
    mutation updateJobDetails($jobId: String!, $details: jsonb!, $location: String) {
        update_jobs(where: { id: { _eq: $jobId } }, _set: { details: $details, location: $location }) {
            affected_rows
        }
    }
`;

export const NEW_JOBS = gql`
    query NewJobs($status: [Int!], $types: [String!], $createdAfter: bigint!) {
        jobs(
            where: {
                status: { _in: $status }
                jobType: { _in: $types }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                createdAt: { _gte: $createdAfter }
            }
        ) {
            id
        }
    }
`;
