import { gql } from '@apollo/client';
import { JobStatus } from 'shared/models/job';

import { FunnelCounts } from 'shared/models/job-stages';

import { internalClientIds } from 'shared/models/client';
import { JobType } from '../../state';

export interface Job {
    id: string;
    title: string;
    createdAt: number;
    assignee: {
        id: string;
        name: string;
    };
    client: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    jobType: JobType;
    status: JobStatus;
    stageFunnelCounts: {
        [stage: string]: { qualified: FunnelCounts; disqualified: FunnelCounts };
    };
    metrics: {
        emailsSent: number;
        movedToRocketScreenComplete: number;
        submits: number;
    };
    firstEmailOut: {
        sentAt: number;
    };
}

// cspell:disable
export const JOBS = gql`
    query Jobs($createdAt: bigint!, $types: [String!]) {
        jobStages: job_stages(order_by: { id: asc }) {
            id
            name
        }
        jobs(
            where: {
                status: { _eq: 1 }
                createdAt: { _lte: $createdAt }
                clientId: { _nin: ${JSON.stringify(internalClientIds)} }
                jobType: { _in: $types }
            }
            order_by: [{ status: asc }, { client: { name: asc } }, { title: asc }]
        ) {
            id
            title
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            client {
                id
                name
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
            jobType
            status
            stageFunnelCounts
            metrics: job_metrics {
                emailsSent
                movedToRocketScreenComplete
                submits
            }
            firstEmailOut {
                sentAt
            }
        }
    }
`;
